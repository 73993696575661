import './TradingTokenItem.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { getUIStateClassName } from '../../../../data/utils';
import { app } from '../../../../data/Controllers/AppController';
import { largeNumberToLetter } from '../../../../replicant/utils/numbers';
import { TradingTokenListing } from '../../../../replicant/features/tradingMeme/types';
import { HP } from '../../../../replicant/lib/HighPrecision';
import { ProgressBarCircle } from '../../../shared/ProgressBarCircle/ProgressBarCircle';
import { CreatorImage } from '../../../shared/CreatorImage/CreatorImage';
import { MemeImage } from '../../../shared/MemeImage/MemeImage';
import { useEffect, useState } from 'react';
import { getDexGraduationPct } from '../../../../data/memeUtils';

interface Props {
  offchainTokenData: TradingTokenListing;
}

let hasClickedCreator = false;

export const TradingTokenItem = ({ offchainTokenData }: Props) => {
  const { t } = useTranslation();

  const {
    name,
    offchainTokenId,
    image,
    creatorId,
    creatorName,
    creatorImage,
    ticker,
  } = offchainTokenData;

  const tickerName = ticker;

  // get holderCount from meme
  // not sure if there could be a more efficient way to retrieve this value
  let holderCount = 0;
  app.memes.getMeme(offchainTokenId).then((meme) => {
    holderCount = meme?.holderCount || 0;
  });

  const onShowOffchainTokenDetails = async () => {
    if (hasClickedCreator) {
      return;
    }

    // navigate to tiktok feed instead of token details
    // app.nav.goToTradingToken(offchainTokenId);
    app.nav.goToTiktokFeed(offchainTokenId, undefined, 'feed-list');
  };

  const onCreatorClick = () => {
    // Use 'hasClickedCreator' to prevent the click on the name to open the token page
    hasClickedCreator = true;
    app.nav.goToProfile(creatorId);
    setTimeout(() => {
      hasClickedCreator = false;
    });
  };

  const onTapProgress = (event: any) => {
    // just prevent bubbling to the item
    event.preventDefault();
    event.stopPropagation();
  };

  // -----------------------------------------------
  // dex progress calculation

  const [dexProgress, setDexProgress] = useState(0);
  const [isDexProgressCompleted, setDexProgressCompleted] = useState(false);
  const [marketCap, setMarketCap] = useState(0);

  useEffect(() => {
    if (offchainTokenId) {
      app.memes.getMeme(offchainTokenId, 'fetch').then((meme) => {
        if (!meme) return;

        setMarketCap(HP(meme.marketCapUsd).toNumber());

        getDexGraduationPct(meme).then((graduationPct) => {
          setDexProgress(graduationPct);
          setDexProgressCompleted(graduationPct >= 100);
        });
      });
    }
  }, [offchainTokenData.offchainTokenId]);

  // -----------------------------------------------

  return (
    // container
    <div
      className={`btn item-trading ${getUIStateClassName(
        app.ui.getCardState(offchainTokenId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      <div className="item-trading-container">
        {/* image */}
        <MemeImage
          src={image}
          size={64}
          badgeSrc={
            isDexProgressCompleted ? assets.icon_graduation_rocket : undefined
          }
        />

        {/* content */}
        <div className="item-trading-content">
          <div className="left">
            {/* token name */}
            <div className="name-container">
              {name} {`($${ticker})`}
            </div>

            {/* token market cap */}
            <div className="owned-container">
              <div className="owned-container">
                {`Market Cap`} {largeNumberToLetter(marketCap, 3, '$', 2)}
              </div>

              <div className="holders">
                <div className="label">{'•'}</div>
                <div className="icon">
                  <MemeImage src={assets.icon_holder} size={10} />
                </div>
                <div className="label">{holderCount}</div>
              </div>
            </div>

            {/* token creator */}
            <div className="creator-container">
              <div className="creator-label by">{'By'}</div>
              <CreatorImage src={creatorImage} size={14} />
              <div className="creator-label name">{creatorName}</div>
            </div>
          </div>

          <div className="right">
            {/* claim button */}
            {isDexProgressCompleted && (
              <div className="btn btn-normal button-claim">
                <img src={assets.button_ton} />
              </div>
            )}

            {!isDexProgressCompleted && (
              // circular progress bar
              <div className="btn progress-points" onClick={onTapProgress}>
                <ProgressBarCircle
                  progress={dexProgress}
                  completedIcon={assets.icon_graduation_rocket}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
