import './DrawerTradingTransaction.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { MemeImage } from '../../../shared/MemeImage/MemeImage';
import {
  getCurrencyIcon,
  getCurrencyName,
  getFormattedTxAmount,
} from '../TradingTokenFooter/helpers';

export const DrawerTradingTransactionSuccess = () => {
  const { t } = useTranslation();

  const meme = app.ui.drawer.view.data?.props?.meme;

  const onTapShareButton = async () => {
    if (!meme) return;
    // share - sendGift
    await app.memes.sendGift(meme, 'ftue_share_gate');
    app.invoke.grantFtueShareReward({ memeId: meme.id || '' });
    setTimeout(() => app.ui.drawer.close(), 1000);
  };

  const props = app.ui.drawer.view.data?.props?.transactionSuccess;
  const txType = props?.mode === 'create' ? 'buy' : props?.mode || 'buy';
  const txCurrency = props?.txCurrency || 'points';
  const txMemeImage = props?.memeImage || '';
  const txMemeTicker = props?.memeTicker || '';
  const txAmount = props?.txAmount || 0;

  const currencyIcon = getCurrencyIcon(
    txCurrency,
    txMemeImage,
    txType,
    'receive',
    false,
  );

  const currencyName = getCurrencyName(
    txCurrency,
    txMemeTicker,
    txType,
    'receive',
  );

  const formattedTxAmount = getFormattedTxAmount(
    Number(txAmount),
    txType,
    txCurrency,
    'receive',
  );

  console.log('DrawerTradingTransactionSuccess', {
    txType,
    txCurrency,
    txMemeImage,
    txAmount,
    currencyIcon,
    currencyName,
    formattedTxAmount,
  });

  return (
    <div className="drawer-trading-transaction">
      <div className="drawer-trading-transaction-header">
        <img
          className="drawer-trading-transaction-icon"
          src={assets.trading_transaction_success}
        />

        <div className="drawer-trading-transaction-title">
          {t(`drawer_claim_success_title`)}
        </div>

        <div className="drawer-trading-transaction-reward">
          {currencyIcon && <MemeImage src={currencyIcon} size={20} />}
          <div
            className="value"
            dangerouslySetInnerHTML={{
              __html: formattedTxAmount,
            }}
          ></div>
          <div className="currency">{currencyName}</div>
        </div>
      </div>

      <div className="footer-buttons">
        {/* share */}
        <div
          className={`btn btn-normal`}
          onClick={() => {
            app.ui.confetti.hide();
            app.ui.drawer.close();
            onTapShareButton();
          }}
        >
          {t('generic_share')}
        </div>
      </div>
    </div>
  );
};
