import './DrawerPartnerReferral.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { largeNumberToLetter } from '../../../replicant/utils/numbers';
import { GlowingImage } from '../../shared/GlowingImage/GlowingImage';

// ===================================================================================
// #region Partners

// todo(Damon): This data needs to be redefined by product maybe.

interface Partner {
  id: string;
  name: string;
  reward: number;
  image: string;
}

const partnersConfig = {
  billionaires_path: {
    id: 'billionaires_path',
    name: 'Billionares Path',
    reward: 500_000,
    image: assets.partner_billionaires_path,
  } as Partner,
  blum: {
    id: 'blum',
    name: 'Blum',
    reward: 500_000,
    image: assets.partner_blum,
  } as Partner,
  cex: {
    id: 'cex',
    name: 'Cex',
    reward: 500_000,
    image: assets.partner_cex,
  } as Partner,
  dormint: {
    id: 'dormint',
    name: 'Dormint',
    reward: 500_000,
    image: assets.partner_dormint,
  } as Partner,
  free_dogs: {
    id: 'free_dogs',
    name: 'Free Dogs',
    reward: 500_000,
    image: assets.partner_free_dogs,
  } as Partner,
  goats: {
    id: 'goats',
    name: 'Goats',
    reward: 500_000,
    image: assets.partner_goats,
  } as Partner,
  hipo: {
    id: 'hipo',
    name: 'Hipo',
    reward: 500_000,
    image: assets.partner_hipo,
  } as Partner,
  okx: {
    id: 'okx',
    name: 'OKX',
    reward: 500_000,
    image: assets.partner_okx,
  } as Partner,
  seed: {
    id: 'seed',
    name: 'Seed',
    reward: 500_000,
    image: assets.partner_seed,
  } as Partner,
  ton_kombat: {
    id: 'ton_kombat',
    name: 'Ton Kombat',
    reward: 500_000,
    image: assets.partner_ton_kombat,
  } as Partner,
  wcoin: {
    id: 'wcoin',
    name: 'WCoin',
    reward: 500_000,
    image: assets.partner_wcoin,
  },
} as const;

// ===================================================================================
// #region Drawer

export const DrawerPartnerReferral = () => {
  const { t } = useTranslation();

  // get current user
  const { current } = app.profile;

  // todo(Damon): which partner should be defined by the referral link
  const partner = partnersConfig.free_dogs;

  const onTapClaim = () => {
    // todo(Damon): claim reward
    app.ui.drawer.close();
  };

  if (!partner) {
    return null;
  }

  return (
    <div className="drawer-partner">
      <div className="drawer-partner-content">
        <GlowingImage
          image={partner.image}
          size={'200px'}
          blur={60}
          intensity={0.35}
          className="drawer-partner-image"
        />

        {/* welcome */}
        <div className="drawer-partner-row welcome">
          <div className="drawer-partner-label s24">
            {t('partner_referral_welcome')}
          </div>
          <div className="drawer-partner-label s24 yellow">{current?.name}</div>
        </div>

        {/* partner */}
        <div className="drawer-partner-row partner">
          <div className="drawer-partner-label s16 grey">
            {t('partner_referral_thanks_for_joining')}
          </div>
          <div className="drawer-partner-label s20 yellow">{partner.name}</div>
        </div>

        {/* reward */}
        <div className="drawer-partner-row reward">
          <div className="drawer-partner-label s16 grey">
            {t('partner_referral_special_reward')}
          </div>
          <div className="drawer-partner-reward">
            <img src={assets.coin} />
            <div className="drawer-partner-label s48">
              {largeNumberToLetter(partner.reward, 3)}
            </div>
          </div>
        </div>

        {/* button */}
        <div
          className="btn btn-normal drawer-partner-button"
          onClick={onTapClaim}
        >
          {t('partner_referral_claim')}
        </div>
      </div>
    </div>
  );
};

// ===================================================================================
