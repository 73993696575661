import { Dictionary, Cell } from '@ton/core';
import { sha256_sync } from '@ton/crypto';
import { waitFor } from '../utils';
import { Optional } from '../types';
import {
  storeJettonDeploy,
  storeAdminCallTransferJetton,
  storeAdminCallSellJetton,
  storeAdminCallClaimDexJetton,
  storeAdminCallDailyClaimDexJetton,
} from '../../replicant/features/tradingMeme/tact_Administrator';
import {
  sha256,
  SNAKE_PREFIX,
} from '../../replicant/features/tradingMeme/tradingMeme.getters.ton';

export type {
  JettonDeploy,
  AdminCallTransferJetton,
  AdminCallSellJetton,
} from '../../replicant/features/tradingMeme/tact_Administrator';

export const storeBuilders = {
  createJettonContract: storeJettonDeploy,
  buyToken: storeAdminCallTransferJetton,
  sellToken: storeAdminCallSellJetton,
  claimDexToken: storeAdminCallClaimDexJetton,
  claimDailyDexToken: storeAdminCallDailyClaimDexJetton,
};

const KNOWN_KEYS = [
  'name',
  'symbol',
  'description',
  'image',
  'decimals',
  'extra',
];

// Precompute a map of hash -> original string
const KEY_MAP: Record<string, string> = {};
for (const k of KNOWN_KEYS) {
  const hashHex = sha256(k).toString('hex');
  // '3976890847a...'
  KEY_MAP[hashHex] = k;
}

export function readOnchainMetadata(cell: Cell): Record<string, string> {
  try {
    // 1) Create a Slice
    const slice = cell.beginParse();

    // 2) Read the 8-bit prefix
    const prefix = slice.loadUint(8);
    console.error('prefix', prefix);
    // if (prefix !== ONCHAIN_CONTENT_PREFIX) {
    //   return {};
    //     // throw new Error(`Unexpected prefix: ${prefix}, expected ${ONCHAIN_CONTENT_PREFIX}`);
    // }

    // 3) Load the dictionary: (uint256) => cell
    const dict = slice.loadDict(
      Dictionary.Keys.BigUint(256),
      Dictionary.Values.Cell(),
    );

    // 4) Iterate all entries and parse them
    const result: Record<string, string> = {};
    for (const [keyBigUint, valCell] of dict) {
      // Convert your biguint key back to the original string key
      const bigIntHex = keyBigUint.toString(16).padStart(64, '0');
      const keyStr = KEY_MAP[bigIntHex];

      // 5) Parse the 'snake' cell to get the entire UTF-8 text
      const buffer = readSnakeCell(valCell);
      result[keyStr] = buffer.toString('utf8');
    }

    return result;
  } catch (e) {
    console.error('Error with readOnchainMetadata:', e);
    return {};
  }
}

// readSnakeCell reverses the logic of makeSnakeCell.
export function readSnakeCell(cell: Cell): Buffer {
  let result = Buffer.alloc(0);
  let current: Cell | null = cell;
  let isFirstCell = true;

  while (current) {
    // Create a parsing slice from the current cell
    const slice = current.beginParse();

    // If it's the first cell, load and verify the SNAKE_PREFIX (8 bits)
    if (isFirstCell) {
      const prefix = slice.loadUint(8);
      if (prefix !== SNAKE_PREFIX) {
        throw new Error(
          `Invalid snake prefix: got ${prefix}, expected ${SNAKE_PREFIX}`,
        );
      }
      isFirstCell = false;
    }

    // The rest of the bits in this cell are the chunk
    const bytesInThisCell = slice.remainingBits / 8;
    const chunk = slice.loadBuffer(bytesInThisCell);
    result = Buffer.concat([result, chunk]);

    // Move to the next reference if present
    if (current.refs.length > 0) {
      current = current.refs[0];
    } else {
      current = null;
    }
  }

  return result;
}

export function stringToBigInt(input: string): bigint {
  // Hash the string using SHA-256
  const hash = sha256_sync(input);

  // Convert the hash to a bigint
  return BigInt('0x' + hash.toString('hex'));
}

interface WithRetryOpts {
  maxRetries: number;
  interval?: number;
}
export const withRetry = async <T>(
  promise: () => Promise<T>,
  opts: Optional<WithRetryOpts> = {
    maxRetries: 10,
    interval: 1000,
  },
  retry = 0,
): Promise<T> => {
  const { maxRetries, interval = 1000 } = opts;
  if (retry === maxRetries) {
    throw new Error('TIMEOUT');
  }
  try {
    console.log('withRetry', { promise, retry });
    const value = await promise();
    console.log('withRetry', { value, retry });
    if (value) {
      return value;
    }
    await waitFor(interval);
    return withRetry(promise, opts, ++retry);
  } catch (e) {
    throw e;
  }
};

function calculatePrice(
  currentSupply: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  /**
   * Calculate the market price at a given current supply.
   *
   * @param currentSupply Current token supply.
   * @param initialPrice Initial price of the token (in nanoton).
   * @param maxTargetPrice Maximum target price of the token (in nanoton).
   * @param totalSupply Maximum total token supply.
   * @return Market price as an integer (in nanoton).
   */
  const price =
    ((maxTargetPrice - initialPrice) * currentSupply ** BigInt(2)) /
      totalSupply ** BigInt(2) +
    initialPrice;
  return price; // Integer in nanoton
}

// Estimate Token amount for Ton
export function calculateTokensReceived(
  currentSupply: bigint,
  tonPaid: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  /**
   * Calculate the number of tokens received for a given TON paid.
   *
   * @param currentSupply Current token supply (S_start).
   * @param tonPaid TON the user will pay (in nanoton).
   * @param initialPrice Initial price of the token (in nanoton).
   * @param maxTargetPrice Maximum target price of the token (in nanoton).
   * @param totalSupply Maximum total token supply.
   * @return Number of tokens received.
   */
  let tokensToBuy = BigInt(0);
  let totalCost = BigInt(0);

  while (totalCost < tonPaid) {
    // Stop if current supply reaches total supply
    if (currentSupply >= totalSupply) {
      console.log('Reached maximum token supply.');
      break;
    }

    // Calculate the price of the next token
    const price = calculatePrice(
      currentSupply,
      initialPrice,
      maxTargetPrice,
      totalSupply,
    );
    if (totalCost + price > tonPaid) {
      break;
    }

    // Update totals
    totalCost += price;
    tokensToBuy += BigInt(1);
    currentSupply += BigInt(1);
  }

  return tokensToBuy;
}

/**
 * Estimates the number of tokens that can be received for a given amount of TON,
 * taking into account the bonding curve pricing model. Uses a step-based approach
 * for more efficient calculation with large numbers. Note, the smaller the step size,
 * the more precise the calculation, but the slower it will be.
 *
 * @param currentSupply - Current total supply of tokens in circulation
 * @param tonPaid - Amount of TON being paid (in nanotons)
 * @param initialPrice - Starting price of tokens (in nanotons)
 * @param maxTargetPrice - Maximum price cap for tokens (in nanotons)
 * @param totalSupply - Maximum possible token supply
 * @returns The estimated number of tokens that can be purchased
 */
export function estimateTokensReceived(
  currentSupply: bigint,
  tonPaid: bigint,
  initialPrice: bigint,
  maxTargetPrice: bigint,
  totalSupply: bigint,
): bigint {
  // Early exits
  if (currentSupply >= totalSupply || tonPaid <= BigInt(0)) {
    return BigInt(0);
  }

  // Use a step size to make the calculation more efficient
  const STEP_SIZE = BigInt(1000); // Adjust this value based on precision needs
  let tokensToBuy = BigInt(0);
  let totalCost = BigInt(0);
  let remainingSupply = totalSupply - currentSupply;

  while (totalCost < tonPaid && currentSupply < totalSupply) {
    // Calculate how many tokens we can try to buy in this step
    let stepTokens = STEP_SIZE;
    if (tokensToBuy + stepTokens > remainingSupply) {
      stepTokens = remainingSupply - tokensToBuy;
    }

    // Calculate price for this batch
    const price = calculatePrice(
      currentSupply + tokensToBuy,
      initialPrice,
      maxTargetPrice,
      totalSupply,
    );

    if (price <= BigInt(0)) {
      break;
    }

    const batchCost = price * stepTokens;

    if (totalCost + batchCost > tonPaid) {
      // If we can't buy the full batch, calculate how many we can buy
      const remainingTon = tonPaid - totalCost;
      const tokensInLastBatch = remainingTon / price;
      tokensToBuy += tokensInLastBatch;
      break;
    }

    totalCost += batchCost;
    tokensToBuy += stepTokens;

    if (tokensToBuy >= remainingSupply) {
      break;
    }
  }

  return tokensToBuy;
}

function calculateDexSwap(
  sendAmount: bigint,
  sourcePoolAmount: bigint,
  targetPoolAmount: bigint,
): bigint {
  const FEE_NUMERATOR = BigInt(997);
  const FEE_DENOMINATOR = BigInt(1000);
  const numerator = targetPoolAmount * sendAmount * FEE_NUMERATOR;
  const denominator =
    sourcePoolAmount * FEE_DENOMINATOR + sendAmount * FEE_NUMERATOR;

  return numerator / denominator;
}

export function calculateTonToDexTokens(
  tonSendAmount: bigint,
  tonLiquidityPoolAmount: bigint,
  dexTokenLiquidityPoolAmount: bigint,
): string {
  const tokensReceived = calculateDexSwap(
    tonSendAmount,
    tonLiquidityPoolAmount,
    dexTokenLiquidityPoolAmount,
  );
  return tokensReceived.toString();
}

export function calculateDexTokensToTon(
  dexTokenSendAmount: bigint,
  tonLiquidityPoolAmount: bigint,
  dexTokenLiquidityPoolAmount: bigint,
): bigint {
  const tonReceived = calculateDexSwap(
    dexTokenSendAmount,
    dexTokenLiquidityPoolAmount,
    tonLiquidityPoolAmount,
  );
  return BigInt(tonReceived);
}

type CurveConfig = {
  maxTargetPrice: bigint;
  initialPrice: bigint;
  maxSupply: bigint;
};
// Estimate Ton for Token amount
export function calculateTotalMintPrice(
  currentSupply: bigint,
  tokensToBuyOrSell: bigint,
  curveConfig: CurveConfig,
  isBuy: boolean,
): bigint {
  /**
   * Calculate the total mint price for buying or selling tokens based on a curve.
   *
   * @param currentSupply Current token supply.
   * @param tokensToBuyOrSell Number of tokens to buy or sell.
   * @param curveConfig Curve configuration (maxTargetPrice, initialPrice, maxSupply).
   * @param isBuy Boolean indicating if it is a buy (true) or sell (false) operation.
   * @return Total mint price as an integer (in nanoton).
   */
  const b: bigint = curveConfig.maxTargetPrice - curveConfig.initialPrice;
  const F: bigint = curveConfig.maxSupply ** BigInt(2);
  const precisionFactor: bigint = BigInt(1_000_000_000); // Large factor to maintain precision

  const power = BigInt(3);

  let price: bigint;

  if (isBuy) {
    const buyTerm =
      (((currentSupply + tokensToBuyOrSell) ** power - currentSupply ** power) *
        b *
        precisionFactor) /
      (power * F);
    price =
      curveConfig.initialPrice * tokensToBuyOrSell * precisionFactor + buyTerm;
  } else {
    const sellTerm =
      ((currentSupply ** power - (currentSupply - tokensToBuyOrSell) ** power) *
        b *
        precisionFactor) /
      (power * F);
    price =
      curveConfig.initialPrice * tokensToBuyOrSell * precisionFactor + sellTerm;
  }

  // Divide by the precision factor to scale down to intended integer range
  return price / precisionFactor;
}
