import './DrawerUserMemeGiftBack.scss';
import { assets } from '../../../assets/assets';
import { app } from '../../../data/Controllers/AppController';
import { useTranslation } from 'react-i18next';
import { getBuyTxEstimate } from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import {
  displayPointAmount,
  formatPrice,
} from '../../../replicant/utils/numbers';
import { memeGiftRuleset } from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { useEffect } from 'react';
import {
  insertCoinIconIntoText,
  insertTicketIconIntoText,
} from '../../../data/utils';
import { HP } from '../../../replicant/lib/HighPrecision';

export const DrawerUserMemeGiftBack = () => {
  const { t } = useTranslation();

  useEffect(() => {
    app.ui.confetti.setData({ duration: 2000 }).show();
  }, []);

  const userMemeGift = app.memes.userMemeGift;

  if (!userMemeGift) {
    return null;
  }

  const {
    token,
    alreadyClaimed,
    consolation,
    expired,
    points,
    senderId,
    subFeature,
  } = userMemeGift;

  if (!token) {
    return null;
  }

  const giftPoints = getBuyTxEstimate({
    currentSupply: HP(token.pointSupply),
    currencyAmount: HP(memeGiftRuleset.giftReward),
  });

  const onSendGift = async () => {
    await app.memes.sendGift(token, 'meme_gift_back');

    app.ui.drawer.close();
  };

  const text = {
    title: t('user_meme_gift_received'),
    senderName: t('user_meme_gift_from', {
      friendName: userMemeGift.senderName,
    }),
    receiveReward: insertTicketIconIntoText(
      t('user_meme_gift_points', {
        points: displayPointAmount(HP(points).toNumber(), 5, true, true),
      }),
      token.image,
      0,
    ),
    sendReward: t('user_meme_gift_give_get', {
      amount: displayPointAmount(giftPoints.toNumber(), 3, false, false),
    }),
  };

  if (alreadyClaimed || expired) {
    text.title = alreadyClaimed
      ? t('user_meme_gift_already_claimed')
      : t('user_meme_gift_expired');
    text.receiveReward = '';
    text.senderName = expired ? t('user_meme_gift_expired_subtitle') : '';
  }

  if (consolation > 0) {
    text.receiveReward = insertCoinIconIntoText(
      t('user_meme_gift_coins', {
        coins: formatPrice(userMemeGift.consolation),
      }),
      0,
    );
  }

  const gotSomething = consolation > 0 || HP(points).gt(0);

  return (
    <div className="drawer-details gift-back">
      <TrackOnReady
        event={gotSomething ? 'GiftClaimSuccess' : 'GiftFailure'}
        data={{
          memeId: token.id,
          MemeTicker: token.ticker,
          pointAmount: points,
          coinAmount: consolation.toString(),
          senderID: senderId,
          Page_location: 'reshareGift',
          subFeature,
        }}
      />
      <div className="drawer-layout powerup">
        <div className="drawer-header gift-back-header">
          {/* image */}
          <div className="drawer-header-icon">
            <div className="img-header">
              <div className="img-container">
                <img src={token.image} />
              </div>
              <div className="overlay-img-header">
                <div className="overlay-img-container">
                  <img src={assets.gift_icon} />
                </div>
              </div>
            </div>
          </div>

          {/* title and description */}
          <div className="gift-back-title">{text.title}</div>

          {text.senderName && (
            <div className="gift-back-sender">{text.senderName}</div>
          )}

          <div
            className="gift-back-value"
            dangerouslySetInnerHTML={{
              __html: text.receiveReward,
            }}
          ></div>
        </div>

        <div
          className="drawer-gift-back-give-get"
          dangerouslySetInnerHTML={{ __html: text.sendReward }}
        ></div>
      </div>

      {/* buy button */}
      <div className="footer-buttons">
        <div className="btn btn-normal  gift-back-button" onClick={onSendGift}>
          {gotSomething
            ? t('pup_gift_received_button')
            : t('pup_send_another_gift')}
        </div>
      </div>
    </div>
  );
};

interface TrackOpts {
  event: 'GiftClaimSuccess' | 'GiftFailure';
  data: {
    memeId?: string;
    MemeTicker?: string;
    pointAmount?: string;
    coinAmount?: string;
    senderID?: string;
    Page_location: 'ftueGift' | 'reshareGift' | 'sideBar';
    subFeature?: string;
  };
}

const TrackOnReady = (opts: TrackOpts) => {
  useEffect(() => {
    app.track(opts.event, opts.data);
  }, []);
  return null;
};
