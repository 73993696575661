import './TimeIntervalButtons.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import { TimeInterval } from './TradingChart';
import { useTranslation } from 'react-i18next';
import { isTelegramWebview } from '../../../../data/device';

interface TimeIntervalButtonsProps {
  renderType: 'dropdown' | 'inline';
  firstInterval: TimeInterval;
  intervals: TimeInterval[];
  onSetInterval: (interval: TimeInterval) => void;
}

export const TimeIntervalButtons = ({
  renderType,
  firstInterval,
  intervals,
  onSetInterval,
}: TimeIntervalButtonsProps) => {
  const { t } = useTranslation();

  const [selectedInterval, setSelectedInterval] = useState(firstInterval);

  useEffect(() => {
    setSelectedInterval(firstInterval);
  }, [firstInterval]);

  // ======================================================
  // render intervals as dropdown

  if (renderType === 'dropdown') {
    const onDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const interval = intervals[event.target.selectedIndex];
      setSelectedInterval(interval);
      onSetInterval(interval);
    };

    return (
      <div className="time-interval-buttons">
        <div className="time-interval-buttons-topright">
          <form>
            <select
              className={`dropdown ${isTelegramWebview() ? 'telegram' : ''}`}
              onChange={onDropdownChange}
            >
              {intervals.map((interval, index) => (
                <option
                  key={`${interval.text}_${index}`}
                  selected={selectedInterval === interval}
                >
                  {t(interval.text, interval.textP)}
                </option>
              ))}
            </select>
          </form>
        </div>
      </div>
    );
  }

  // ======================================================
  // render intervals as inline buttons

  const onButtonChange = (interval: TimeInterval) => {
    setSelectedInterval(interval);
    onSetInterval(interval);
  };

  return (
    <div className="time-interval-buttons">
      <div className="time-interval-buttons-topleft">
        {intervals.map((interval, index) => (
          <button
            key={`${interval.text}_${index}`}
            className={`interval-button ${
              selectedInterval === interval ? 'active' : ''
            }`}
            onClick={() => onButtonChange(interval)}
          >
            {t(interval.text, interval.textP)}
          </button>
        ))}
      </div>
    </div>
  );

  // ======================================================
};
