import './LightweightChartComponent.scss';
import {
  createChart,
  ColorType,
  ChartOptions,
  DeepPartial,
  LineType,
  IChartApi,
  LineStyle,
  CrosshairMode,
  LastPriceAnimationMode,
  LineWidth,
} from 'lightweight-charts';
import { useEffect, useRef, useState } from 'react';
import { LabelSubscript } from '../../../shared/LabelSubscript/LabelSubscript';

// documentation:
// https://tradingview.github.io/lightweight-charts/
// https://tradingview.github.io/lightweight-charts/tutorials/react/simple

export interface GraphPoint {
  time: number;
  value: number;
}

interface Props {
  data: GraphPoint[];
  colors: any;
  height: number;
  margin: number;
  lineWidth: LineWidth;
  multiplier?: number;
}

function getReadabeTimeFromMarkerData(timestampFromGraph: number) {
  // get date from marker data
  const date = new Date(timestampFromGraph * 1000);

  // todo: not sure why, but the timestamp returned by the graph api is not the same as in the bottom timeline
  // todo: always returns 9 hours in the future
  // todo: however, the price is always correct, so what we need to do is adjust the time rendering by substracting 9h
  // todo: now, the value displayed in the custom label always matches exactly the label displayed by the graph in the bottom timeline
  var nineHoursLess = date.getTime() - 9 * 60 * 60 * 1000;
  date.setTime(nineHoursLess);

  // format day to a nice short readable string
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' }).slice(0, 3);
  const year = date.toLocaleString('default', { year: '2-digit' });

  // format time to a nice short readable string
  var hour = date.getHours();
  var minute = date.getMinutes();
  const strHour = hour < 10 ? `0${hour}` : `${hour}`;
  const strMinute = minute < 10 ? `0${minute}` : `${minute}`;

  // set final readable time
  const readableTime = `${day} ${month} '${year} ${strHour}:${strMinute}`;
  return readableTime;
}

export const LightweightChartComponent = ({
  data,
  colors,
  height,
  margin,
  lineWidth,
  multiplier = 1,
}: Props) => {
  const chartContainerRef = useRef<any>();
  const chartRef = useRef<{ chart: IChartApi | null }>({ chart: null }); // Ref to store the chart instance

  // console.warn('>>> LightweightChartComponent', data, colors);

  // -----------------------------------------
  // states for custom css marker

  const areaRef = useRef<any>();

  const [markerPos, setMarkerPos] = useState<{
    x: number;
    y: number;
  }>({
    x: -999999999,
    y: -999999999,
  });

  const [markerData, setMarkerData] = useState<{
    time: number;
    value: number;
  }>({
    time: 0,
    value: 0,
  });

  const [markerReadableTime, setMarkerReadableTime] = useState<string>();

  // -----------------------------------------

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const adjustedData =
      multiplier === 1
        ? data
        : data.map((dataPoint) => ({
            value: dataPoint.value * multiplier,
            time: dataPoint.time,
          }));

    // const myPriceFormatter = (p: number) => largeNumberToLetter(p, 4);

    if (chartRef.current.chart === null) {
      const chartOptions: DeepPartial<ChartOptions> = {
        // Apply a custom priceFormatter to the chart
        // localization: {
        //   priceFormatter: myPriceFormatter,
        // },
        layout: {
          background: { type: ColorType.Solid, color: colors.backgroundColor },
          textColor: colors.textColor,
          fontSize: 11,
          attributionLogo: false, // The licence for library specifies that you add the "attribution notice"...
        },
        grid: {
          horzLines: { visible: false, color: 'rgba(255, 255, 255, 0.15)' },
          vertLines: { visible: false, color: 'rgba(255, 255, 255, 0.15)' },
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
          // uniformDistribution: true,
          fixLeftEdge: false,
          fixRightEdge: false,
          lockVisibleTimeRangeOnResize: false,
          visible: false, // note: we are not displacing timeline on bottom anymore
        },
        rightPriceScale: {
          borderVisible: false,
          scaleMargins: { top: 0.05, bottom: 0 }, // this is important for the chart to be 100% width
          autoScale: true,
          visible: false, // note: we are not displacing prices on right column anymore
        },
        handleScroll: {
          mouseWheel: false,
          pressedMouseMove: false,
          vertTouchDrag: false,
          horzTouchDrag: false, //enable this to pan by moving the finger horizontally
        },
        handleScale: {
          axisPressedMouseMove: false,
          mouseWheel: false,
          pinch: false, // enable this to zoom the chart with pinch gesture
        },
        crosshair: {
          mode: CrosshairMode.Magnet, // 'Normal' will allow the crosshair to move freely without snapping to datapoints
          vertLine: {
            // showing Date in Label
            // color: '#C3BCDB44',
            // style: LineStyle.Solid,
            color: '#F5C005', // yellow'
          },
          horzLine: {
            // showing Price in Label
            color: '#F5C005', // yellow'
            labelBackgroundColor: '#F5C005', // yellow'
            visible: false, // todo
          },
        },
        // Chart size props
        width: chartContainerRef.current.clientWidth,

        height: height, // 250, // 300,
        autoSize: false, // true,
      };

      const chart = createChart(chartContainerRef.current, chartOptions);
      chartRef.current.chart = chart;
    }

    const handleResize = () => {
      if (chartRef.current.chart === null) {
        return;
      }

      chartRef.current.chart?.applyOptions({
        width: chartContainerRef.current.clientWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    const newSeries = chartRef.current.chart.addAreaSeries({
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      crosshairMarkerBackgroundColor: '#F5C005', // yellow'
      lastPriceAnimation: LastPriceAnimationMode.Continuous,

      // title: 'My awesome chart',
      lineWidth: 2, //lineWidth,
      lineColor: colors.lineColor,
      topColor: colors.areaTopColor,
      bottomColor: colors.areaBottomColor,
      lineType: LineType.WithSteps,

      autoscaleInfoProvider: () => ({
        priceRange: {
          minValue:
            adjustedData.reduce(
              (min, point) => Math.min(min, point.value),
              Infinity,
            ) * 0.99,
          maxValue:
            adjustedData.reduce((max, point) => Math.max(max, point.value), 0) *
            1.01,
        },
      }),
    });

    // @ts-ignore, somehow the type-checker complains about the type of "time" but it works
    newSeries.setData(adjustedData);
    chartRef.current.chart.timeScale().fitContent();
    chartRef.current.chart.timeScale().scrollToRealTime();

    // ---------------------------------------------------------------

    // custom css marker
    // update custom css marker to be rendered on the crosshair vertical line over the chart
    // lightweightchart does not have this feature, so we need to implement it by hand

    function myCrosshairMoveHandler(param: any) {
      if (param.point) {
        // place custom marker to match graph vertical line
        setMarkerPos({ x: param.point.x, y: 0 });
      } else {
        // hide css custom marker and excape
        setMarkerPos({ x: -999999999, y: -999999999 });
        return;
      }

      const dataPoint = param.seriesData.get(newSeries);
      if (dataPoint) {
        // set the marker data
        setMarkerData({
          time: dataPoint.time,
          value: dataPoint.value,
        });

        // set final readable time
        const readableTime = getReadabeTimeFromMarkerData(dataPoint.time);
        setMarkerReadableTime(readableTime);
        // console.warn('>>> dataPoint', dataPoint);
      } else {
        // console.error('>>> dataPoint not found');
      }
    }

    chartRef.current.chart.subscribeCrosshairMove(myCrosshairMoveHandler);
    // ---------------------------------------------------------------

    return () => {
      window.removeEventListener('resize', handleResize);
      chartRef.current.chart!.removeSeries(newSeries);
    };
  }, [
    data,
    colors.backgroundColor,
    colors.lineColor,
    colors.textColor,
    colors.areaTopColor,
    colors.areaBottomColor,
  ]);

  // calculate label side
  const w = areaRef.current?.clientWidth;
  const markerSide = markerPos.x > w - 100 ? 'left' : 'right';

  return (
    <div className="lc-container">
      <div
        ref={chartContainerRef}
        style={{ width: `calc(100% - ${margin}px)` }}
      />
      <div ref={areaRef} className="lc-overlay-test">
        <div
          className={`lc-marker ${markerSide}`}
          style={{ top: markerPos.y, left: markerPos.x }}
        >
          <div className={`lc-marker-label ${markerSide}`}>
            {markerReadableTime}
          </div>

          <div className={`lc-marker-label ${markerSide} yellow`}>
            <LabelSubscript
              sourceValue={markerData.value}
              stripZeroesOnRight={false}
            />
          </div>

          {/* <divclassName={`lc-marker-label ${markerSide} yellow`}>
            \n{largeNumberToLetter(markerData.value, 4)}
          </div> */}
        </div>
      </div>
    </div>
  );
};
