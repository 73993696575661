import './Tabs.scss';
import { useState, useEffect } from 'react';
import { Badge } from '../Badge/Badge';
import { ElementUIState } from '../../../data/Controllers/UIController/UITypes';
import { getUIStateClassName } from '../../../data/utils';
import { app } from '../../../data/Controllers/AppController';

/*
Tabs component will render tabs in given style type, 
and also render given content when selected.

Usage example:

 <Tabs<'Tab-A' | 'Tab-B'>
    tabStyle={TabStyle.Squared}
    initial={'Tab-A'}
    tabs={[
      {
        name: 'Tab-A',
        component: (
          <div>This is tab-a content</div>
        ),
      },
      {
        name: 'Tab-B',
        component: (
          <div>This is tab-b content</div>
        ),
      },
    ]}
  />
See MinePage.tsx for a full usage
*/

type BadgeProps = {
  visible?: boolean;
  amount?: number;
  checked?: boolean;
};

export interface TabCfg<T> {
  id: T;
  name: T | string;
  selectedStyle?: { bgColor: string; color: string; height?: string };
  icon?: string; // todo carles
  badge?: BadgeProps;
  disabled?: boolean;
  component: React.ReactNode;
}

interface Props<T> {
  tabStyle?: TabStyle;
  height?: number;
  onTabClick?: (currentTab: T, previousTab: T) => void;
  initial: T;
  autoSelected?: T;
  tabs: TabCfg<T>[];
  uiState?: ElementUIState;
}

export enum TabStyle {
  Rounded,
  Squared,
  Underline,
  Large, // Add this new style
}

export function Tabs<T extends string>({
  tabStyle,
  height,
  initial,
  tabs,
  onTabClick,
  autoSelected,
  uiState,
}: Props<T>) {
  const [selected, setSelected] = useState(initial);
  const [visited, setVisited] = useState({} as Record<T, boolean>);

  useEffect(() => {
    if (autoSelected) {
      setSelected(autoSelected);
    }
  }, [autoSelected]);

  const current = tabs.find((tab) => tab.id === selected);

  const selectTab = (tab: T) => () => {
    const previousTab = selected;
    setSelected(tab);
    setVisited({ ...visited, [previousTab]: true });
    onTabClick?.(tab, previousTab);
  };

  const extraStyle = uiState ? getUIStateClassName(uiState) : '';

  const getTabsClassNameByTabStyle = () => {
    switch (tabStyle) {
      case TabStyle.Squared:
        return `tabs-header-rounded ${extraStyle}`;
      case TabStyle.Rounded:
        return `tabs-header-rounded extra-rounded ${extraStyle}`;
      case TabStyle.Underline:
        return `tabs-header-underline ${extraStyle}`;
      case TabStyle.Large:
        return `tabs-header-large ${extraStyle}`;
      default:
        return `tabs-header-rounded ${extraStyle}`;
    }
  };

  const getTabCustomStyle = (tab: any, isSelected: boolean) => {
    let selectedStyle = {
      bgColor: 'unset',
      color: 'unset',
      borderBottom: 'unset',
    };

    if (tabStyle === TabStyle.Large) {
      return { bgColor: 'unset', color: isSelected ? 'white' : '#777' };
    }

    if (tabStyle !== TabStyle.Underline) {
      selectedStyle = {
        bgColor: '#222',
        color: 'white',
        borderBottom: 'unset',
      };
      if (isSelected)
        selectedStyle = tab.selectedStyle || {
          bgColor: 'black',
          color: 'white',
        };
    }

    if (tabStyle === TabStyle.Underline) {
      selectedStyle.color = isSelected ? 'white' : '#777';
    }

    if (tabStyle === TabStyle.Rounded) {
      return { backgroundcolor: isSelected ? '#232323' : 'unset' };
    }

    return selectedStyle;
  };

  return (
    <div
      className={getTabsClassNameByTabStyle()}
      style={{ height: `${height}px` }}
    >
      <div className="tabs">
        {tabs.map((tab, index) => {
          const isSelected = selected === tab.id;
          const { bgColor, color } = getTabCustomStyle(tab, isSelected);

          return (
            <div
              key={index}
              style={{ backgroundColor: bgColor, color }}
              className={`btn tab 
               ${tab.disabled ? 'disabled' : ''} ${
                isSelected ? 'selected' : ''
              } ${extraStyle} ${visited[tab.id] ? 'visited' : ''} ${
                tabStyle === TabStyle.Rounded ? 'extra-rounded' : ''
              }`}
              onClick={selectTab(tab.id as T)}
            >
              <div className="tab-content">
                {tab.icon && (
                  <div className="tab-icon">
                    <img src={tab.icon} />
                  </div>
                )}
                <div className="tab-name">{tab.name}</div>
              </div>

              <Badge
                visible={tab.badge?.visible}
                amount={tab.badge?.amount}
                checked={tab.badge?.checked}
              />
            </div>
          );
        })}
      </div>

      <div className={`items-area`}>{current && current.component}</div>
    </div>
  );
}
