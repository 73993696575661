import {
  createSharedStateComputedProperties,
  searchableComputedProperty,
  GetIndexSchema,
  SB,
  payloadComputedProperty,
} from '@play-co/replicant';
import { onchainHoldersSharedStateSchema } from './onchainHolders.schema';
import { MAX_DISPLAYED_TOP_HOLDERS } from './onchainHolders.ruleset';

export const onchainHoldersComputedProperties =
  createSharedStateComputedProperties(onchainHoldersSharedStateSchema)({
    memeId: searchableComputedProperty(SB.string(), (state) => {
      return state.global.memeId;
    }),
    holders: payloadComputedProperty(
      SB.array(
        SB.object({
          walletAddress: SB.string(),
          tokenAmount: SB.string(),
          jettonTokenAmount: SB.string().optional(),
          dexTokenAmount: SB.string().optional(),
          userId: SB.string(),
          userName: SB.string().optional(),
          userImage: SB.string().optional(),
          isCreator: SB.boolean().optional(),
        }),
      ),
      (state) => {
        // only add top 100 holders to indexed page
        return state.global.holders.slice(0, MAX_DISPLAYED_TOP_HOLDERS);
      },
    ),
  });

export type TradingIndexSchema = GetIndexSchema<
  typeof onchainHoldersComputedProperties
>;
