import { Tutorials, TutorialCfg } from '../types';
import { AppController } from '../../Controllers/AppController';
import { getInitialTutorialSteps } from './initialTutorialSteps';
import { tutorialModules } from '../modules';
import { WaitForEndOfEnergy } from '../modules/energy/WaitForEndOfEnergy';
import { OutOfEnergyShareBtn } from '../modules/energy/OutOfEnergyShareBtn';
import { isTiktokOnlyExperience } from '../../utils';

export const tutorials: Record<Tutorials, (app: AppController) => TutorialCfg> =
  {
    [Tutorials.InitialTutorial]: (app) => {
      const steps = getInitialTutorialSteps(app);
      return {
        id: Tutorials.InitialTutorial,
        steps,
        onComplete: () => {},
        lastStep: steps.length - 1,
      };
    },
    // @todo: remove (POST SEASON 2 MIGRATION)
    // [Tutorials.EnergyTutorial]: (app) => {
    //   const steps = [new WaitForEndOfEnergy(app), new OutOfEnergyShareBtn(app)];
    //   return {
    //     id: Tutorials.EnergyTutorial,
    //     steps,
    //     onComplete: () => {},
    //     lastStep: steps.length,
    //   };
    // },
    [Tutorials.SlideshowTutorial]: (app) => {
      const steps = [
        new tutorialModules.SlideshowStart(app),
        new tutorialModules.SlideshowEnd(app),
        new tutorialModules.Grant100State(app),
      ];
      return {
        id: Tutorials.SlideshowTutorial,
        steps,
        onComplete: () => {},
        lastStep: steps.length,
      };
    },

    // todo: Deprecated on season 3. Remove?
    // [Tutorials.SlideshowTradingCreateToken]: (app) => {
    //   const steps = [new tutorialModules.SlideshowStart(app)];
    //   return {
    //     id: Tutorials.SlideshowTradingCreateToken,
    //     steps,
    //     onComplete: () => {},
    //     lastStep: steps.length,
    //     repeateable: true,
    //   };
    // },

    // @todo: remove (POST SEASON 2 MIGRATION)
    // [Tutorials.SlideshowSeasonKickOff]: (app) => {
    //   const steps = [new tutorialModules.SlideshowStart(app)];
    //   return {
    //     id: Tutorials.SlideshowSeasonKickOff,
    //     steps,
    //     onComplete: () => {
    //       app.replicant.invoke.kickOffNewSeason();
    //     },
    //     lastStep: steps.length,
    //     transparentBg: true,
    //     repeateable: true,
    //   };
    // },
  };

export const getFTUE = (app: AppController) => {
  return undefined;
};
