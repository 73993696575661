import { TradingTokenSearchField } from '../../../replicant/features/tradingMeme/tradingMeme.asyncGetters';
import { getOffchainTokenListing } from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import {
  TradingIndexSchema,
  TradingSearchResult,
} from '../../../replicant/features/tradingMeme/tradingMeme.properties';
import {
  memeSearchCount,
  season2StartTime,
} from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { TradingTokenListing } from '../../../replicant/features/tradingMeme/types';
import { AppController } from '../AppController';
import { Optional } from '../../types';
import { BusinessController } from '../BusinessController';
import { MemesEvents } from './MemesController';
import { MemeFilters, SearchFilters, tokenListFilters } from './types';
import { QueryFilter } from '@play-co/replicant';
import { api } from '@play-co/replicant/lib/core/api/API';
import { TradingMemeStatus } from '../../../replicant/features/tradingMeme/tradingMeme.schema';

interface MemeListOpts {
  prefetch?: boolean;
  filter: MemeFilters;
  onUpdate: (evt: MemesEvents) => void;
}

interface Paginator {
  lastOffchainTokenSortFieldValue: number;
  lastOffchainTokenAvailabilityTime: number;
}

export class MemeList extends BusinessController<''> {
  protected list: TradingSearchResult[] = [];

  protected paginator?: Paginator;

  protected endOfList = false;

  protected listingMap: Record<string, TradingTokenListing> = {};

  protected tokenIds: string[] = [];

  protected get isActive(): boolean {
    return this.app.memes.currentList.filter === this.filter;
  }

  protected get filter() {
    return this.opts.filter;
  }

  protected _listing: TradingTokenListing[] = [];
  public get listing() {
    return this._listing;
  }

  public get firstItem() {
    return this.listing[0];
  }

  private searchTerm?: string;

  constructor(app: AppController, private opts: MemeListOpts) {
    super(app);
  }

  public init = async () => {
    // Clear search when search page is closed
    this.app.views.TiktokSearchPage.onVisibilityChange((visible) => {
      if (!visible) {
        this.resetSearch();
      }
    });

    this.onInitComplete();
  };

  /**
   * This should only be used for internal meme stuff; It's the raw data, wont be needed outside the API
   * @returns
   */
  public getSearchResults = () => {
    return this.list.filter((t) => this.tokenIds.includes(t.id));
  };

  public getItem = (tokenId: string) => {
    return this.listingMap[tokenId];
  };

  public resetSearch = () => {
    this.searchTerm = undefined;
    this.requestListing(true);
  };

  public search = async (searchString: string) => {
    this.searchTerm = searchString;
    this.requestListing(true);
  };

  public getSearchTermLength = () => {
    return this.searchTerm === undefined ? 0 : this.searchTerm.length;
  };

  public paginate = async () => {
    // To paginate the initial fetch has to be done, so it can't be undefined
    if (!this.paginator) {
      return;
    }
    this.requestListing();
  };

  public isEndOfList = () => {
    return this.endOfList;
  };

  public requestUpdate = async (refresh: Optional<'refresh'> = undefined) => {
    return this.requestListing(Boolean(refresh));
  };

  protected requestListing = async (reset = false) => {
    const searchField =
      tokenListFilters.searchable[this.filter as SearchFilters];

    if (this.endOfList && !reset) {
      return;
    }

    const initialPagination = reset || this.paginator === undefined;

    // undefined gets the first page
    const lessThan = initialPagination
      ? undefined
      : this.paginator?.lastOffchainTokenSortFieldValue;
    const availableAfterIfEqual = initialPagination
      ? undefined
      : this.paginator?.lastOffchainTokenAvailabilityTime;

    const props = {
      searchString: this.searchTerm,
      field: searchField,
      lessThan,
      availableAfterIfEqual,
    };

    function test({
      searchString,
      field,
      lessThan,
      availableAfterIfEqual,
      limit = memeSearchCount,
      randomSort = false,
    }: {
      searchString: string | undefined;
      field: TradingTokenSearchField;
      lessThan?: number;
      availableAfterIfEqual?: number | undefined;
      limit?: number;
      randomSort?: boolean;
    }) {
      let results: TradingSearchResult[] = [];

      let conditions: QueryFilter<TradingIndexSchema>[] = [];

      const lessThanCondition: {
        availableAt?: { lessThan: number; greaterThanOrEqual: number };
        top?: { lessThan: number; greaterThanOrEqual: number };
        volume?: { lessThan: number; greaterThanOrEqual: number };
        pointSupply?: { lessThan: number; greaterThanOrEqual: number };
        tokenSupply?: { lessThan: number; greaterThanOrEqual: number };
      } = {};

      conditions.push(lessThanCondition);

      if (lessThan !== undefined) {
        if (field === 'availableAt') {
          lessThan = Math.round(lessThan);
        }

        lessThanCondition[field] = {
          lessThan: lessThan,
          greaterThanOrEqual: 0,
        };

        if (availableAfterIfEqual !== undefined && field !== 'availableAt') {
          const equalityCondition: {
            availableAt: { greaterThan: number };
            top?: { between: [number, number] };
            volume?: { between: [number, number] };
            pointSupply?: { between: [number, number] };
            tokenSupply?: { between: [number, number] };
          } = {
            availableAt: {
              greaterThan: Math.max(season2StartTime, availableAfterIfEqual),
            },
          };

          equalityCondition[field] = { between: [lessThan, lessThan] };

          conditions.push(equalityCondition);
        }
      }

      // add the moderation constraint
      conditions.forEach((condition) => {
        condition.status = {
          isAnyOf: [
            TradingMemeStatus.Moderated,
            TradingMemeStatus.ModeratedOS,
            TradingMemeStatus.Reported,
            TradingMemeStatus.Created,
          ],
        };
      });

      if (searchString) {
        // add the search constraint
        conditions = conditions.reduce((conditionsWithSearch, condition) => {
          conditionsWithSearch.push({
            ...condition,
            profile: { name: { matchesAnyOf: [searchString] } },
          });

          conditionsWithSearch.push({
            ...condition,
            profile: { creatorName: { matchesAnyOf: [searchString] } },
          });

          return conditionsWithSearch;
        }, [] as QueryFilter<TradingIndexSchema>[]);
      }

      const searchProps = {
        // @note: not sure why type checker complains
        // @ts-ignore
        where: conditions,
        sort: randomSort
          ? 'random'
          : [
              { field, order: 'desc' },
              { field: 'availableAt', order: 'asc' },
            ],
        limit,
      };

      console.log({ searchProps });
    }

    console.log('requestListing', { props });
    test(props);

    let offchainTokens;
    const referredTokenId = this.app.memes.getReferredTokenId();
    if (this.filter === 'Hot' && referredTokenId && initialPagination) {
      // inject referred token at top of hot list
      const hotTokensRequest = this.app.asyncGetters.searchMemes(props);

      // @note: parallelizing requests
      const referredToken = await this.app.asyncGetters.getMemesFromOpenSearch({
        memeIds: [referredTokenId],
      });

      offchainTokens = await hotTokensRequest;

      if (referredToken && referredToken.length > 0) {
        const referredTokenIdx = offchainTokens.findIndex((offchainToken) => {
          return offchainToken.id === referredTokenId;
        });

        if (referredTokenIdx >= 0) {
          offchainTokens.splice(referredTokenIdx, 1);
        }

        // insert token at the beginning
        offchainTokens.unshift(referredToken[0]);
      }
    } else {
      offchainTokens = await this.app.asyncGetters.searchMemes(props);
    }

    this.log(
      'requestListing',
      {
        reset,
        props,
        offchainTokens,
      },
      true,
    );

    // Update paginator
    this.paginator = getNextPaginator({
      offchainTokens,
      searchField,
      currentPaginator: this.paginator,
      isRefresh: reset,
    });

    this.endOfList = this.paginator.lastOffchainTokenSortFieldValue === -1;

    if (offchainTokens.length > 0) {
      this.updateListing(offchainTokens, reset);
    }
  };

  protected updateListing = (tokens: TradingSearchResult[], reset: boolean) => {
    const newList: TradingSearchResult[] = [];
    // Separate new items in a new list and update items already in list
    tokens.forEach((token) => {
      // If we already have it cached, then it's an update
      const targetIndex = this.list.findIndex(({ id }) => id === token.id);
      if (targetIndex < 0) {
        newList.push(token);
      } else {
        this.list[targetIndex] = token;
      }
    });
    // Update the list with the new items
    this.list = reset ? tokens : [...this.list, ...newList];

    // Update the list of ids based on the updated list
    this.tokenIds = this.list.map((t) => t.id);
    // Update the listing array
    this._listing = this.getListing(this.list);
    // Update the listing map based on the updated list
    this.listingMap = this.getListingMap(this._listing);
    // Send event to let it know we have new data
    this.log('updateListing', {
      list: this.list,
      active: this.isActive,
      listing: this.listingMap,
    });

    if (this.isActive) {
      this.opts?.onUpdate(MemesEvents.OnListingUpdate);
    }
  };

  // Utils
  protected getListing = (items: TradingSearchResult[]) => {
    return items.map(getOffchainTokenListing);
  };

  protected getListingMap = (items: TradingTokenListing[]) => {
    return items.reduce((res, cur) => {
      res[cur.offchainTokenId] = cur;
      return res;
    }, {} as Record<string, TradingTokenListing>);
  };

  protected log = (
    method: string,
    data: Record<string, any>,
    enabled = false,
  ) => {
    if (enabled) {
      console.log(
        `%cMemeList(${this.filter}):${method}`,
        `color: #bc8c5b`,
        data,
      );
    }
  };
}

interface PaginatorOpts {
  offchainTokens: TradingSearchResult[];
  searchField: TradingTokenSearchField;
  currentPaginator?: Paginator;
  isRefresh: boolean;
}

export const getNextPaginator = ({
  offchainTokens,
  searchField,
  currentPaginator,
  isRefresh,
}: PaginatorOpts) => {
  if (isRefresh && currentPaginator) {
    return currentPaginator;
  }
  // Default
  if (offchainTokens.length < memeSearchCount) {
    return {
      lastOffchainTokenSortFieldValue: -1,
      lastOffchainTokenAvailabilityTime: -1,
    };
  }
  const lastToken = offchainTokens[offchainTokens.length - 1];

  return {
    lastOffchainTokenSortFieldValue: lastToken[searchField],
    lastOffchainTokenAvailabilityTime: lastToken.availableAt,
  };
};
