import { useEffect, useState } from 'react';

type Listener = (value: () => void) => () => void;

interface Trigger {
  listener: Listener;
  dep?: boolean;
  debug?: boolean;
}

interface ReRenderOpts {
  id: string;
  events: Trigger[];
}

export const useManyAppUpdates = ({ id, events }: ReRenderOpts) => {
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    if (events.length === 0) {
      return;
    }

    const activeEvents = events.filter((evt) => evt.dep !== false);

    const unsubscribeList = activeEvents.map((evt) => {
      const callback = () => {
        const timestamp = Date.now();
        if (evt.debug) {
          console.log(`Re-render ${id}`);
        }
        setRenderCount(timestamp);
      };
      return evt.listener(callback);
    });

    return () => {
      unsubscribeList.forEach((unsub) => unsub());
    };
  }, [renderCount, events]);
};
