import './TradingTokenTabs.scss';
import { t } from 'i18next';
import { app } from '../../../../data/Controllers/AppController';
import { useEffect, useState } from 'react';
import { Tabs, TabStyle } from '../../../shared/Tabs/Tabs';
import {
  TradingTokenTransactionItem,
  TradingAbout,
  TradingOverview,
  TradingPriceArea,
  TradingTokenHoldersItem,
} from './TradingTokenTabsContent';
import { TradingTokenHolder } from '../../../../replicant/features/tradingMeme/types';

type TradingTokenCategories = 'Overview' | 'About' | 'Holders' | 'Transactions';
interface Props {
  onTabChange: () => void;
}
export const TradingTokenTabs = ({ onTabChange }: Props) => {
  const initialTab = 'Overview';
  const [currentTab, setCurrentTab] = useState(initialTab);

  const [holders, setHolders] = useState<TradingTokenHolder[]>([]);

  const token = app.memes.currentMeme.meme;

  const onTabClick = (
    tab: TradingTokenCategories,
    _prev: TradingTokenCategories,
  ) => {
    setCurrentTab(tab);
    onTabChange();

    if (token) {
      app.track('memeoffchainToken_detail_tab_view', {
        tab_name: tab,
        memeoffchainToken_name: token.name,
        memeoffchainTokenID: token.id,
        current_price: token.pointPrice,
        total_holders: token.holderCount,
      });
    }
  };

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        app.memes.trading.getHolders().then(setHolders);
      }, 2000);
    }
  }, [token?.tokenSupply]);

  // --------------------------------
  // render

  if (!token) {
    return null;
  }

  const transactions = token?.offchainTxs.slice().reverse() || [];

  return (
    <div className="trading-token-tabs-container">
      <Tabs<TradingTokenCategories>
        tabStyle={TabStyle.Squared}
        initial={initialTab as TradingTokenCategories}
        onTabClick={onTabClick}
        // autoSelected={undefined}
        // uiState={app.ui.state.mine.btnTab}
        tabs={[
          {
            id: 'Overview',
            name: t('trading_token_tabs_overview'),
            badge: { visible: false, amount: 3, checked: false },
            component: (
              <>
                <TradingOverview />
                <TradingPriceArea showChart={true} />
              </>
            ),
          },

          {
            id: 'About',
            name: t('trading_token_tabs_about'),
            badge: { visible: false, amount: 2, checked: false },
            component: (
              <>
                <TradingAbout />
                <TradingPriceArea />
              </>
            ),
          },

          {
            id: 'Transactions',
            name: t('trading_token_tabs_transactions'),
            badge: { visible: false, amount: 1, checked: false },
            component: (
              <>
                <div className="items-list transactions">
                  {transactions.map((tx, i) => (
                    <TradingTokenTransactionItem
                      key={'tx_' + tx.createdAt + `_${i}`}
                      tx={tx}
                    />
                  ))}
                </div>
                <TradingPriceArea />
              </>
            ),
          },

          {
            id: 'Holders',
            name: t('trading_token_tabs_holders'),
            badge: { visible: false, amount: 1, checked: false },
            component: (
              <>
                <div className="items-list transactions">
                  {holders.map((item, index) => (
                    <TradingTokenHoldersItem
                      key={'holder_' + index} //  + '_' + item.id}
                      data={item}
                    />
                  ))}
                </div>
                <TradingPriceArea />
              </>
            ),
          },
        ]}
      />
    </div>
  );
};
