import {
  createSharedStateMessages,
  createSharedStateMessage,
  SB,
} from '@play-co/replicant';
import {
  TradingState,
  tradingMemeSharedStateSchema,
  tradingMemeDetailsSchema,
  PriceSlice,
  PriceTrends,
  FailedTxReason,
  StatsSlice,
  TradingMemeStatus,
  onchainTxSchema,
  jettonTxSchema,
} from './tradingMeme.schema';
import {
  calculateMintTonPrice,
  getBuyEstimate,
  getCanBuy,
  getCanSell,
  getDayTime,
  getOnchainCurvePrice,
  getSellEstimate,
  isMemeGraduated,
  scoreToPoints,
} from './tradingMeme.getters';
import {
  failedTxLifespan,
  fixedSliceTimeWindows,
  FixedSliceTimeWindows,
  maxTokenAllTimeSliceCount,
  maxOffchainTxCount,
  tokenPriceSliceConfigs,
  SliceConfig,
  fixedSliceStatsTimeWindows,
  FixedSliceStatsTimeWindows,
  tokenStatsSliceConfigs,
  giftTokenCoinAmount,
  maxOnchainTxCount,
} from './tradingMeme.ruleset';
import { HighPrecision, HP } from '../../lib/HighPrecision';
import { getDayMidnightInUTC } from '../../utils/time';

function addPriceToTrend(
  trend: PriceSlice[],
  sliceConfig: SliceConfig,
  price: string,
  supply: string,
  time: number,
) {
  let firstDataPoint = {
    price,
    supply,
    time,
  };

  while (trend.length > 0) {
    firstDataPoint = trend[0];
    if (trend[0].time >= time - sliceConfig.window) {
      break;
    }

    // slice is out of the time window
    // time to kick some butts
    trend.shift();
  }

  const windowStartTime =
    sliceConfig.interval *
    Math.floor((time - sliceConfig.window) / sliceConfig.interval);
  if (trend.length === 0 || trend[trend.length - 1].time !== windowStartTime) {
    trend.unshift({
      time: windowStartTime,
      supply: firstDataPoint.supply,
      price: firstDataPoint.price,
    });
  }

  // setting the price for the NEXT price slice
  // the reason is that the last transaction of a slice sets the entry price of the price slice coming after it
  const sliceTime =
    sliceConfig.interval * Math.floor(time / sliceConfig.interval);
  const nextSliceTime = sliceTime + sliceConfig.interval;
  if (trend[trend.length - 1].time < nextSliceTime) {
    // create next slice
    trend.push({
      time: nextSliceTime,
      supply,
      price,
    });
  } else if (trend[trend.length - 1].time < sliceTime) {
    // last slice is already the next slice
    trend[trend.length - 1].price = price;
  } else {
    // ignore price point
  }
}

function addPriceToTrends(
  trends: PriceTrends,
  price: string,
  supply: string,
  time: number,
) {
  for (let i = 0; i < fixedSliceTimeWindows.length; i += 1) {
    const timeWindow = fixedSliceTimeWindows[i];
    const sliceConfig =
      tokenPriceSliceConfigs[timeWindow as FixedSliceTimeWindows];
    const trend = trends[timeWindow as FixedSliceTimeWindows];
    addPriceToTrend(trend, sliceConfig, price, supply, time);
  }

  // update slices for the allTime/variable time window
  const allTimeTrend = trends.allTime;
  if (
    allTimeTrend.length === 0 ||
    allTimeTrend[allTimeTrend.length - 1].time < time
  ) {
    allTimeTrend.push({ time, price, supply });
  }

  // @note: only one iteration of the loop should happen
  while (allTimeTrend.length > maxTokenAllTimeSliceCount) {
    const timeWindow =
      allTimeTrend[allTimeTrend.length - 1].time - allTimeTrend[0].time;

    let smallestInterval = timeWindow;
    let smallestIntervalSliceIdx = -1;
    let previousTime = allTimeTrend[0].time;
    for (let i = 1; i < allTimeTrend.length - 1; i += 1) {
      const slice = allTimeTrend[i];

      // @todo?
      // prioritize merges of distance point in times by multipltying interval by a distance coefficient,
      // it virtually makes distant intervals smaller
      // const distanceCoeff = Math.pow(i / allTimeTrend.length, 0.5);
      // const interval = distanceCoeff * (slice.time - previousTime);

      const interval = slice.time - previousTime;
      if (interval < smallestInterval) {
        smallestInterval = interval;
        smallestIntervalSliceIdx = i;
      }
      previousTime = slice.time;
    }

    trends.allTime.splice(smallestIntervalSliceIdx, 1);
  }
}

function addStats(
  stats: StatsSlice[],
  sliceConfig: SliceConfig,
  volume: string,
  holderCount: number,
  time: number,
) {
  const window = sliceConfig.window;
  while (stats.length > 0) {
    const firstStats = stats[0];
    if (firstStats.time >= time - window) {
      break;
    }

    // slice is out of the time window
    // time to kick some butts
    stats.shift();
  }

  const interval = sliceConfig.interval;

  // setting the stats for the NEXT price slice
  // the reason is that the last transaction of a slice sets the entry price of the price slice coming after it
  const sliceTime = interval * Math.floor(time / interval);
  const sliceIdx = stats.findIndex((statsSlice) => {
    return statsSlice.time >= sliceTime;
  });

  if (sliceIdx === -1) {
    // no slice on which to add stats
    // create next slice
    stats.push({
      time: sliceTime,
      volume,
      holderCount,
    });
  } else {
    // there is a slice on which to update stats
    const existingStatsSlice = stats[sliceIdx];
    if (existingStatsSlice.time === sliceTime) {
      // existing slice corresponds to current slice time
      existingStatsSlice.volume = HP(existingStatsSlice.volume)
        .add(volume)
        .toString();
      existingStatsSlice.holderCount = holderCount;
    } else {
      // existing slice is after current slice time (existingStatsSlice.time > sliceTime)
      stats.push({
        time: sliceTime,
        volume,
        holderCount,
      });

      stats.sort((a, b) => {
        return a.time - b.time;
      });
    }
  }
}

function addTxStats(state: TradingState, currencyAmount: string, time: number) {
  const stats = state.stats;
  const holderCount = state.holderCount;

  for (let i = 0; i < fixedSliceStatsTimeWindows.length; i += 1) {
    const timeWindow = fixedSliceStatsTimeWindows[i];
    const sliceConfig =
      tokenStatsSliceConfigs[timeWindow as FixedSliceStatsTimeWindows];
    const windowStats = stats[timeWindow as FixedSliceStatsTimeWindows];
    addStats(windowStats, sliceConfig, currencyAmount, holderCount, time);
  }
}

function curateOffchainTransactions(state: TradingState) {
  // limit number of transactions on state
  if (state.offchainTxs.length > maxOffchainTxCount) {
    state.offchainTxs = state.offchainTxs.slice(
      state.offchainTxs.length - maxOffchainTxCount,
    );
  }
}

function curateOnchainTransactions(state: TradingState) {
  // limit number of transactions on state
  if (state.onchainTxs.length > maxOnchainTxCount) {
    state.onchainTxs = state.onchainTxs.slice(
      state.onchainTxs.length - maxOnchainTxCount,
    );
  }
}

function updateDataPoints(
  state: TradingState,
  time: number,
  price: string,
  supply: string,
) {
  // update trends/price slices
  addPriceToTrends(state.trends, price, supply, time);
  addTxStats(
    state,
    state.onchainTxs[state.onchainTxs.length - 1].currencyAmount,
    time,
  );
}

function updateUserHoldingStats(
  state: TradingState,
  tokensHeldBefore: string,
  tokensHeldNow: string,
) {
  const tokensHeldBeforeNum = HP(tokensHeldBefore);
  const tokensHeldNowNum = HP(tokensHeldNow);

  let holderCountChange = 0;
  if (tokensHeldBeforeNum.lte(0) && tokensHeldNowNum.gt(0)) {
    holderCountChange += 1;
  } else if (tokensHeldBeforeNum.gt(0) && tokensHeldNowNum.lte(0)) {
    holderCountChange -= 1;
  }

  state.holderCount += holderCountChange;

  if (state.holderCount === 0) {
    state.sumOfHoldingsSquare = '0';
  } else {
    const sumOfHoldingsSquareBefore = HP(state.sumOfHoldingsSquare);
    const sumOfHoldingsSquare = sumOfHoldingsSquareBefore
      .minus(HP(tokensHeldBefore).pow(2))
      .plus(HP(tokensHeldNow).pow(2));

    state.sumOfHoldingsSquare = sumOfHoldingsSquare.toString();
  }
}

function addFailedTx(
  state: TradingState,
  createdAt: number,
  userId: string,
  reason?: FailedTxReason,
) {
  // filter out outdated failex transactions
  const failedOffchainTxs = state.failedOffchainTxs.filter((failedTx) => {
    return failedTx.createdAt > createdAt - failedTxLifespan;
  });

  failedOffchainTxs.push({
    createdAt,
    userId,
    reason,
  });

  state.failedOffchainTxs = failedOffchainTxs;
}

function addToSupply(state: TradingState, pointAmount: HighPrecision) {
  state.pointSupply = HP(state.pointSupply).add(pointAmount).toString();

  const pointsDistributed = HP(state.pointsDistributed).add(pointAmount);
  state.pointsDistributed = pointsDistributed.toString();
}

function addDailyPoints(
  state: TradingState,
  timestamp: number,
  points: HighPrecision,
) {
  if (!isMemeGraduated(state)) {
    // daily points only useful after listing
    return;
  }

  const dateUTC = getDayTime(timestamp);
  state.dailyPoints[dateUTC] = HP(state.dailyPoints[dateUTC] || 0)
    .add(points)
    .toString();
}

function giftToken(
  state: TradingState,
  data: {
    timestamp: number;
    buyerId: string;
    buyerName?: string;
    buyerImage?: string;
    expectedTxIdx: number;
    coinAmount: number | string;
  },
) {
  const {
    timestamp,
    buyerId,
    buyerName,
    buyerImage,
    expectedTxIdx,
    coinAmount,
  } = data;

  const isExpectedState = state.offchainTxs.length === expectedTxIdx;

  if (!isExpectedState) {
    const reason = isExpectedState ? undefined : 'concurrencyIssue';
    addFailedTx(state, timestamp, buyerId, reason);
    return;
  }

  const currencyAmountNum = HP(coinAmount);
  const currencyAmount = currencyAmountNum.toString();
  const pointAmount = getBuyEstimate(state, currencyAmountNum);

  addToSupply(state, pointAmount);

  state.offchainTxs.push({
    txType: 'buy',
    userId: buyerId,
    userName: buyerName,
    userImage: buyerImage,
    createdAt: timestamp,
    currencyAmount,
    pointAmount: pointAmount.toString(),
    currency: 'gift',
  });

  curateOffchainTransactions(state);

  addDailyPoints(state, timestamp, pointAmount);
}

// All props should be optional
const periodicUpdateSchema = SB.object({
  sharesToAdd: SB.number().optional(),
  pointsToAdd: SB.int().optional(),
});

export type PeriodicUpdate = SB.ExtractType<typeof periodicUpdateSchema>;

// @warning: never remove/rename shared state messages
export const tradingMemeMessages = createSharedStateMessages(
  tradingMemeSharedStateSchema,
)({
  /**
   * This actually updates the offchainToken (create is done via api request) but since we require
   * creator info I called it 'createMeme'
   */
  createMemeMessage: createSharedStateMessage(
    SB.object({
      details: tradingMemeDetailsSchema,
      timestamp: SB.int(),
      // currencyAmount: SB.string(),
      isDev: SB.boolean().optional(),
      jettonContractAddress: SB.string(),
    }),
    (
      state,
      {
        details,
        timestamp,
        // currencyAmount,
        isDev,
        jettonContractAddress,
      },
      meta,
    ) => {
      // Set offchainToken details
      state.global.details = details;
      state.global.jettonContractAddress = jettonContractAddress;
      state.global.status = TradingMemeStatus.Created;

      // const supply = getCreateEstimate(Big(currencyAmount));

      // state.global.pointSupply = supply.toString();
      // state.global.pointsDistributed = supply.toString();
      // state.global.offchainTxs.push({
      //   txType: 'buy',
      //   userId: details.creatorId,
      //   userName: details.creatorName,
      //   userImage: details.creatorImage,
      //   createdAt: timestamp,
      //   currencyAmount: '0',
      //   pointAmount: supply.toString(),
      // });

      if (isDev) {
        state.global.status = TradingMemeStatus.Moderated;
      }

      const tokenPrice = getOnchainCurvePrice(HP(0));
      updateDataPoints(state.global, timestamp, tokenPrice.toString(), '0');
    },
  ),
  editOffchainToken: createSharedStateMessage(
    SB.object({
      telegramChannelLink: SB.string().optional(),
      telegramChatLink: SB.string().optional(),
      twitterLink: SB.string().optional(),
    }),
    (state, { telegramChannelLink, telegramChatLink, twitterLink }, meta) => {
      state.global.details.telegramChannelLink = telegramChannelLink;
      state.global.details.telegramChatLink = telegramChatLink;
      state.global.details.twitterLink = twitterLink;
    },
  ),

  attemptBuyOffchainToken: createSharedStateMessage(
    SB.object({
      timestamp: SB.int(),
      expectedTxIdx: SB.int(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
      currencyAmount: SB.string(), // how much currency wants to invest
      pointAmountEstimate: SB.string(),
      driftPct: SB.number(),
    }),
    (
      state,
      {
        timestamp,
        expectedTxIdx,
        buyerId,
        buyerName,
        buyerImage,
        currencyAmount,
        pointAmountEstimate,
        driftPct,
      },
      meta,
    ) => {
      let currencyAmountNum = HP(currencyAmount);
      let pointAmountEstimateNum = HP(pointAmountEstimate);
      const canBuy = getCanBuy(state.global, {
        currencyAmount: currencyAmountNum,
        pointAmountEstimate: pointAmountEstimateNum,
        driftPct,
      });

      const isExpectedState = state.global.offchainTxs.length === expectedTxIdx;
      const createdAt = timestamp ?? meta.timestamp;
      if (!canBuy || !isExpectedState) {
        const reason = isExpectedState ? undefined : 'concurrencyIssue';
        addFailedTx(state.global, createdAt, buyerId, reason);
        return;
      }

      const pointAmount = getBuyEstimate(state.global, currencyAmountNum);

      addToSupply(state.global, pointAmount);

      state.global.offchainTxs.push({
        txType: 'buy',
        userId: buyerId,
        userName: buyerName,
        userImage: buyerImage,
        createdAt,
        currencyAmount,
        pointAmount: pointAmount.toString(),
      });

      curateOffchainTransactions(state.global);
    },
  ),

  attemptSellOffchainToken: createSharedStateMessage(
    SB.object({
      timestamp: SB.int(),
      expectedTxIdx: SB.int(),
      sellerId: SB.string(),
      sellerName: SB.string().optional(),
      sellerImage: SB.string().optional(),
      pointAmount: SB.string(), // how many tokens wants to sell
      currencyAmountEstimate: SB.string(), // how much the user has been show it would receive
      driftPct: SB.number(), // how much off mark can it be and still be acceptable
    }),
    (
      state,
      {
        timestamp,
        expectedTxIdx,
        sellerId,
        sellerName,
        sellerImage,
        pointAmount,
        currencyAmountEstimate,
        driftPct,
      },
      meta,
    ) => {
      const pointAmountNum = HP(pointAmount);
      const currencyAmountEstimateNum = HP(currencyAmountEstimate);
      const sellPrice = getSellEstimate(state.global, pointAmountNum);

      if (!sellPrice.gt(0)) {
        addFailedTx(state.global, timestamp, sellerId);
        return;
      }

      const canSell = getCanSell(state.global, {
        pointAmount: pointAmountNum,
        currencyAmountEstimate: currencyAmountEstimateNum,
        driftPct,
      });
      const isExpectedState =
        expectedTxIdx === undefined ||
        state.global.offchainTxs.length === expectedTxIdx;
      if (!canSell || !isExpectedState) {
        const reason = isExpectedState ? undefined : 'concurrencyIssue';
        addFailedTx(state.global, timestamp, sellerId, reason);
        return;
      }

      const currentSupply = HP(state.global.pointSupply);
      const newSupply = currentSupply.minus(pointAmountNum);
      state.global.pointSupply = newSupply.toString();
      state.global.offchainTxs.push({
        txType: 'sell',
        userId: sellerId,
        userName: sellerName,
        userImage: sellerImage,
        createdAt: timestamp,
        currencyAmount: sellPrice.toString(),
        pointAmount,
      });

      curateOffchainTransactions(state.global);
    },
  ),

  updateStatus: createSharedStateMessage(
    SB.object({
      status: SB.string(),
      image: SB.string().optional(),
      moderatedImage: SB.string().optional(),
    }),
    (state, { status, image, moderatedImage }, info) => {
      if (
        !Object.values(TradingMemeStatus).includes(status as TradingMemeStatus)
      ) {
        throw new Error('Invalid status');
      }
      state.global.status = status;
      if (image) {
        state.global.details.image = image;
      }
      if (moderatedImage) {
        state.global.details.moderatedImage = moderatedImage;
      }
    },
  ),

  addImageUrl: createSharedStateMessage(
    SB.object({ image: SB.string() }),
    (state, { image }, info) => {
      state.global.details.image = image;
    },
  ),

  // setSumOfHoldingsSquare: createSharedStateMessage(
  //   SB.object({ sumOfHoldingsSquare: SB.string() }),
  //   (state, { sumOfHoldingsSquare }, info) => {
  //     state.global.sumOfHoldingsSquare = sumOfHoldingsSquare;
  //   },
  // ),

  updateHoldingStats: createSharedStateMessage(
    SB.object({
      holderCount: SB.int(),
      tokenSupply: SB.string(),
      sumOfHoldingsSquare: SB.string(),
    }),
    (state, { holderCount, tokenSupply, sumOfHoldingsSquare }, info) => {
      state.global.holderCount = holderCount;
      state.global.tokenSupply = tokenSupply;
      state.global.sumOfHoldingsSquare = sumOfHoldingsSquare;
    },
  ),

  periodicUpdate: createSharedStateMessage(
    periodicUpdateSchema,
    (state, { sharesToAdd }, info) => {
      if (sharesToAdd !== undefined) {
        state.global.shares += sharesToAdd;
      }
    },
  ),

  // awardMemeTokenGift: createSharedStateMessage(
  //   SB.object({
  //     timestamp: SB.int(),
  //     buyerId: SB.string(),
  //     buyerName: SB.string().optional(),
  //     buyerImage: SB.string().optional(),
  //     expectedTxIdx: SB.int(),
  //   }),
  //   (state, data, meta) => {
  //     giftToken(state.global, {
  //       ...data,
  //       coinAmount: giftTokenCoinAmount,
  //     });
  //   },
  // ),

  awardPointAmount: createSharedStateMessage(
    SB.object({
      timestamp: SB.int(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
      expectedTxIdx: SB.int(),
      coinAmount: SB.string(),
    }),
    (state, data, meta) => {
      giftToken(state.global, data);
    },
  ),

  exchangeScoreForPoints: createSharedStateMessage(
    SB.object({
      timestamp: SB.int(),
      score: SB.int(),
      buyerId: SB.string(),
      buyerName: SB.string().optional(),
      buyerImage: SB.string().optional(),
      pointsAccumulated: SB.string(),
      expectedTxIdx: SB.int(),
    }),
    (
      state,
      {
        score,
        buyerId,
        buyerName,
        buyerImage,
        timestamp,
        pointsAccumulated,
        expectedTxIdx,
      },
      meta,
    ) => {
      const isExpectedState =
        expectedTxIdx === undefined ||
        state.global.offchainTxs.length === expectedTxIdx;

      if (!isExpectedState) {
        const reason = isExpectedState ? undefined : 'concurrencyIssue';
        addFailedTx(state.global, timestamp, buyerId, reason);
        return;
      }

      const supplyNum = HP(state.global.pointSupply);
      const { points, equivalentCurrencyAmount } = scoreToPoints(
        supplyNum,
        pointsAccumulated,
        state.global.pointsDistributed,
        score,
      );

      const currencyAmount = equivalentCurrencyAmount.toString();

      addToSupply(state.global, points);

      state.global.offchainTxs.push({
        txType: 'buy',
        userId: buyerId,
        userName: buyerName,
        userImage: buyerImage,
        createdAt: timestamp,
        currencyAmount,
        pointAmount: points.toString(),
        currency: 'points',
      });
      curateOffchainTransactions(state.global);

      addDailyPoints(state.global, timestamp, points);
    },
  ),

  updateTokenAmountHeld: createSharedStateMessage(
    SB.object({
      tokenAmountHeldNow: SB.string(),
      tokenAmountHeldBefore: SB.string(),
    }),
    (
      state,
      {
        tokenAmountHeldNow,
        tokenAmountHeldBefore,
      }: { tokenAmountHeldNow: string; tokenAmountHeldBefore: string },
    ) => {
      updateUserHoldingStats(
        state.global,
        tokenAmountHeldBefore,
        tokenAmountHeldNow,
      );
    },
  ),

  // temporary placeholder for previous message schema that was used in dev
  // can remove after release
  addOnchainTxData: createSharedStateMessage(
    onchainTxSchema,
    (state, onchainTx, info) => {
      if (!process.env.STAGE) {
        // this should only be useful when testing locally
        // as local state cannot receive the onJettonContractMinted message
        state.global.jettonMinted = true;
      }

      state.global.onchainTxs.push(onchainTx);
    },
  ),

  addDexTxs: createSharedStateMessage(
    SB.object({
      dexTxs: SB.array(onchainTxSchema),
      firstDexTxLogicalTime: SB.string(),
      lastDexTxLogicalTime: SB.string(),
    }),
    (state, paylaod, info) => {
      if (paylaod.firstDexTxLogicalTime !== state.global.lastDexTxLogicalTime) {
        // another message was faster at updating the state
        return;
      }

      state.global.lastDexTxLogicalTime = paylaod.lastDexTxLogicalTime;
      state.global.lastOnchainTxUpdateTime = info.timestamp;

      paylaod.dexTxs.forEach((dexTx) => {
        state.global.onchainTxs.push(dexTx);

        curateOnchainTransactions(state.global);

        const isBuy = dexTx.txType === 'dexBuy';
        if (isBuy) {
          state.global.buyCount += 1;
        }

        const isSell = dexTx.txType === 'dexSell';
        if (isSell) {
          state.global.sellCount += 1;
        }

        const isClaim =
          dexTx.txType === 'dailyClaim' || dexTx.txType === 'graduationClaim';
        if (!isClaim) {
          const tokenAmount = HP(dexTx.tokenAmount);
          const currencyAmount = HP(dexTx.currencyAmount);
          const tokenPrice = HP(currencyAmount).div(tokenAmount).toString();

          updateDataPoints(
            state.global,
            dexTx.createdAt,
            tokenPrice,
            state.global.tokenSupply,
          );
        }
      });
    },
  ),

  updateLastOnchainTxUpdateTime: createSharedStateMessage(
    SB.unknown(),
    (state, paylaod, info) => {
      state.global.lastOnchainTxUpdateTime = info.timestamp;
    },
  ),

  addJettonTxs: createSharedStateMessage(
    SB.object({
      jettonTxs: SB.array(jettonTxSchema),
      firstJettonTxLogicalTime: SB.string(),
      lastJettonTxLogicalTime: SB.string(),
    }),
    (state, paylaod, info) => {
      // if we can read a jetton tx, it means the meme was minted
      state.global.jettonMinted = true;

      if (
        paylaod.firstJettonTxLogicalTime !==
        state.global.lastJettonTxLogicalTime
      ) {
        // another message was faster at updating the state
        return;
      }

      state.global.lastJettonTxLogicalTime = paylaod.lastJettonTxLogicalTime;
      state.global.lastOnchainTxUpdateTime = info.timestamp;

      paylaod.jettonTxs.forEach((jettonTx) => {
        const isDeploy = jettonTx.txType === 'deploy';
        const isBuy = jettonTx.txType === 'buy';
        const isSell = jettonTx.txType === 'sell';
        const supplyIncreased = isDeploy || isBuy;

        const tokenAmount = HP(jettonTx.tokenAmount);

        // compute how much was spent using supply before update
        const supplyBeforeTx = HP(state.global.tokenSupply);
        const currencyAmount = calculateMintTonPrice(
          supplyBeforeTx,
          tokenAmount,
          isBuy || isDeploy,
        );

        // @note: the liquidity increases with the token supply
        const liquidityDiff = supplyIncreased
          ? currencyAmount
          : currencyAmount.mul(-1);
        const tokenSupplyDiff = supplyIncreased
          ? tokenAmount
          : tokenAmount.mul(-1);

        state.global.liquidity = HP(state.global.liquidity)
          .add(liquidityDiff)
          .toString();

        state.global.tokenSupply = supplyBeforeTx
          .add(tokenSupplyDiff)
          .toString();

        state.global.onchainTxs.push({
          ...jettonTx,
          currencyAmount: currencyAmount.toString(),
        });

        curateOnchainTransactions(state.global);

        if (isBuy) {
          if (state.global.buyCount === 0) {
            state.global.firstBuyer = {
              userId: jettonTx.userId,
              walletAddress: jettonTx.walletAddress,
              userName: jettonTx.userName,
              userImage: jettonTx.userImage,
            };
          }

          state.global.buyCount += 1;
        }

        if (isSell) {
          state.global.sellCount += 1;
        }

        const tokenPrice = HP(currencyAmount).div(tokenAmount).toString();

        updateDataPoints(
          state.global,
          jettonTx.createdAt,
          tokenPrice,
          state.global.tokenSupply,
        );
      });
    },
  ),

  onJettonContractMinted: createSharedStateMessage(
    SB.object({}),
    (state, {}, info) => {
      state.global.jettonMinted = true;
    },
  ),

  onDexContractMinted: createSharedStateMessage(
    SB.object({
      contractAddress: SB.string(),
    }),
    (state, { contractAddress }, info) => {
      if (!state.global.dexContractAddress) {
        state.global.dexContractAddress = contractAddress;
        state.global.dexListingTime = info.timestamp;
      }
    },
  ),

  onDexGraduationTriggered: createSharedStateMessage(
    SB.object({}),
    (state, {}, info) => {
      state.global.isGraduated = true;
      state.global.onchainTxs = [];
      state.global.trends = {
        hour24: [],
        day7: [],
        day30: [],
        allTime: [],
      };

      state.global.stats = {
        hour24: [],
      };

      state.global.buyCount = 0;
      state.global.sellCount = 0;

      // those remain untouched
      // state.global.holderCount = 0;
      // state.global.sumOfHoldingsSquare = 0;
      // state.global.tokenSupply = 0;
      // state.global.firstBuyer = {};
    },
  ),
});
