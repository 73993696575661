import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { MutableState, State } from '../../schema';
import { TradingSearchResult } from './tradingMeme.properties';
import {
  CLAIM_DAY_DURATION,
  curveConfig,
  onchainCurveConfig,
  pointTxConfig,
  tmgRuleset,
  txConfig,
} from './tradingMeme.ruleset';
import {
  PriceSlice,
  StatsSlice,
  TradingState,
  OffchainTx,
  TxType,
  OnchainUserProfile,
} from './tradingMeme.schema';
import {
  TMGFarmingStatus,
  TradingOverview,
  TradingTokenListing,
} from './types';
import { ReplicantClient, ReplicantServer } from '../../config';
import {
  OTTG,
  OwnedOffchainMeme,
  WalletMemeHoldings,
  WalletProfile,
} from '../game/player.schema';
import { getTimeLeft } from '../game/game.getters';
import { DAY_IN_MS, HOUR_IN_MS, MIN_IN_MS, SEC_IN_MS } from '../../utils/time';
import { tests } from '../../ruleset';
import { getFeatureAb } from '../game/abtest.getters';
import { HP, HighPrecision } from '../../lib/HighPrecision';
import { fromNano } from '@ton/core';

type CurveConfig = {
  maxTargetPrice: bigint;
  initialPrice: bigint;
  maxSupply: bigint;
};

// Estimate Ton for Token amount
export function calculateTotalMintPrice(
  currentSupply: bigint,
  tokensToBuyOrSell: bigint,
  curveConfig: CurveConfig,
  isBuy: boolean,
): bigint {
  /**
   * Calculate the total mint price for buying or selling tokens based on a curve.
   *
   * @param currentSupply Current token supply.
   * @param tokensToBuyOrSell Number of tokens to buy or sell.
   * @param curveConfig Curve configuration (maxTargetPrice, initialPrice, maxSupply).
   * @param isBuy Boolean indicating if it is a buy (true) or sell (false) operation.
   * @return Total mint price as an integer (in nanoton).
   */
  const b: bigint = curveConfig.maxTargetPrice - curveConfig.initialPrice;
  const F: bigint = curveConfig.maxSupply ** BigInt(2);
  const precisionFactor: bigint = BigInt(1_000_000_000); // Large factor to maintain precision

  const power = BigInt(3);

  let price: bigint;

  if (isBuy) {
    const buyTerm =
      (((currentSupply + tokensToBuyOrSell) ** power - currentSupply ** power) *
        b *
        precisionFactor) /
      (power * F);
    price =
      curveConfig.initialPrice * tokensToBuyOrSell * precisionFactor + buyTerm;
  } else {
    const sellTerm =
      ((currentSupply ** power - (currentSupply - tokensToBuyOrSell) ** power) *
        b *
        precisionFactor) /
      (power * F);
    price =
      curveConfig.initialPrice * tokensToBuyOrSell * precisionFactor + sellTerm;
  }

  // Divide by the precision factor to scale down to intended integer range
  return price / precisionFactor;
}

export function calculateMintTonPrice(
  supply: HighPrecision,
  tokenAmount: HighPrecision,
  isBuy: boolean,
) {
  const mintPrice = calculateTotalMintPrice(
    BigInt(supply.toString()),
    BigInt(tokenAmount.toString()),
    onchainCurveConfig,
    isBuy,
  );

  return HP(fromNano(mintPrice).toString());
}

export function getOnchainCurvePrice(supply: HighPrecision) {
  const mintPrice = calculateTotalMintPrice(
    BigInt(supply.toString()),
    BigInt(1),
    onchainCurveConfig,
    true,
  );

  return HP(fromNano(mintPrice).toString());
}

export function getOffchainCurvePrice(supply: HighPrecision) {
  return curveConfig.startPrice.mul(
    Math.exp(supply.mul(curveConfig.exponent).toNumber()),
  );
}

export function getSupplyFromCurvePrice(price: HighPrecision) {
  return HP(Math.log(price.div(curveConfig.startPrice).toNumber())).div(
    curveConfig.exponent,
  );
}

function getPointAmountForCoinBuy(
  currentSupply: HighPrecision,
  coinsToSpend: HighPrecision,
  priceModifier = 1 + txConfig.fee,
) {
  let coinsToSpendValue = coinsToSpend;
  if (!(coinsToSpend instanceof HighPrecision)) {
    coinsToSpendValue = HP(coinsToSpend);
  }

  const adjustedCoinsToSpend = coinsToSpendValue
    .mul(curveConfig.exponent)
    // .div(curveConfig.startPrice.mul(txConfig.buyModifier).mul(priceModifier));
    .div(curveConfig.startPrice.mul(priceModifier));
  const term = adjustedCoinsToSpend.add(
    Math.exp(currentSupply.mul(curveConfig.exponent).toNumber()),
  );
  const pointAmount = HP(1)
    .div(curveConfig.exponent)
    .mul(
      HP(Math.log(term.toNumber())).minus(
        currentSupply.mul(curveConfig.exponent),
      ),
    );
  return pointAmount;
}

// @todo: equivalent "getGrossTonAmountForTokenSell"
export function getGrossCoinAmountForPointSell(
  currentSupply: HighPrecision,
  pointAmount: HighPrecision,
) {
  let pointAmountDiff = currentSupply.minus(pointAmount);
  if (pointAmountDiff.lt(0)) {
    pointAmountDiff = currentSupply;
  }

  const pricing = curveConfig.startPrice.div(curveConfig.exponent);

  const curve = HP(
    Math.exp(curveConfig.exponent.mul(currentSupply).toNumber()),
  );

  const cadence = HP(
    Math.exp(
      curveConfig.exponent.mul(currentSupply.minus(pointAmount)).toNumber(),
    ),
  );

  return pricing.mul(curve.minus(cadence));
}

// (1 - spread) * (1 - fee) * (constant / exponent) * (EXP(exponent * (total supply + amount to sell)) - EXP(exponent * total supply))
export function getCoinAmountForPointSell(
  currentSupply: HighPrecision,
  tokensToSell: HighPrecision,
) {
  const grossCurrencyAmount = getGrossCoinAmountForPointSell(
    currentSupply,
    tokensToSell,
  );
  // const netCurrencyAmount = grossCurrencyAmount.mul(
  //   txConfig.sellModifier.minus(txConfig.fee),
  // );
  const netCurrencyAmount = grossCurrencyAmount.mul(HP(1 - txConfig.fee));

  return netCurrencyAmount;
}

function getUnusedWallet() {
  // const zero = HP(0).toString();
  const wallet: WalletProfile = {
    lastTxTimestamp: 0,
    lastHoldingCheck: 0,
    memeHoldings: {} as Record<string, WalletMemeHoldings>,
    unconfirmedTxs: [],
    // currencySpent: zero,
    // currencyRecovered: zero,
    // portfolioTrends: {
    //   hour24: [],
    //   day7: [],
    //   day30: [],
    //   allTime: [],
    // },
  };

  return wallet;
}

export function getWallet(state: MutableState, walletAddress: string) {
  const wallets = state.trading.onchain.wallets;
  let wallet = wallets[walletAddress];
  if (!wallet) {
    wallet = wallets[walletAddress] = getUnusedWallet();
  }

  return wallet;
}

const unusedWalletEquivalent = getUnusedWallet();

export function getWalletStatus(
  state: State,
  walletAddress: string | undefined,
) {
  if (!walletAddress) {
    return unusedWalletEquivalent;
  }

  const walletHoldings = state.trading.onchain.wallets[walletAddress];
  if (!walletHoldings) {
    return unusedWalletEquivalent;
  }

  return walletHoldings;
}

export function getWalletHoldings(
  state: State,
  walletAddress: string | undefined,
) {
  return getWalletStatus(state, walletAddress).memeHoldings;
}

export function getOffchainMarketCap(supply: HighPrecision) {
  return getOffchainCurvePrice(supply).mul(supply);
}

export function getOnchainMarketCap(supply: HighPrecision) {
  return getOnchainCurvePrice(supply).mul(supply);
}

export function getMyOffchainMemeIds(state: State) {
  return Object.keys(state.trading.offchainTokens);
}

export function getMyOnchainMemeIds(
  state: State,
  walletAddress: string | undefined,
) {
  return Object.keys(getWalletHoldings(state, walletAddress));
}

export function getMyOffchainTokenPointAmount(
  state: State,
  offchainTokenId: string,
) {
  return state.trading.offchainTokens[offchainTokenId]?.pointAmount || '0';
}

export function getMyOffchainTokenPointsAccumulated(
  state: State,
  offchainTokenId: string,
) {
  return (
    state.trading.offchainTokens[offchainTokenId]?.pointsAccumulated || '0'
  );
}

function getOverview({
  details: { description },
  pointSupply,
  holderCount,
}: TradingState): TradingOverview {
  // @TODO: Put this in a const
  const shortDescription =
    description.length > 180
      ? `${description.substring(0, 180)}...`
      : description;

  return {
    shortDescription,
    marketCap: getOffchainMarketCap(HP(pointSupply)).toString(),
    numOfHolders: holderCount,
  };
}

function getDescription({
  details: {
    description,
    telegramChannelLink,
    telegramChatLink,
    twitterLink,
    websiteLink,
  },
}: TradingState) {
  return {
    description,
    telegramChannelLink,
    telegramChatLink,
    twitterLink,
    websiteLink,
  };
}

export function getOffchainTokenListing(
  searchableOffchainToken: TradingSearchResult,
): TradingTokenListing {
  return {
    offchainTokenId: searchableOffchainToken.id,
    image: searchableOffchainToken.profile.image,
    name: searchableOffchainToken.profile.name,
    creatorName: searchableOffchainToken.profile.creatorName,
    creatorImage: searchableOffchainToken.profile.creatorImage,
    creatorId: searchableOffchainToken.profile.creatorId,
    ticker: searchableOffchainToken.profile.ticker,
    marketCap: getOnchainMarketCap(HP(searchableOffchainToken.tokenSupply)),
    priceChange: searchableOffchainToken.priceChange,
    lastTx: searchableOffchainToken.lastTx,
    shares: searchableOffchainToken.shares,
  };
}

export function getTokenPrice(state: TradingState) {
  const priceTrend = state.trends.hour24;
  if (priceTrend.length === 0) {
    return HP(0).toString();
  }

  return priceTrend[priceTrend.length - 1]?.price;
}

export function getMeme(
  state: TradingState & { createdAt: number },
  memeId: string,
) {
  const {
    image,
    name,
    creatorId,
    creatorName,
    ticker,
    creatorImage,
    creatorWalletAddress,
  } = state.details;

  // @note: find a better solution that sticking unusable onchain data on meme
  return {
    id: memeId,
    image,
    name,
    creatorId,
    creatorName,
    creatorImage,
    ticker,
    // overview
    overview: getOverview(state),
    // description
    description: getDescription(state),
    // holders
    holderCount: state.holderCount,
    // transactions
    offchainTxs: state.offchainTxs,
    onchainTxs: state.onchainTxs,
    // prices
    pointPrice: getOffchainCurvePrice(HP(state.pointSupply)).toString(),
    // supplies
    pointSupply: state.pointSupply,
    firstBuyer: state.firstBuyer,
    buyCount: state.buyCount,
    sellCount: state.sellCount,
    // trends
    trends: state.trends,
    // stats
    stats: state.stats,
    // metrics
    changePerHour: -1, // is this applied for both sell and buy or would be one for each?,
    status: state.status,
    createdAt: state.createdAt,
    shares: state.shares,
    jettonContractAddress: state.jettonContractAddress, // "EQBvwuChGntX7kCwuTGr_IuGwNA076a8NCQScelHiV3euM7b", // Test jetton contract address
    dexContractAddress: state.dexContractAddress, // "EQApfjfy0Uuy-ixrFMrVWSEL4WMOsGDcTsha9i5lZUR5wMS2", // Test dex contract address
    creatorWalletAddress,
    isMinted: state.jettonMinted,
    isGraduated: state.isGraduated,
    lastOnchainTxUpdateTime: state.lastOnchainTxUpdateTime,
    // onchain data, might be overwritten by client
    tokenPrice: getOnchainCurvePrice(HP(state.tokenSupply)).toString(),
    tokenSupply: state.tokenSupply,
    marketCapTon: getOnchainMarketCap(HP(state.tokenSupply)).toString(),
    marketCapUsd: HP(0).toString(),
  };
}

export type Meme = ReturnType<typeof getMeme>;

export interface CanBuyOpts {
  currencyAmount: HighPrecision;
  pointAmountEstimate: HighPrecision;
  driftPct: number;
}

export function getCanBuy(state: TradingState, opts: CanBuyOpts) {
  const { currencyAmount, pointAmountEstimate, driftPct } = opts;
  const buyPointAmount = getBuyEstimate(state, currencyAmount);
  const minBuyPointAmount = pointAmountEstimate.minus(
    pointAmountEstimate.mul(driftPct - 1),
  );

  return buyPointAmount.gte(minBuyPointAmount);
}

export interface CanSellOpts {
  pointAmount: HighPrecision;
  currencyAmountEstimate: HighPrecision;
  driftPct: number;
}

export function getCanSell(state: TradingState, opts: CanSellOpts) {
  const { pointAmount, currencyAmountEstimate, driftPct } = opts;
  const sellPrice = getSellEstimate(state, pointAmount);

  const minSellPrice = currencyAmountEstimate.minus(
    currencyAmountEstimate.mul(driftPct - 1),
  );

  return sellPrice.gte(minSellPrice);
}

/**
 * Use this function to get the estimate token amount the user can purchase given the
 * currencyAmount. The estimate deducts the transactions fee. Use optional opts to modify the behaviour
 * @param state
 * @param currencyAmount
 * @returns the token amount that can be bought with the currencyAmount
 */
export const getBuyEstimate = (
  state: TradingState,
  currencyAmount: HighPrecision,
) => {
  return getBuyTxEstimate({
    currencyAmount,
    currentSupply: HP(state.pointSupply),
  });
};

export const getBuyPointEstimate = (
  state: TradingState,
  currencyAmount: HighPrecision,
  relativePointShare: number,
) => {
  return getBuyPointTxEstimate(
    {
      currencyAmount,
      currentSupply: HP(state.pointSupply),
    },
    relativePointShare,
  );
};

/**
 * Use this function to get the estimate amount of currencyAmount the user can get given the
 * pointAmount it wants to sell
 * @param state
 * @param pointAmount
 * @returns
 */
export const getSellEstimate = (
  state: TradingState,
  pointAmount: HighPrecision,
) => {
  return getCoinAmountForPointSell(HP(state.pointSupply), pointAmount);
};

export const getCreateEstimate = (currencyAmount: HighPrecision) => {
  return getPointAmountForCoinBuy(HP(0), currencyAmount);
};

interface SellTx {
  pointAmount: HighPrecision;
  currentSupply: HighPrecision;
}

interface BuyTx {
  currencyAmount: HighPrecision;
  currentSupply: HighPrecision;
}

export const getBuyTxEstimate = (opts: BuyTx) => {
  return getPointAmountForCoinBuy(opts.currentSupply, opts.currencyAmount);
};

export const getCoinToPointConversionRate = (relativePointShare: number) => {
  return (
    1 +
    pointTxConfig.maxModifier *
      Math.exp(-pointTxConfig.modifierSlope * relativePointShare)
  );
};

export const getBuyPointTxEstimate = (
  opts: BuyTx,
  relativePointShare: number,
) => {
  return getPointAmountForCoinBuy(
    opts.currentSupply,
    opts.currencyAmount,
    getCoinToPointConversionRate(relativePointShare),
  );
};

export const getIsPointsBuyTxValid = (userState: State, tx: BuyTx) => {
  return tx.currencyAmount.lte(userState.balance) && tx.currencyAmount.gt(0);
};

export const getIsPointsSellTxValid = (
  userState: State,
  offchainTokenId: string,
  tx: SellTx,
) => {
  const offchainToken = userState.trading.offchainTokens[offchainTokenId];

  if (!offchainToken || !tx) {
    return false;
  }

  const hasSufficientTokens = HP(offchainToken.pointAmount).gte(tx.pointAmount);

  const hasValidCoinAmount = getCoinAmountForPointSell(
    tx.currentSupply,
    tx.pointAmount,
  ).gte(1);

  return hasSufficientTokens && hasValidCoinAmount;
};

export const getTxWithinEstimate = (
  value: HighPrecision,
  estimate: HighPrecision,
  pct: number,
) => {
  const diff = value.minus(estimate).abs();
  const acceptableError = value.mul(pct - 1);
  return acceptableError.gte(diff);
};

export function getCanUserSell(
  state: State,
  cardId: string,
  pointAmount: HighPrecision = HP(0),
) {
  const myPointAmount = state.trading.offchainTokens[cardId]?.pointAmount;
  if (!myPointAmount) {
    return false;
  }
  return HP(myPointAmount).gte(pointAmount);
}

export async function confirmTransaction(
  api: ReplicantAsyncActionAPI<ReplicantServer>,
  userId: string,
  offchainTokenId: string,
  timestamp: number,
): Promise<
  | {
      transaction: OffchainTx;
      offchainToken: TradingState & { createdAt: number };
    }
  | undefined
> {
  await api.flushMessages();

  const offchainTokenState = await api.sharedStates.tradingMeme.fetch(
    offchainTokenId,
  );

  const offchainToken = offchainTokenState?.global;
  // This should be impossible
  if (!offchainToken) {
    throw new Error(
      `Cannot find offchainToken ${offchainTokenId} for transaction confirmation at time ${timestamp}`,
    );
  }

  const failedTx = offchainToken.failedOffchainTxs.find((failedTx) => {
    return failedTx.createdAt === timestamp && failedTx.userId === userId;
  });

  if (failedTx) {
    api.sendAnalyticsEvents([
      {
        eventType: 'OffchainTxFailed',
        eventProperties: {
          offchainTokenId,
          reason: failedTx.reason ?? 'unknown',
          createdAt: failedTx.createdAt,
        },
      },
    ]);
    return;
  }

  const transaction = offchainToken.offchainTxs.find((tx) => {
    return tx.createdAt === timestamp && tx.userId === userId;
  });

  if (transaction) {
    return {
      transaction,
      offchainToken,
    };
  }

  api.sendAnalyticsEvents([
    {
      eventType: 'OffchainTxFailed',
      eventProperties: {
        offchainTokenId,
        reason: 'transaction missing',
        txCount: offchainToken.offchainTxs.length,
        failedTxCount: offchainToken.failedOffchainTxs.length,
        timestamp,
      },
    },
  ]);
}

export function getCurrencyInvested(state: State) {
  const ownedTokens = Object.values(state.trading.offchainTokens);
  return ownedTokens.reduce((totalInvested, ownedToken) => {
    return totalInvested.add(ownedToken.currencyInvested);
  }, HP(0));
}

export function computeOffchainHoldingsValue(
  state: State,
  memeStatuses: TradingSearchResult[],
) {
  const memeHoldings = state.trading.offchainTokens;
  const holdingsValue = memeStatuses.reduce((totalValue, memeStatus) => {
    const ownedMeme = memeHoldings[memeStatus.id];
    if (!ownedMeme) {
      return totalValue;
    }

    const coinValue = getGrossCoinAmountForPointSell(
      HP(memeStatus.pointSupply),
      HP(ownedMeme.pointAmount),
    );

    return totalValue.add(coinValue);
  }, HP(0));

  return holdingsValue;
}

export function computeOffchainPortfolioValue(
  state: State,
  memeStatuses: TradingSearchResult[],
) {
  const holdingsValue = computeOffchainHoldingsValue(state, memeStatuses);
  return holdingsValue.add(state.balance);
}

export function getMemeHolding(
  state: State,
  walletAddress: string | undefined,
  memeId: string,
): WalletMemeHoldings | undefined {
  const memeHoldings = getWalletStatus(state, walletAddress).memeHoldings;
  return memeHoldings[memeId];
}

export function computeOnchainPortfolioValue(
  state: State,
  walletAddress: string | undefined,
  memeStatuses: TradingSearchResult[],
  tonToUsdRate: number,
) {
  const memeHoldings = getWalletStatus(state, walletAddress).memeHoldings;

  let holdingsValue = 0;
  memeStatuses.forEach((memeStatus) => {
    const memeHolding = memeHoldings[memeStatus.id];
    if (!memeHolding) {
      // not held in wallet
      return;
    }

    const valuationTon = calculateTotalMintPrice(
      BigInt(memeStatus.tokenSupply),
      BigInt(memeHolding.tokenAmount),
      onchainCurveConfig,
      false,
    );

    const valuationUsd = Number(fromNano(valuationTon)) * tonToUsdRate;
    holdingsValue += valuationUsd;
  });

  return holdingsValue;
}

export async function getOffchainPortfolioValue(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  const memeIds = getMyOffchainMemeIds(state);
  const memeStatuses = await api.asyncGetters.getMemesFromOpenSearch({
    memeIds,
  });

  return computeOffchainPortfolioValue(state, memeStatuses);
}

export function getOffchainTradingVolume(state: State) {
  // const { currencySpent, currencyRecovered } = state.trading.offchain;
  // return HP(currencyRecovered).add(currencySpent);
  return HP(0);
}

export function getOnchainTradingVolume(
  state: State,
  walletAddress: string | undefined,
) {
  // @todo (requires parsing onchain tx data)
  // const { currencySpent, currencyRecovered } = getWalletStatus(
  //   state,
  //   walletAddress,
  // );
  // return HP(currencyRecovered).add(currencySpent);
  return HP(0);
}

export function getOffchainProfitLoss(
  state: State,
  memeStatuses: TradingSearchResult[],
) {
  // const { currencySpent, currencyRecovered } = state.trading.offchain;
  // const portfolioValue = computeOffchainHoldingsValue(state, memeStatuses);
  // return HP(currencyRecovered).add(portfolioValue).minus(currencySpent);
  return HP(0);
}

export function getOnchainHoldingValueNowAndThen(
  memeStatus: TradingSearchResult,
  tokenAmount: string,
  then: number,
) {
  const valuationTonNow = calculateMintTonPrice(
    HP(memeStatus.tokenSupply),
    HP(tokenAmount),
    false,
  ).toNumber();

  const supplyThen = getSupplyBackThen(memeStatus.pastTokenPrices ?? [], then);
  const valuationTonThen = calculateMintTonPrice(
    HP(supplyThen),
    HP(tokenAmount),
    false,
  ).toNumber();

  return {
    valuationTonNow,
    valuationTonThen,
  };
}

function getTotalOnchainHoldingValueNowAndThen(
  state: State,
  walletAddress: string | undefined,
  memeStatuses: TradingSearchResult[],
  tonToUsdRate: number,
  then: number,
) {
  // return the 24h profit/loss
  const memeHoldings = getWalletStatus(state, walletAddress).memeHoldings;

  let valuationUsdNow = 0;
  let valuationUsdThen = 0;
  memeStatuses.forEach((memeStatus) => {
    const memeHolding = memeHoldings[memeStatus.id];
    if (!memeHolding) {
      // not held in wallet
      return;
    }

    const { valuationTonNow, valuationTonThen } =
      getOnchainHoldingValueNowAndThen(
        memeStatus,
        memeHolding.tokenAmount,
        then,
      );

    // console.error('\n\n\n*******')
    // console.error('TOTAL VALUATION!', memeStatus.profile.name)
    // console.error('memeStatus.tokenPrice!', memeStatus.tokenSupply)
    // console.error('memeHolding.tokenAmount!', memeHolding.tokenAmount)
    // console.error('valuationTonNow!', valuationTonNow)
    // console.error('valuationTonThen!', valuationTonThen)

    valuationUsdNow += valuationTonNow * tonToUsdRate;
    valuationUsdThen += valuationTonThen * tonToUsdRate;
  });

  return {
    valuationUsdNow,
    valuationUsdThen,
  };
}

export function getOnchainProfitLoss(
  state: State,
  walletAddress: string | undefined,
  memeStatuses: TradingSearchResult[],
  tonToUsdRate: number,
  then: number,
) {
  // return the 24h profit/loss
  const { valuationUsdNow, valuationUsdThen } =
    getTotalOnchainHoldingValueNowAndThen(
      state,
      walletAddress,
      memeStatuses,
      tonToUsdRate,
      then,
    );
  return valuationUsdNow - valuationUsdThen;
}

export function getRoiSinceThen(
  state: State,
  walletAddress: string | undefined,
  memeStatuses: TradingSearchResult[],
  tonToUsdRate: number,
  then: number,
) {
  // return the 24h roi as %
  const { valuationUsdNow, valuationUsdThen } =
    getTotalOnchainHoldingValueNowAndThen(
      state,
      walletAddress,
      memeStatuses,
      tonToUsdRate,
      then,
    );
  return (100 * (valuationUsdNow - valuationUsdThen)) / valuationUsdThen;
}

export function getPriceSliceBackThen(pricePoints: PriceSlice[], then: number) {
  if (pricePoints.length === 0) {
    return {
      time: 0,
      price: curveConfig.startPrice.toString(),
      supply: '0',
    };
  }

  let priceIdx = pricePoints.findIndex((pricePoint) => {
    return pricePoint.time > then;
  });

  if (priceIdx !== 0) {
    if (priceIdx > 0) {
      // we want the price just before the slice that comes after the "then"
      priceIdx -= 1;
    } else {
      priceIdx = pricePoints.length - 1;
    }
  }

  return pricePoints[priceIdx];
}

export function getSupplyBackThen(pricePoints: PriceSlice[], then: number) {
  return getPriceSliceBackThen(pricePoints, then).supply;
}

export function getPriceBackThen(pricePoints: PriceSlice[], then: number) {
  return HP(getPriceSliceBackThen(pricePoints, then).price);
}

export function getStatsBackThen(
  stats: StatsSlice[],
  then: number,
): StatsSlice {
  if (stats.length === 0) {
    return {
      time: then,
      volume: '0',
      holderCount: 0,
    };
  }

  let statsIdx = stats.findIndex((statsSlice) => {
    return statsSlice.time > then;
  });

  if (statsIdx !== 0) {
    if (statsIdx > 0) {
      // we want the price just before the slice that comes after the "then"
      statsIdx -= 1;
    } else {
      statsIdx = stats.length - 1;
    }
  }

  return stats[statsIdx];
}

export function getValueChange(
  priceNow: HighPrecision,
  priceThen: HighPrecision,
) {
  if (!priceNow || !priceThen || priceNow.eq(0) || priceThen.eq(0)) {
    return 0;
  }
  return priceNow.div(priceThen).minus(1).toNumber();
}

export function getMeanHoldings(state: TradingState) {
  return HP(state.tokenSupply).div(state.holderCount);
}

export function getVariance(state: TradingState) {
  if (state.holderCount <= 0) {
    return HP(0);
  }

  const meanHoldings = getMeanHoldings(state);
  const sumOfHoldingsSquare = HP(state.sumOfHoldingsSquare);
  const variance = sumOfHoldingsSquare
    .div(state.holderCount)
    .minus(meanHoldings.pow(2));

  // Handle potential floating-point errors
  if (variance.lt(0)) {
    return HP(0);
  }

  return variance;
}

// "ceofficient of variation" is a measure of inequality
export function getCoefficientOfVariation(state: TradingState) {
  if (state.holderCount === 1) {
    // everything held by a single person
    return 1;
  }

  if (state.holderCount <= 0) {
    // no one holds the token = fairest distribution
    return 0;
  }

  const meanHoldings = getMeanHoldings(state);
  if (meanHoldings.lte(0)) {
    // note that it should not happen
    // in this case we default to 1 to avoid promoting "broken" tokens
    return 1;
  }

  const holdingsSdToMean = getVariance(state).sqrt();
  const c = holdingsSdToMean.div(meanHoldings).toNumber();
  // normalize, constant chosen arbitrarily to make variation coefficient feel good
  return c / (c + 10);
}

// ====================================================
// ==================== TAP GAME ======================
// ====================================================

function getTMG(state: State) {
  return state.trading.miniGames;
}

function getTMGTapping(state: State) {
  return state.trading.miniGames.tapping;
}

export function getTMGState(state: State) {
  return { ...getTMG(state).state };
}

function getTMGToken(state: State, tokenId: string) {
  return getTMG(state).state[tokenId];
}

function getTTGFarmingSpotsInUse(state: State) {
  const ttg = getTMGState(state);
  return Object.keys(ttg).reduce((res, key) => {
    if (ttg[key]?.miningStart !== undefined) {
      res.push({
        id: key,
        ...ttg[key],
      } as OTTG & { id: string });
    }
    return res;
  }, [] as (OTTG & { id: string })[]);
}

export function getTTGFarmingSpotsAvailable(state: State) {
  return tmgRuleset.farmingLimit - getTTGFarmingSpotsInUse(state).length;
}

export function getTTGIsFarming(state: State, tokenId: string) {
  return getTMGToken(state, tokenId)?.miningStart !== undefined;
}

export function getTTGFarmingTimeLeft(
  state: State,
  tokenId: string,
  now: number,
) {
  if (!getTTGIsFarming(state, tokenId)) {
    return -1;
  }
  const farmStart = getTMGToken(state, tokenId).miningStart ?? 0;
  const timeLeft = getTimeLeft(
    farmStart, // should never be undefined we check above
    tmgRuleset.farmingDuration,
    now,
  );
  return Math.max(0, timeLeft); // clamp negative time to 0
}

export function getTTGFarmingStatus(
  state: State,
  tokenId: string,
  now: number,
): TMGFarmingStatus {
  const timeLeft = getTTGFarmingTimeLeft(state, tokenId, now);
  if (timeLeft < 0) {
    return 'Idle';
  }
  return timeLeft > 0 ? 'Farming' : 'Ready to Claim';
}

export function getTTGFarmingPoints(
  state: State,
  tokenId: string,
  now: number,
) {
  if (!getTTGIsFarming(state, tokenId)) {
    return 0;
  }
  const timeLeft = getTTGFarmingTimeLeft(state, tokenId, now);

  const timeDiff = tmgRuleset.farmingDuration - timeLeft;

  return Math.ceil(timeDiff * tmgRuleset.farmingPointsPerMs);
}

export function getTTGCanFarm(state: State, tokenId: string, now: number) {
  const status = getTTGFarmingStatus(state, tokenId, now);
  return status === 'Idle' && getTTGFarmingSpotsAvailable(state) > 0;
}

export function getTTGFarmProgress(state: State, tokenId: string, now: number) {
  if (!getTTGIsFarming(state, tokenId)) {
    return {
      hoursLeft: 0,
      minutesLeft: 0,
    };
  }
  const timeLeft = getTTGFarmingTimeLeft(state, tokenId, now);
  const hoursLeft = Math.floor(timeLeft / HOUR_IN_MS);
  const timeDiff = timeLeft - hoursLeft * HOUR_IN_MS;
  const minutesLeft = Math.floor(timeDiff / MIN_IN_MS);
  return {
    hoursLeft,
    minutesLeft,
  };
}

export function getTTGKickback(state: State, tokenId: string) {
  const allTimeReferralKickBack =
    getTMGToken(state, tokenId)?.allTimeReferralKickBack ?? '0';
  return HP(allTimeReferralKickBack).toNumber();
}

export function getTTGCanClaim(state: State, tokenId: string, now: number) {
  return getTTGFarmingStatus(state, tokenId, now) === 'Ready to Claim';
}

export function getTMGTappingTickets(tokenId: string, repl?: ReplicantClient) {
  if (!repl) {
    return 0;
  }

  const { state } = repl;

  return state.trading.miniGames.tapping.tickets ?? 0;
}

export function getTMGTappingMaxTickets(state: State) {
  return tmgRuleset.tappingMaxTickets;
}

export function getTMGTappingSessionTaps(state: State) {
  return getTMG(state).tapping.sessionTaps;
}

export function getMyToken(state: State, tokenId: string) {
  return state.trading.offchainTokens[tokenId];
}

export function getTMGFarmingListing(state: State, now: number) {
  const farming = getTTGFarmingSpotsInUse(state);
  return farming.map(({ id }) => ({
    tokenId: id,
    state: getTTGFarmingStatus(state, id, now),
    progress: getTTGFarmProgress(state, id, now),
    points: getTTGFarmingPoints(state, id, now),
  }));
}

export function getTMGFarmingIsShowing(state: State) {
  return true;
}

export function getRandomTickr() {
  const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const length = Math.floor(Math.random() * 3) + 3; // Random length between 3 and 5
  let uniqueWord = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * alphanumeric.length);
    uniqueWord += alphanumeric[randomIndex];
  }
  return uniqueWord;
}

export function getRandomMemeImage() {
  // get random number between 1 and 22
  const randomImageNumber = Math.floor(Math.random() * 3900) + 1;
  // "https://ddw8nafke4m1l.cloudfront.net/user-assets/gemzcoin-bravo/trading/offchainMemeTrading-ILLUM/65a4c1ab29bff9f87cfad360f027adde.png"
  return `https://notgemz.cms.gemz.fun/media/memes/${randomImageNumber}.png`;
}

function getTappedMemeCount(state: State) {
  const memesWithPoints = Object.values(state.trading.miniGames.state);
  const tappedMemeCount = memesWithPoints.reduce(
    (tappedMemeCount, memeWithPoints) => {
      if (memeWithPoints.dailyScore > 0) {
        return tappedMemeCount + 1;
      }

      return tappedMemeCount;
    },
    0,
  );

  return tappedMemeCount;
}

export function hasReachedMemeTapLimit(state: State, tokenId: string) {
  if (state.trading.miniGames.state[tokenId]?.dailyScore === 0) {
    return false;
  }

  return getTappedMemeCount(state) >= tmgRuleset.tappingLimit;
}

export function hasReachedMemeCreationLimit(state: State) {
  const memeHoldings = Object.values(state.trading.offchainTokens);
  const createdMemeCount = memeHoldings.reduce(
    (createdMemeCount, memeHolding) => {
      if (memeHolding.productId !== undefined) {
        return createdMemeCount + 1;
      }

      return createdMemeCount;
    },
    0,
  );

  return createdMemeCount >= tmgRuleset.creationLimit;
}

export function hasReachedMemeHoldingLimit(state: State, tokenId: string) {
  if (state.trading.offchainTokens[tokenId] !== undefined) {
    return false;
  }

  const memeHoldingCount = Object.keys(state.trading.offchainTokens).length;

  return memeHoldingCount >= tmgRuleset.holdingLimit;
}

export function getTmgTicketTimestamp(state: State) {
  return state.trading.miniGames.tapping.ticketTimestamp;
}

/**
  Points received = getPointAmountForCoinBuy(supply, Session Score / Price Modifier)
  Price Modifier = 1+(quantity*exp(-decay * share of points earned to date))
 */
export function scoreToPoints(
  currentSupply: HighPrecision,
  pointsAccumulated: string,
  pointsDistributed: string,
  score: number,
) {
  const shareOfDistribution = HP(pointsDistributed).eq(0)
    ? 1
    : HP(pointsAccumulated).div(pointsDistributed).toNumber();

  // @WARNING: DO NOT CHANGE THOSE CONSTANTS WITHOUT VERSIONING MESSAGE!
  const quantity = 0.25;
  const decay = 0.5;

  const priceModifier = 1 + quantity * Math.exp(-decay * shareOfDistribution);
  const equivalentCurrencyAmount = HP(score).div(priceModifier);
  const points = getPointAmountForCoinBuy(
    currentSupply,
    equivalentCurrencyAmount,
    1,
  );

  return {
    points,
    equivalentCurrencyAmount,
  };
}

export function getActualTime(
  api: ReplicantAsyncActionAPI<any> | ReplicantEventHandlerAPI<any>,
) {
  return api.date.now() - api.getClockOffset();
}

export function getFtueShareGateReward(_state: State) {
  return 25_000_000;
}

export function isMemeGraduated(state: TradingState) {
  return (state.dexListingTime ?? 0) > 0;
}

export function isValidTxType(txType: TxType) {
  return (
    txType === 'deploy' ||
    txType === 'buy' ||
    txType === 'sell' ||
    txType === 'dexBuy' ||
    txType === 'dexSell' ||
    txType === 'dailyClaim' ||
    txType === 'graduationClaim'
  );
}

export async function findWalletUser(
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
  userProfile: OnchainUserProfile,
  walletAddress: string,
): Promise<OnchainUserProfile & { playerFound: boolean }> {
  const userOwnsTheWallet = userProfile.walletAddress === walletAddress;
  if (userOwnsTheWallet) {
    return {
      walletAddress,
      userId: userProfile.userId,
      userName: userProfile.userName,
      userImage: userProfile.userImage,
      playerFound: true,
    };
  }

  const players = (
    await api.searchPlayers({
      where: {
        wallets: {
          address: {
            isAnyOf: [walletAddress],
          },
        },
      },
      // expectation at time of writing:
      // - in > 95% of cases this will return 1 user
      // - in < 5% of cases this will return 0 users
      // - in < 1% of cases this will return multiple users, 2 being the most likely
      limit: 10,
      // to increase chance of getting relevant players we sort them by last session time
      // note that is does not guarantee that player who used the wallet last will be first on the list
      sort: [{ field: 'lastSession', order: 'desc' }],
    })
  ).results;

  // figure out who used the wallet last
  if (!players || players.length === 0) {
    return {
      userId: '',
      walletAddress,
      playerFound: false,
    };
  }

  // figure out who used the wallet last
  let lastConnectionTime = 0;
  let lastConnectedPlayer = players[0];
  players.forEach((player) => {
    const wallet = player.wallets?.find(
      (wallet) => wallet.address === walletAddress,
    );
    if (!wallet) {
      // should not happen, players were filtered to have a wallet matching the address
      return;
    }

    if (wallet.lastConnectionTime > lastConnectionTime) {
      lastConnectionTime = wallet.lastConnectionTime;
      lastConnectedPlayer = player;
    }
  });

  return {
    walletAddress,
    userId: lastConnectedPlayer.id,
    userName: lastConnectedPlayer.name,
    userImage: lastConnectedPlayer.photo,
    playerFound: true,
  };
}

export const isDailyTokenBeingClaimed = (
  now: number,
  offchainHolding?: OwnedOffchainMeme,
) => {
  if (!offchainHolding) {
    return false;
  }

  const tokenClaimOpStartTime = offchainHolding.tokenClaimOpStartTime;
  if (tokenClaimOpStartTime) {
    const timeSinceClaimed = now - tokenClaimOpStartTime;
    if (timeSinceClaimed > 15 * MIN_IN_MS) {
      // let the player retry, something might be wrong with the watcher's logic
      return false;
    } else {
      return true;
    }
  }

  return false;
};

export const canClaimDailyTokens = (
  now: number,
  offchainHolding?: OwnedOffchainMeme,
) => {
  if (!offchainHolding) {
    return false;
  }

  if (isDailyTokenBeingClaimed(now, offchainHolding)) {
    return false;
  }

  const tokenAmount = offchainHolding.claimableTokens;
  if (!tokenAmount) {
    return false;
  }

  return HP(tokenAmount).gt(0);
};

export const getGraduationClaimAmount = (
  isGraduated: boolean,
  state: State,
  memeId: string,
  walletAddress: string | undefined,
) => {
  if (!isGraduated) {
    return;
  }

  if (!walletAddress) {
    return;
  }

  const wallet = state.trading.onchain.wallets[walletAddress];
  if (!wallet) {
    return;
  }

  const memeHolding = wallet.memeHoldings[memeId];
  if (!memeHolding) {
    return;
  }

  if (memeHolding.graduationClaimTime) {
    // already claiming
    return;
  }

  return memeHolding.jettonTokenAmount;
};

export function getTxVerificationRetryDelay(state: State) {
  let retryDelay = Infinity;

  const wallets = state.trading.onchain.wallets;
  for (let walletAddress in wallets) {
    const unconfirmedTxs = wallets[walletAddress].unconfirmedTxs;
    unconfirmedTxs.forEach((unconfirmedTx) => {
      retryDelay = Math.min(retryDelay, unconfirmedTx.verifDelayMs);
    });
  }

  return retryDelay;
}

export function getPendingTxs(state: State, memeId?: string) {
  const walletAddress = state.lastConnectedWallet;
  if (!walletAddress) {
    return [];
  }

  const playerWallet = state.trading.onchain.wallets[walletAddress];
  if (!playerWallet) {
    return [];
  }

  const pendingTxs = playerWallet.unconfirmedTxs;

  // uncomment to test pending txs
  // const pendingTxs: UnconfirmedTx[] = [{
  //   memeId: '8098862064648777',
  //   txHash: '2832984327948',
  //   txType: 'buy',
  //   createdAt: Date.now() - 21324,
  //   verifDelayMs: 1000,
  // }, {
  //   memeId: '8098862064648777',
  //   txHash: '23940239480',
  //   txType: 'sell',
  //   createdAt: Date.now() - 599838,
  //   verifDelayMs: 1000,
  // }, {
  //   memeId: '8098862064648777',
  //   txHash: '745634535223',
  //   txType: 'dailyClaim',
  //   createdAt: Date.now() - 18723782,
  //   verifDelayMs: 1000,
  // }];

  if (memeId) {
    return pendingTxs.filter((pendingTx) => pendingTx.memeId === memeId);
  }

  return pendingTxs;
}

export function getDayTime(timestamp: number, nextDays: number = 0) {
  // round down to the day's midnight in GMT
  const adjustedTimestamp = timestamp + nextDays * CLAIM_DAY_DURATION;
  const dayMidnight =
    adjustedTimestamp - (adjustedTimestamp % CLAIM_DAY_DURATION);
  return dayMidnight;
}
