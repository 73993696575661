import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ProgressBarCircle.scss';

interface ProgresBarProps {
  progress: number;
  height?: number;
  color?: string;
  completedIcon?: string;
}

export const ProgressBarCircle = ({
  progress,
  height,
  color,
  completedIcon,
}: ProgresBarProps) => {
  const { t } = useTranslation();

  const isCompleted = progress >= 100;
  if (isCompleted) progress = 100;

  const sz = 32;

  height = height || 10;
  color = color || 'white';

  return isCompleted && completedIcon ? (
    <img src={completedIcon} width={sz} height={sz} />
  ) : (
    <svg
      width={sz}
      height={sz}
      viewBox={`0 0 ${sz} ${sz}`}
      className="circular-progress-bar"
      style={
        { '--size': `${sz}px`, '--progress': progress } as React.CSSProperties
      }
    >
      <circle className="bg" />
      {progress > 0 && <circle className="fg" />}
    </svg>
  );
};

// ===================================================================================
