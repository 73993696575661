import { t } from 'i18next';
import { app } from '../../../../data/Controllers/AppController';
import { MemeFilters } from '../../../../data/Controllers/Memes/types';
import { FeedItemFilterButton } from './FeedItemFilterBtn';
import { getTMGFarmingIsShowing } from '../../../../replicant/features/tradingMeme/tradingMeme.getters';

interface TiktokTabsProps {
  selectedFilter: MemeFilters;
  onTapFilter: (cat: MemeFilters) => Promise<void>;
}

export const TiktokFilterPicker = ({
  selectedFilter,
  onTapFilter,
}: TiktokTabsProps) => {
  const isShowingFarming = getTMGFarmingIsShowing(app.state);
  // profile tabs mode (Created / Owned)
  if (app.memes.myMemes.getIsMyFilter(selectedFilter)) {
    return (
      <div className="feed-ui-top-categories">
        <FeedItemFilterButton
          filter={'Created'}
          selectedFilter={selectedFilter}
          onTap={onTapFilter}
          label={t('profile_tab_name_created')}
        />
        <FeedItemFilterButton
          filter={'Owned'}
          selectedFilter={selectedFilter}
          onTap={onTapFilter}
          label={t('profile_tab_name_wallet')}
        />
        {
          /*app.profile.current?.isSelf && */ isShowingFarming && (
            <FeedItemFilterButton
              filter={'Farming'}
              selectedFilter={selectedFilter}
              onTap={onTapFilter}
              label={t('profile_tab_name_points')}
            />
          )
        }
      </div>
    );
  }

  // root tabs mode (New / Hot / Top)
  return (
    <div className="feed-ui-top-categories">
      <FeedItemFilterButton
        filter={'New'}
        selectedFilter={selectedFilter}
        onTap={onTapFilter}
        label={t('main_ui_topnav_new')}
      />
      <FeedItemFilterButton
        filter={'Hot'}
        selectedFilter={selectedFilter}
        onTap={onTapFilter}
        label={t('main_ui_topnav_hot')}
      />
      <FeedItemFilterButton
        filter={'Top'}
        selectedFilter={selectedFilter}
        onTap={onTapFilter}
        label={t('main_ui_topnav_top')}
      />
    </div>
  );
};
