import { TradingTokenSearchField } from '../../../replicant/features/tradingMeme/tradingMeme.asyncGetters';
import { REPLICANT_UPLOAD_LENGTH_LIMIT } from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';

const transformUrl = (value: string) => {
  // First remove all whitespace from the entire string
  const noSpaces = value.replace(/\s+/g, '');
  if (!noSpaces) return '';

  // Don't transform if user is typing/deleting the protocol (case-insensitive)
  const lowerNoSpaces = noSpaces.toLowerCase();
  if (
    lowerNoSpaces === 'h' ||
    lowerNoSpaces === 'ht' ||
    lowerNoSpaces === 'htt' ||
    lowerNoSpaces === 'http' ||
    lowerNoSpaces === 'https' ||
    lowerNoSpaces === 'https:' ||
    lowerNoSpaces === 'https:/' ||
    lowerNoSpaces === 'https://'
  ) {
    return noSpaces; // Return original input to preserve case
  }

  if (!/^https?:\/\//i.test(noSpaces)) {
    return `https://${noSpaces}`;
  }
  return noSpaces;
};

export const tokenListFilters = {
  searchable: {
    Hot: 'volume',
    Top: 'top',
    New: 'availableAt',
  } satisfies Record<string, TradingTokenSearchField>,
  user: {
    Created: '',
    Owned: '',
    Farming: '',
  },
};
export type SearchFilters = keyof typeof tokenListFilters.searchable;
export type UserMemeFilters = keyof typeof tokenListFilters.user;
export type MemeFilters = SearchFilters | UserMemeFilters;

export type GetMemeStrategy =
  | 'cache'
  | 'fetch'
  | 'forceFetch'
  | 'fetchAndUpdate'
  | 'cacheOnly';

// form config for token creation page
export const newTokenFormConfig = {
  name: {
    title: 'create_token_name_title',
    placeholder: 'create_token_name_placeholder',
    validate: (str: string) => str.length > 0,
    required: true,
    maxLength: 32,
  },
  ticker: {
    title: 'create_token_ticker_title',
    placeholder: 'create_token_ticker_placeholder',
    transformInput: (value: string) =>
      value.replace(/\s+/g, '').toUpperCase().substring(0, 8),
    validate: (str: string) =>
      str.length >= 3 && str.length <= 8 && /^[a-zA-Z0-9]+$/.test(str),
    required: true,
    maxLength: 8,
  },
  description: {
    title: 'create_token_description_title',
    placeholder: 'create_token_description_placeholder',
    validate: (str: string) => str.length > 0,
    required: true,
  },
  image: {
    title: 'create_token_image_title',
    placeholder: 'create_token_image_placeholder',
    required: true,
    // dataOnly: true,
    validate: (str: string) => {
      const file = new File([str], 'filename');
      // @todo: is it possible to increase this limit?
      return file.size <= REPLICANT_UPLOAD_LENGTH_LIMIT;
    },
  },

  // links
  twitterLink: {
    title: 'create_token_twitterLink_title',
    placeholder: 'create_token_twitterLink_placeholder',
    example: 'https://x.com',
    transformInput: transformUrl,
    validate: (str: string) =>
      str.length === 0 ||
      // str.toLowerCase().startsWith('x.com') ||
      // str.toLowerCase().startsWith('twitter.com') ||
      str.toLowerCase().startsWith('https://x.com') ||
      str.toLowerCase().startsWith('https://twitter.com'),
    required: false,
  },
  telegramChannelLink: {
    title: 'create_token_telegramChannelLink_title',
    placeholder: 'create_token_telegramChannelLink_placeholder',
    example: 'https://t.me',
    transformInput: transformUrl,
    validate: (str: string) =>
      str.length === 0 ||
      // str.toLowerCase().startsWith('t.me') ||
      // str.toLowerCase().startsWith('telegram.me') ||
      str.toLowerCase().startsWith('https://t.me') ||
      str.toLowerCase().startsWith('https://telegram.me'),
    required: false,
  },
  telegramChatLink: {
    title: 'create_token_telegramChatLink_title',
    placeholder: 'create_token_telegramChatLink_placeholder',
    example: 'https://t.me/joinchat',
    transformInput: transformUrl,
    validate: (str: string) =>
      str.length === 0 ||
      // str.toLowerCase().startsWith('t.me') ||
      // str.toLowerCase().startsWith('telegram.me') ||
      str.toLowerCase().startsWith('https://t.me') ||
      str.toLowerCase().startsWith('https://telegram.me'),
    required: false,
  },
  websiteLink: {
    title: 'create_token_websiteLink_title',
    placeholder: 'create_token_websiteLink_placeholder',
    example: 'https://website.fun',
    transformInput: transformUrl,
    validate: (str: string) => {
      if (str.length === 0) return true;
      try {
        const url = new URL(str);
        return (
          // Must be https://
          url.protocol === 'https:' &&
          // Must have a domain with at least one dot and no spaces
          /^[^\s]+\.[^\s]+$/.test(url.hostname) &&
          // Full URL shouldn't contain spaces
          !/\s/.test(str)
        );
      } catch {
        return false;
      }
    },
    required: false,
  },
} as const;

// form config for edit links page
export const updateTokenFormConfig = {
  twitterLink: newTokenFormConfig.twitterLink,
  telegramChannelLink: newTokenFormConfig.telegramChannelLink,
  telegramChatLink: newTokenFormConfig.telegramChatLink,
  websiteLink: newTokenFormConfig.websiteLink,
} as const;
