import { createEventHandlers } from '@play-co/replicant';
import { asyncGetters } from './asyncGetters';
import { computedProperties } from './computedProperties';
import messages from './messages';
import { ruleset, tests } from './ruleset';
import scheduledActions from './scheduledActions';
import stateSchema from './schema';
import { sharedStates } from './sharedStates';
// import {
//   getEnergyLimit,
//   getEnergyPerSecond,
//   getTaps,
//   getTimeToFullRechargeInSecs,
// } from './features/game/game.getters';
// import { autoBotConfig } from './features/game/ruleset/autobot';
// import { syncTeamScore } from './features/teams/teams.modifiers';
import { DAY_IN_MS, HOUR_IN_MS } from './utils/time';
// import { FREE_ENERGY_RECHARGE_COOLDOWN } from './features/game/ruleset/buffs';
// import { rocketmanConfig } from './features/game/ruleset/boosters';
// import { DAILY_REWARDS } from './features/game/ruleset/dailyRewards';
// import { getMiningRevenues } from './features/powerups/getters';
// import { earningRewards } from './features/game/ruleset/earnings';
import { scheduleBotNotification } from './features/chatbot/chatbot.modifiers';
import { runTxConfirmationAndReschedule } from './features/tradingMeme/tradingMeme.modifiers';
// import { handleFreeTokenMemeGift } from './features/game/game.modifiers';

export const eventHandlers = createEventHandlers(stateSchema, {
  asyncGetters,
  computedProperties,
  messages,
  ruleset,
  scheduledActions,
  sharedStates,
})({
  async onSessionEnd(state, event, api) {
    const now = api.date.now();
    // const sessionDuration = now - state.last_session_time;

    // updating balance this way was causing the exploit where players were leveling up powerups but their balance was not updateds
    // when offline the state instance here does not have the updated balance
    // claimPowerUpReward(state, now);
    // const rewards = getMiningRevenues(state, now, state.powerUps.last_claimed);
    // if (rewards > 0) {
    //   api.postMessage.claimRewards(state.id, { rewards });
    //   state.powerUps.last_claimed = now;
    // }

    // // determine if player might be exploiting the game
    // const sessionDurationInSecs = sessionDuration / 1000;
    // const maxBuffRefills = Math.ceil(
    //   sessionDuration / FREE_ENERGY_RECHARGE_COOLDOWN,
    // );
    // const tapMultiplier = getTaps(state);
    // const tapCoeff = 1;
    // const maxRocketmanCount = Math.min(
    //   Math.ceil(sessionDurationInSecs / rocketmanConfig.cooldown),
    //   rocketmanConfig.limitPerDay,
    // );
    // const maxTapsPerSec = 50;
    // const maxRocketmanDuration = Math.min(
    //   (maxRocketmanCount * rocketmanConfig.duration) / 1000,
    //   sessionDurationInSecs,
    // );
    // const maxRocketmanScore =
    //   maxRocketmanDuration *
    //   rocketmanConfig.multiplier *
    //   tapMultiplier *
    //   maxTapsPerSec;
    // const maxTaps = sessionDurationInSecs * maxTapsPerSec;
    // const maxEnergyUse = Math.ceil(
    //   getEnergyLimit(state) * (1 + maxBuffRefills) +
    //     sessionDurationInSecs * getEnergyPerSecond(state),
    // );
    // const maxTapScore = Math.round(
    //   Math.min(maxTaps * tapMultiplier, maxEnergyUse * tapCoeff) +
    //     maxRocketmanScore,
    // );

    // const previousSessionEndTime = Math.max(
    //   1720026661058,
    //   state.last_session_end_time,
    // );
    // const timeBetweenSessions =
    //   state.last_session_time - previousSessionEndTime;

    // const maxAutoBotScore =
    //   (Math.min(autoBotConfig.maxRewardDuration, timeBetweenSessions) / 1000) *
    //   tapMultiplier;
    // const maxMiningScore = getMiningRevenues(
    //   state,
    //   now,
    //   previousSessionEndTime,
    // );
    // let maxRewardScore =
    //   DAILY_REWARDS[state.consecutive_days] +
    //   Object.values(earningRewards).reduce(
    //     (total, reward) => total + reward,
    //     0,
    //   );

    // // remove rewards that could not have been claimed
    // if (state.friendCount < 2) {
    //   maxRewardScore -= earningRewards.inviteFriends2;
    // }
    // if (state.friendCount < 5) {
    //   maxRewardScore -= earningRewards.inviteFriends5;
    // }
    // if (state.friendCount < 10) {
    //   maxRewardScore -= earningRewards.inviteFriends10;
    // }
    // if (state.friendCount < 100) {
    //   maxRewardScore -= earningRewards.inviteFriends100;
    // }
    // if (state.wallet.length === 0) {
    //   maxRewardScore -= earningRewards.walletConnect;
    // }
    // if (!state.followOnYoutubeTimestamp) {
    //   maxRewardScore -= earningRewards.followOnYoutube;
    // }
    // if (!state.earnings.joinAnnouncement) {
    //   maxRewardScore -= earningRewards.joinAnnouncement;
    // }

    // const maxAccumulatedScore =
    //   maxTapScore + maxAutoBotScore + maxMiningScore + maxRewardScore;
    // if (maxAccumulatedScore < state.unsynced_team_score) {
    //   api.sendAnalyticsEvents([
    //     {
    //       eventType: 'CheatAlert',
    //       eventProperties: {
    //         unsyncedScore: state.unsynced_team_score,
    //         maxAccumulatedScore,
    //         maxTapScore,
    //         maxAutoBotScore,
    //         maxMiningScore,
    //         maxRewardScore,
    //         feature: 'session',
    //         $subfeature: 'session_end',
    //       },
    //     },
    //   ]);
    // }

    // // Post score to team.
    // syncTeamScore(state, api, maxAccumulatedScore);

    // api.sendAnalyticsEvents([
    //   {
    //     eventType: 'Tap',
    //     eventProperties: {
    //       taps: state.session_taps,
    //       sessionDuration: Math.round(sessionDuration / 100) / 10,
    //       feature: 'session',
    //       $subfeature: 'session_end',
    //     },
    //   },
    // ]);

    // let bucketId = state.ruleset.abTests[tests.TEST_TIKTOK_ONLY]?.bucketId;
    // if (bucketId !== 'tiktok_only_experience') {
    //   if (state.energy !== getEnergyLimit(state)) {
    //     const energyRechargedDelay = Math.max(
    //       HOUR_IN_MS,
    //       getTimeToFullRechargeInSecs(state),
    //     );

    //     state.notificationBacklog.energyRecharged = {
    //       triggerTime: now + energyRechargedDelay,
    //     };
    //   }
    // }

    state.notificationBacklog.reengagement = {
      triggerTime: now + DAY_IN_MS,
      payload: {
        iteration: 1,
      },
    };

    scheduleBotNotification(state, api);

    // trigger verification of unconfirmed transactions + scheduled new verification if necessary
    runTxConfirmationAndReschedule(state, api);

    // await handleFreeTokenMemeGift(state, api);

    state.last_session_end_time = now;
  },
});
