import { ModalLabels } from '../../../replicant/ruleset';
import { AppController } from '../AppController';

export enum LabelManagerKeys {
  UILabels = 'UILabels',
}

export class LabelManager {
  private currentVersion: number = 0;

  private currentVersionLabel?: string;

  constructor(private app: AppController, private key: LabelManagerKeys) {}

  public init = async (labels: ModalLabels[]) => {
    this.setVersion();
    console.log(
      `LabelManager (${this.key}): init with version '${this.currentVersion}'.`,
    );

    this.addLabels(this.getNewLabels(labels));

    console.log(
      `LabelManager (${this.key}): init complete.`,
      this.app.state.labels,
    );
  };

  private getNewLabels = (labels: ModalLabels[]) => {
    const newLabels: ModalLabels[] = [];
    for (let i = this.currentVersion; i < labels.length; i++) {
      newLabels.push(labels[i]);
    }
    return newLabels;
  };

  private addLabels = (labels: ModalLabels[]) => {
    if (labels.length) {
      this.bumpVersion();
      this.app.invoke.addLabels({ labels });
    }
  };

  private bumpVersion = () => {
    if (this.currentVersionLabel) {
      this.app.invoke.removeLabels({ labels: [this.currentVersionLabel] });
    }
    this.currentVersion += 1;
    this.app.invoke.addLabels({ labels: [this.formatVersion()] });
  };

  private formatVersion = () => {
    return `${this.key}:${this.currentVersion}`;
  };

  private getVersionLabel = () => {
    return this.app.state.labels.find((label) => label.includes(this.key));
  };

  private setVersion = () => {
    const labelVersion = this.getVersionLabel();
    this.currentVersionLabel = labelVersion;

    const [_, version] = (labelVersion ?? '').split(':');

    const versionNumber = Number(version);

    if (!labelVersion || Number.isNaN(versionNumber)) {
      return;
    }

    this.currentVersion = versionNumber;
  };

  public shouldShow = (label: ModalLabels) => {
    return this.app.state.labels.includes(label);
  };

  public showed = (label: ModalLabels) => {
    this.app.invoke.removeLabels({ labels: [label] });
  };
}
