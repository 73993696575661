import './MemeImage.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

interface MemeImageProps {
  src: string;
  size: number;
  badgeSrc?: string;
}

export const MemeImage = ({ src, size, badgeSrc }: MemeImageProps) => {
  const { t } = useTranslation();

  const imgRef = useRef<any>();
  const [verticalAspectRatio, setVerticalAspectRatio] = useState(false);

  useEffect(() => {
    if (src) {
      if (imgRef.current) {
        const isVertical =
          imgRef.current.naturalWidth < imgRef.current.naturalHeight;
        setVerticalAspectRatio(isVertical);
      }
    }
  }, [src]);

  return (
    <div
      className="meme-image-circle"
      style={{ width: `${size}px`, height: `${size}px`, position: 'relative' }}
    >
      <div className="meme-image">
        <img
          ref={imgRef}
          src={src || assets.telegram_star}
          style={{
            width: verticalAspectRatio ? '100%' : 'unset',
            height: verticalAspectRatio ? 'unset' : '100%',
          }}
        />
      </div>
      {badgeSrc && (
        <div className="badge">
          <img src={badgeSrc} alt="Badge" />
        </div>
      )}
    </div>
  );
};

// ===================================================================================
