import { createAsyncGetters } from '../../createAsyncGetters';

export const playerAsyncGetters = createAsyncGetters({
  getPlayerComputedProps: async ({ userId }: { userId: string }, api) => {
    const players = (
      await api.searchPlayers({
        where: {
          id: {
            isOneOf: [userId],
          },
        },
        limit: 1,
      })
    ).results;

    const player = players && players[0];
    if (!player) {
      return;
    }

    return player;
  },
  // @todo: remove (POST SEASON 2 MIGRATION)
  // getPlayerTeamId: async ({ userId }: { userId: string }, api) => {
  //   const players = (
  //     await api.searchPlayers({
  //       where: {
  //         id: {
  //           isOneOf: [userId],
  //         },
  //       },
  //       limit: 1,
  //     })
  //   ).results;

  //   const player = players && players[0];
  //   if (!player) {
  //     return;
  //   }

  //   return player.teamId;
  // },
  getFriendUsernameById: async ({ userId }: { userId: string }, api) => {
    const players = (
      await api.searchPlayers({
        where: {
          id: {
            isOneOf: [userId],
          },
        },
        limit: 1,
      })
    ).results;

    const player = players && players[0];
    if (!player) {
      return;
    }

    return player.name;
  },
  getUserState: async ({ userId }: { userId: string }, api) => {
    const fs = await api.fetchStates([userId]);
    return fs[userId]?.state;
  },
});
