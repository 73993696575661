import { AppController } from '../Controllers/AppController';
import { TutorialModule } from './modules/TutorialModule';

export enum Tutorials {
  InitialTutorial = 'InitialTutorial',
  // @todo: remove (POST SEASON 2 MIGRATION)
  // EnergyTutorial = 'EnergyTutorial',
  SlideshowTutorial = 'SlideshowTutorial',
}

export interface TutorialCfg {
  id: Tutorials;
  steps: TutorialModule[];
  onComplete: (app: AppController) => void;
  lastStep: number;
  repeateable?: boolean;
  transparentBg?: boolean;
}
