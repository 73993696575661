import { createSharedStateSchema, SB, WithMeta } from '@play-co/replicant';

export const contractTypeSchema = SB.tuple(['dex', 'jetton']);
export const txType = SB.tuple(['create', 'buy', 'sell']);

export const tx = SB.object({
  type: txType,
  hash: SB.string(),
});

export const txOutcomeSchema = SB.tuple(['success', 'failure']);
export type TxOutcome = SB.ExtractType<typeof txOutcomeSchema>;

export type ContractType = SB.ExtractType<typeof contractTypeSchema>;

export const onchainTxsSharedStateSchema = createSharedStateSchema({
  global: {
    schema: SB.object({
      memeId: SB.string(),
      // txType,
      contractType: contractTypeSchema,
      page: SB.number(),
      successfulTxHashes: SB.array(SB.string()),
      failedTxHashes: SB.array(SB.string()),
    }),
  },
});

export type OnchainTxsState = SB.ExtractType<
  typeof onchainTxsSharedStateSchema.global.schema
>;

export type MutableOnchainTxsState = WithMeta<OnchainTxsState, {}>;
