import { ErrorCode } from '../../replicant/response';
import {
  SendTransactionRequest,
  SendTransactionResponse,
  TonConnectError,
} from '@tonconnect/sdk';
import { TonProvider } from './TonProvider';
import { BusinessController } from '../Controllers/BusinessController';
import { ConnectedWallet, TonConnectUI } from '@tonconnect/ui';
import gameConfig, { Env } from '../../replicant/features/game/game.config';
import { analytics } from '@play-co/gcinstant';
import { BuyTokenInput, CreateContractInput, SwapType } from './types';
import { cai, waitFor } from '../utils';
import { env, qpConfig } from '../config';
import { Address, Cell, fromNano, SenderArguments, toNano } from '@ton/core';
import { withRetry } from './utils';
import { HighPrecision, HP } from '../../replicant/lib/HighPrecision';
import { DEX, pTON } from '@ston-fi/sdk';
import { TxType } from '../../replicant/features/tradingMeme/types';
import { calculateTonToDexTokens, calculateDexTokensToTon } from './utils';
import {
  ContractMetadata,
  getContractMetadataFromMeme,
  getEnvCode,
  PickMetadaFromMeme,
} from '../../replicant/features/tradingMeme/tradingMeme.getters.ton';
import { getTxVerificationRetryDelay } from '../../replicant/features/tradingMeme/tradingMeme.getters';
import { ONE_DAY_MS } from '../../replicant/features/game/ruleset/contract';
import { timeLog } from '../utils/timeLog';

if (env !== 'prod') {
  // this is a monkey patch for the Ton connect widget that tries to redefine a custom element when the page auto-refreshes
  const originalDefine = window.customElements.define;

  window.customElements.define = function (name, constructor, options) {
    if (!window.customElements.get(name)) {
      return originalDefine.call(this, name, constructor, options);
    }

    // Optionally, log something here if you want to know it's skipping:
    console.warn(`Skipping re-definition of custom element: ${name}`);
    return;
  };
}

const POOL_INTERVAL = 500;
const WAIT_WALLET_ADDRESS_TIMEOUT = 4; //2s
const PROXY_TON_ADDRESS = 'EQBnGWMCf3-FZZq1W4IWcWiGAc3PHuZ0_H-7sad2oY00o83S';
const DEMO_DEX_TOKEN_ADDRESS =
  'EQApfjfy0Uuy-ixrFMrVWSEL4WMOsGDcTsha9i5lZUR5wMS2';
const STONFI_ROUTER_ADDRESS = Address.parse(
  'EQDx--jUU9PUtHltPYZX7wdzIi0SPY3KZ8nvOs0iZvQJd6Ql',
);
const STONFI_PTON_ADDRESS = Address.parse(
  'EQBnGWMCf3-FZZq1W4IWcWiGAc3PHuZ0_H-7sad2oY00o83S',
);

export enum TonEvents {
  OnBalanceUpdate = 'OnBalanceUpdate',
  OnWalletConnectUpdate = 'OnWalletConnectUpdate',
  OnTransactionConfirmed = 'OnTransactionConfirmed',
}

// @TODO: Move to TonProvider
function extractTxHash(transactionResponse: SendTransactionResponse) {
  const cell = Cell.fromBase64(transactionResponse.boc);
  const buffer = cell.hash();
  const txHash = buffer.toString('hex');

  return txHash;
}

export class TonController extends BusinessController<TonEvents> {
  public static TestUIEnabled = qpConfig.testTON;

  public tonConnectUI!: TonConnectUI;

  private provider = new TonProvider();

  private confirmationWaiter?: Promise<any>;

  private txVerificationTimeout: NodeJS.Timeout | null = null;

  public get walletAddress() {
    return this.provider.walletAddress;
  }

  private _balance?: string;

  public get balance() {
    return this._balance ?? '0';
  }

  public get connected() {
    return this.tonConnectUI?.connected ?? false;
  }

  public normalizeAddress(walletAddress?: string) {
    if (!walletAddress) {
      return '';
    }

    return Address.parse(walletAddress).toString({
      urlSafe: true,
      bounceable: false,
    });
  }

  init = async () => {
    const stage = process.env.REACT_APP_STAGE || 'dev';
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: `${gameConfig.playUrl}/tonconnect/${stage}/tonconnect-manifest.json`,
    });

    this.tonConnectUI.onStatusChange(
      async (status: ConnectedWallet | null) => {
        await this.app.waitForReplicant();

        if (status) {
          this.app.replicant.invoke.setWalletInfo({
            app_name: status.appName,
            address: status.account.address,
          });

          analytics.setUserProperties({
            walletAddress: status.account.address,
          });

          // console.error('*** price at supply:', getOnchainCurvePrice(HP(1_000_000)).toString())

          this.provider.setWalletAddress(status.account.address);
          this.getBalance();

          this.scheduleTxConfirmation();
        } else {
          this._balance = undefined;
          this.provider.setWalletAddress(undefined);
        }
        this.sendEvents(TonEvents.OnWalletConnectUpdate);
      },
      (error: TonConnectError) => {
        // console.error(`Failed to connect Ton Wallet: ${error.message}`);
        analytics.pushError('WalletConnectError', {
          name: error.name,
          message: error.message,
        });
      },
    );

    this.app.maybeFixBrokenWalletConnectQuest();
  };

  private onError = (msg: any, code: ErrorCode) => {
    console.error(msg);
    return new Error(code);
  };

  public getBalance = async () => {
    const rawBalance = await this.provider.getBalance();
    const prevBalance = this._balance;
    this._balance = fromNano(rawBalance);
    if (this._balance !== prevBalance) {
      this.sendEvents(TonEvents.OnBalanceUpdate);
    }
    return this._balance;
  };

  private waitForWalletAddress = async (retries = 0): Promise<void> => {
    if (retries > WAIT_WALLET_ADDRESS_TIMEOUT) {
      return Promise.reject();
    }
    await waitFor(POOL_INTERVAL);
    if (this.walletAddress) {
      return Promise.resolve();
    }
    return this.waitForWalletAddress(++retries);
  };

  connect = async () => {
    if (!this.tonConnectUI.connected || !this.provider.walletAddress) {
      this.provider.setWalletAddress(this.tonConnectUI.account?.address);
      await this.tonConnectUI.openModal();
      cai('connect', {});
      return this.waitForWalletAddress();
    }
    return Promise.resolve();
  };

  private sendTransaction = async (txRequest: SendTransactionRequest) => {
    try {
      const transactionResponse = await this.tonConnectUI.sendTransaction(
        txRequest,
      );
      const txHash = extractTxHash(transactionResponse);
      console.log('Transaction Hash:', txHash);

      // @TODO: Here is a good place to start an async check for transaction complete
      return txHash;
    } catch (e) {
      throw e;
    }
  };

  disconnect = async () => {
    await this.tonConnectUI.disconnect();
  };

  createContract = async (input: CreateContractInput) => {
    console.log('createContract', { input });

    await this.connect();

    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    const { meme, tonAmount, jettonContractAddress } = input;

    const tokenAmount = this.provider.getListingBuyTokens(tonAmount);

    const metadata = getContractMetadataFromMeme(meme);

    const submit = await this._buyToken({
      ownerAddress: this.walletAddress,
      tokenAmount: HP(tokenAmount.toString()),
      metadata,
      firstBuy: true,
      tonAmount,
      jettonContractAddress,
    });

    return submit();
  };

  private confirmBuyToken = (txHash: string) => {
    cai('confirmBuyToken', { txHash });
    return () =>
      this.provider.getTxWithHash(txHash).then((res) => {
        cai('confirmBuyToken', { res });
        if (!res.success) {
          throw new Error(`Tx failed`);
        }
        // Observed the buy action has 6 transactions but in theory after the first 3 we can consider it success
        if (res.actions < 3) {
          return undefined; // retry
        }
        return 'success';
      });
  };

  private _buyToken = async ({
    ownerAddress,
    tokenAmount,
    metadata,
    firstBuy,
    tonAmount,
    jettonContractAddress,
  }: {
    ownerAddress: string;
    tokenAmount: HighPrecision;
    metadata: ContractMetadata;
    firstBuy: boolean;
    tonAmount: string;
    jettonContractAddress?: string;
  }) => {
    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    const { memeId } = metadata;
    const buyTime = this.app.now();

    timeLog.start('_buyToken:getBuyTokenTx');
    const buyTokenTx = await this.provider.getBuyTokenTx(
      {
        memeId,
        ownerAddress,
        amount: tokenAmount,
        metadata,
        firstBuy,
      },
      tonAmount,
      buyTime,
    );
    timeLog.end('_buyToken:getBuyTokenTx');

    const submit = async () => {
      cai('tonConnectSubmit', { time: Date.now() });
      const txHash = await this.sendTransaction(buyTokenTx);
      this.setConfirmationWaiter(
        withRetry(this.confirmBuyToken(txHash), {
          maxRetries: 60, // 1min (30s avg)
        }).catch(() => {
          this.app.ui.showError({
            title: 'Transaction Failed',
            message: `Failed to buy meme.\n${txHash}`,
          });
        }),
      );

      this.app.invoke.saveUnconfirmedTx({
        txType: 'buy',
        memeId,
        txHash,
        walletAddress,
      });

      this.scheduleTxConfirmation();

      return txHash;
    };

    return submit;
  };

  private _swap = async ({
    tokenAmount,
    tonAmount,
    jettonContractAddress,
    memeId,
    swapType,
  }: {
    tokenAmount: HighPrecision;
    tonAmount: string;
    jettonContractAddress: string;
    memeId: string;
    swapType: SwapType;
  }) => {
    const walletAddress = this.walletAddress;
    const tokenContractAddress =
      jettonContractAddress || DEMO_DEX_TOKEN_ADDRESS; // todo: comes from the UI
    const proxyTon = pTON.v2_1.create(STONFI_PTON_ADDRESS);
    const ton = '0.1'; // todo: comes from the UI

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    const router = this.provider.client.open(
      DEX.v2_1.Router.create(STONFI_ROUTER_ADDRESS),
    );

    let txParams: SenderArguments;
    if (swapType === 'tonToJetton') {
      txParams = await router.getSwapTonToJettonTxParams({
        userWalletAddress: walletAddress,
        proxyTon,
        askJettonAddress: Address.parse(tokenContractAddress),
        offerAmount: toNano(tonAmount),
        minAskAmount: '1',
        queryId: Date.now(),
      });
    } else {
      txParams = await router.getSwapJettonToTonTxParams({
        userWalletAddress: walletAddress,
        offerJettonAddress: Address.parse(tokenContractAddress),
        offerAmount: tokenAmount.toString(),
        minAskAmount: '1',
        proxyTon,
        queryId: Date.now(),
      });
    }

    const txHash = await this.sendTransaction({
      validUntil: Date.now() + 1000000,
      messages: [
        {
          address: txParams.to.toString(),
          amount: txParams.value.toString(),
          payload: txParams.body?.toBoc().toString('base64'),
        },
      ],
    });

    // seems confirmBuyToken is generic enough to be used here
    this.setConfirmationWaiter(
      withRetry(this.confirmBuyToken(txHash), {
        maxRetries: 60, // 1min (30s avg)
      }).catch(() => {
        this.app.ui.showError({
          title: 'Transaction Failed',
          message: `Failed to buy meme.\n${txHash}`,
        });
      }),
    );

    this.app.invoke.saveUnconfirmedTx({
      txType: swapType === 'tonToJetton' ? 'dexBuy' : 'dexSell',
      memeId,
      txHash,
      walletAddress,
    });

    this.scheduleTxConfirmation();

    return txHash;
  };

  private scheduleTxConfirmation = () => {
    if (this.txVerificationTimeout !== null) {
      clearTimeout(this.txVerificationTimeout);
    }

    const txVerifDelay = getTxVerificationRetryDelay(this.app.state);
    if (!isFinite(txVerifDelay)) {
      return;
    }

    this.txVerificationTimeout = setTimeout(async () => {
      const updatedMemeIds = await this.app.invoke.runTxConfirmationAsync();

      // will have the effect of refreshing the meme states on the client,
      // including the supply, which can in turn trigger the graduation
      this.app.memes.refreshMemes(updatedMemeIds);

      this.scheduleTxConfirmation();
    }, txVerifDelay);
  };

  private setConfirmationWaiter = (promise: Promise<any>) => {
    this.confirmationWaiter = promise.then(() => {
      // Do it with a delay so open search has time to propagate
      setTimeout(() => {
        this.app.profile.refreshUser();
      }, 3000);
    });
  };

  buyToken = async (input: BuyTokenInput) => {
    timeLog.start('buyToken:connect');
    await this.connect();
    timeLog.end('buyToken:connect');

    const { meme, tokenAmount, tonAmount } = input;
    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    const firstBuy = !meme.isMinted;
    if (firstBuy) {
      try {
        // async double check if it's not actually minted but not updated
        this.provider.getJettonBalance(meme.jettonContractAddress).then(() => {
          // If request succeeds that means it has onchain data, therefore is minted
          this.app.invoke.onJettonContractMinted({ memeId: meme.id });
        });
      } catch {}
    }

    const metadata = getContractMetadataFromMeme({
      ...meme,
      description: meme.description.description,
    });

    const submit = this._buyToken({
      ownerAddress: meme.creatorWalletAddress,
      tokenAmount,
      metadata,
      firstBuy,
      tonAmount,
      jettonContractAddress: meme.jettonContractAddress,
    }).then((res) => {
      // todo: optimize this, we don't need to trigger on every buy but may be close to graduation
      if (!meme.isGraduated) {
        this.triggerGraduation(meme.id);
      }
      return res;
    });

    return submit;
  };

  buyDexToken = async (input: BuyTokenInput) => {
    await this.connect();

    const { meme, tonAmount } = input;
    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    if (!meme.dexContractAddress) {
      throw new Error('Missing Dex Contract Address');
    }

    return this._swap({
      tokenAmount: HP(0),
      tonAmount: tonAmount,
      jettonContractAddress: meme.dexContractAddress,
      memeId: meme.id,
      swapType: 'tonToJetton',
    });
  };

  sellToken = async (metadataInput: PickMetadaFromMeme, amount: string) => {
    timeLog.start('sellToken:connect');
    await this.connect();
    timeLog.end('sellToken:connect');

    const metadata = getContractMetadataFromMeme(metadataInput);

    const { memeId } = metadata;

    timeLog.start('sellToken:getSellTokenTx');
    const sellTokenTx = await this.provider.getSellTokenTx(
      {
        amount: BigInt(amount),
        memeId,
        metadata,
      },
      this.app.now(),
    );
    timeLog.end('sellToken:getSellTokenTx');

    const submit = async () => {
      cai('sellToken:submit');
      const txHash = await this.sendTransaction(sellTokenTx);
      // @TODO: see how many transactions we get from selling
      this.setConfirmationWaiter(
        withRetry(this.confirmBuyToken(txHash), {
          maxRetries: 60, // 1min (30s avg)
        }).catch(() => {
          this.app.ui.showError({
            title: 'Transaction Failed',
            message: `Failed to sell meme.\n${txHash}`,
          });
        }),
      );

      this.app.invoke.saveUnconfirmedTx({
        txType: 'sell',
        memeId,
        txHash,
        walletAddress: this.walletAddress!,
      });

      this.scheduleTxConfirmation();

      return txHash;
    };

    return submit;
  };

  sellDexToken = async (input: BuyTokenInput) => {
    await this.connect();

    const { meme, tokenAmount } = input;
    const walletAddress = this.walletAddress;

    if (!walletAddress) {
      throw new Error('Missing Wallet Address');
    }

    if (!meme.dexContractAddress) {
      throw new Error('Missing Dex Contract Address');
    }

    return this._swap({
      tokenAmount: tokenAmount,
      tonAmount: '',
      jettonContractAddress: meme.dexContractAddress,
      memeId: meme.id,
      swapType: 'jettonToTon',
    });
  };

  claimDexToken = async (
    metadataInput: PickMetadaFromMeme,
    claimedTokenAmount: string,
    userId: string,
    claimType: 'graduate' | 'daily' = 'daily',
  ) => {
    await this.connect();

    const metadata = getContractMetadataFromMeme(metadataInput);

    const { memeId } = metadata;

    let claimDexTokenTx;
    if (claimType === 'graduate') {
      const result = await this.provider.claimDexTokenTxGraduate(
        memeId,
        userId,
      );
      claimDexTokenTx = result.result;
    } else {
      claimDexTokenTx = await this.provider.claimDexTokenTx(
        {
          amount: BigInt(claimedTokenAmount),
          memeId,
          metadata,
        },
        this.app.now(),
      );
    }

    console.log(metadata);

    const txHash = await this.sendTransaction(claimDexTokenTx);
    // @TODO: see how many transactions we get from claiming
    this.setConfirmationWaiter(
      withRetry(this.confirmBuyToken(txHash), {
        maxRetries: 60, // 1min (30s avg)
      }).catch(() => {
        this.app.ui.showError({
          title: 'Transaction Failed',
          message: `Failed to sell meme.\n${txHash}`,
        });
      }),
    );
  };

  getJettonAddress = async (metadataInput: PickMetadaFromMeme) => {
    const metadata = getContractMetadataFromMeme(metadataInput);
    return this.provider.getJettonContractAddressFromMetadata(metadata);
  };

  getMemeBalance = async (memeId: string) => {
    const meme = await this.app.memes.getMeme(memeId);
    console.log('getMemeBalance', { memeId, meme });
    if (!meme || !meme.jettonContractAddress) {
      return HP(0);
    }

    if (meme.dexContractAddress) {
      return HP(await this.provider.getJettonBalance(meme.dexContractAddress));
    }

    return HP(await this.provider.getJettonBalance(meme.jettonContractAddress));
  };

  getTx = async (hash: string) => {
    const tx = await this.provider.getTx(hash);
    console.log({ tx });
    return tx?.hash;
  };

  getHolders = async (contractAddress: string) => {
    const holders = await this.provider.getHolders(contractAddress);
    console.log({ holders });
    return holders.total;
  };

  getBuyPrice = async (contractAddress: string, amount: string) => {
    return HP(await this.provider.getJettonBuyPrice(contractAddress, amount));
  };

  getListingTokens = (ton: string) => {
    cai('getListingTokens', { time: Date.now() });
    return this.provider.getListingBuyTokens(ton);
  };

  getEstimatedListingTokens = (ton: string) => {
    return this.provider.getEstimatedListingBuyTokens(ton);
  };

  getBuyTokensPerTon = (contractAddress: string, ton: string) => {
    cai('getBuyTokensPerTon', { time: Date.now() });
    return this.provider.getBuyTokens(contractAddress, ton);
  };

  getEstimatedTonToTokens = (contractAddress: string, ton: string) => {
    return this.provider.getEstimatedBuyTokens(contractAddress, ton);
  };

  getEstimatedTokensToTon = async (contractAddress: string, tokens: bigint) => {
    return this.provider.getSellTokens(contractAddress, tokens);
  };

  getTonToUSDSync = (ton: string) => {
    return this.provider.getTonToUSDSync(ton);
  };

  getTonToUSD = async (ton: string) => {
    return this.provider.getTonToUSD(ton);
  };

  getTokenToTON = (tokens: bigint, supply: bigint, txType: TxType) => {
    return this.provider.getTokenToTON(tokens, supply, txType);
  };

  getJettonSupply = async (contractAddress: string) => {
    return this.provider.getJettonSupply(contractAddress);
  };

  getDexGraduationPct = async (tokenSupply: string) => {
    const graduationPct =
      (100 * HP(tokenSupply).toNumber()) /
      Number(this.provider.curveConfig.graduationSupply);
    return Math.min(100, graduationPct);
  };

  triggerGraduation = async (memeId: string) => {
    return this.provider.triggerGraduation({
      memeId: memeId,
      env: getEnvCode(),
    });
  };

  getDexTonToTokens = async (input: BuyTokenInput) => {
    const { meme, tonAmount } = input;
    const dexTokenAddress = meme.dexContractAddress || DEMO_DEX_TOKEN_ADDRESS;
    const tokensReceived = await this.provider.getDexTonToTokens(
      dexTokenAddress,
      tonAmount,
    );
    return tokensReceived;
  };

  getDexTokensToTon = async (input: BuyTokenInput) => {
    cai('getDexTokensToTon', { time: Date.now() });
    const { meme, tokenAmount } = input;
    const dexTokenAddress = meme.dexContractAddress || DEMO_DEX_TOKEN_ADDRESS;
    const tokensReceived = await this.provider.getDexTokensToTon(
      dexTokenAddress,
      tokenAmount.toString(),
    );
    return tokensReceived;
  };

  getSeqno = (index: number) => {
    return this.provider.getSeqno(index);
  };

  getTransactions = async (addressFriendly: string) => {
    // return this.provider.getTransactionMetadata(addressFriendly);
    return this.provider.getParseTransactions(addressFriendly);
  };

  test = async () => {
    // const transactions = await fetch(
    //   'https://toncenter.com/api/v3/transactions?hash=c2c7abae3347b04ef22772bffdfbb23a17b23e4a8d770b6aa96eb0e7d6b6007e',
    // )
    //   .then((res) => res.json())
    //   .then((v) => {
    //     console.log(v);
    //     return v.transactions;
    //   });
    // this.provider.getParseTransactions(transactions);
    this.provider.getParseTransactions(
      'EQBW8qI2QK88cYFdrGmlmDkcHKU7PDkU3--m2AjP9JwHmbJR',
    );
  };

  checkIn = async () => {
    if (this.app.now() - this.app.state?.dailyContractCheckin < ONE_DAY_MS) {
      console.log('Already checked in today');
      return;
    }

    await this.connect();

    const checkInTx = this.provider.getCheckInTx(this.app.now());

    return this.tonConnectUI.sendTransaction(checkInTx);
  };

  // This is used to render the test page
  public ui = {
    connect: this.connect,
    disconnect: this.disconnect,
    createContract: this.createContract,
    buyToken: this.buyToken,
    sellToken: this.sellToken,
    claimDexToken: this.claimDexToken,
    buyStonFi: this.buyDexToken,
    getLiquidityPoolData: this.provider.getLiquidityPoolData,
    getJettonContractAddress:
      this.provider.getJettonContractAddressFromMetadata,
    getMemeBalance: this.getMemeBalance,
    getJettonWalletAddress: this.provider.getJettonWalletAddress,
    getJettonBuyPrice: this.provider.getJettonBuyPrice,
    // getTxs: this.provider.getTransactions,
    getTx: this.getTx,
    getHolders: this.getHolders,
    getSeqno: this.getSeqno,
    test: this.test,
    getDexGraduationPct: this.getDexGraduationPct,
  };
}
