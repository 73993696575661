import Big from 'big.js';
import { ChangeEvent, useEffect, useState } from 'react';
import { app } from '../data/Controllers/AppController';
import { Form, FormEvents } from '../data/Form';
import { useManyAppUpdates } from '../data/hooks';
import { TonProvider } from '../data/TonProvider/TonProvider';
import { fromNano, toNano } from '@ton/core';
import { adminContractAddresses } from '../replicant/features/tradingMeme/tradingMeme.getters.ton';

const style = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  background: 'blue',
  zIndex: 5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center',
  overflow: 'scroll',
  paddingBottom: '30px',
  opacity: 0.9,
} as const;

type TonService = keyof typeof app.ton.ui;
const tonServices = Object.keys(app.ton.ui) as TonService[];

const createTokenForm = new Form({
  memeId: {
    title: 'TokenId',
    required: true,
  },
  name: {
    title: 'Name',
    required: true,
  },
  description: {
    title: 'Description',
    required: true,
  },
  symbol: {
    title: 'Ticker',
    required: true,
  },
  image: {
    title: 'Image',
    required: true,
  },
});

const getContractAddressForm = new Form({
  memeId: {
    title: 'TokenId',
    required: true,
  },
});

// buy and/or sell
const transferForm = new Form({
  memeId: {
    title: 'TokenId',
    required: true,
  },
  amount: {
    title: 'Amount',
    required: true,
    validate: (value) => !Number.isNaN(Number(value)),
  },
});

const dexTransferForm = new Form({
  dexPoolAddress: {
    title: 'jettonContractAddress',
    required: true,
  },
  amount: {
    title: 'Amount',
    required: true,
    validate: (value) => !Number.isNaN(Number(value)),
  },
});

const jettonBalance = new Form({
  memeId: {
    title: 'Meme ID',
    required: true,
    initialValue: 'EQBpF_vCqu1EhaVIXJvhT8KiqEEZMR0ryEncyc6IkJoOVW2g',
  },
});

const getTx = new Form({
  hash: {
    title: 'Hash',
    required: true,
  },
});

const seqnoForm = new Form({
  seqnoIndex: {
    title: 'Seqno Index',
    required: true,
  },
});

const getPoolDataForm = new Form({
  dexJettonContractAddress: {
    title: 'dexJettonContractAddress',
    required: true,
    initialValue: 'EQApfjfy0Uuy-ixrFMrVWSEL4WMOsGDcTsha9i5lZUR5wMS2',
  },
});

type Forms =
  | typeof createTokenForm
  | typeof getContractAddressForm
  | typeof transferForm
  | typeof dexTransferForm
  | typeof jettonBalance
  | typeof getTx
  | typeof seqnoForm
  | typeof getPoolDataForm;

const serviceToForm = {
  createContract: createTokenForm,
  getJettonContractAddress: getContractAddressForm,
  buyToken: transferForm,
  sellToken: transferForm,
  claimDexToken: transferForm,
  buyStonFi: dexTransferForm,
  jettonBalance,
  getJettonWalletAddress: jettonBalance,
  getJettonBuyPrice: jettonBalance,
  getTx: getTx,
  getHolders: jettonBalance,
  getSeqno: seqnoForm,
  graduation: jettonBalance,
  getPoolData: getPoolDataForm,
} satisfies Record<string, Forms>;

type FormName = keyof typeof serviceToForm;
const formNames = Object.keys(serviceToForm) as FormName[];

/**
 * Instructions:
 * Adding new services is easy, once it's added to the app.ton.ui it will show up here.
 * ! You only need to change this component if you adding a service that takes params !
 * The component is configured to render everything automatically from config so follow these steps:
 * 1) Create a new Form to take the inputs for the params.
 * 2) Map your form to the service you wish to pass the parameters to in `serviceToForm` (above)
 * 3) Add your case to `getServiceParams` (below) following the pattern of returning an array with the params in the expected order
 */
export const TonTest = () => {
  const getServiceParams = (id: TonService) => {
    switch (id) {
      case 'getJettonContractAddress':
        return [getContractAddressForm.data.memeId];

      case 'buyToken':
      case 'sellToken':
      case 'claimDexToken':
      case 'buyStonFi':
        return [transferForm.data.memeId, Big(transferForm.data.amount || 0)];

      case 'createContract':
        const { memeId, ...metadata } = createTokenForm.data;
        return { memeId, metadata };

      case 'getMemeBalance':
      case 'getJettonWalletAddress':
      case 'getJettonBuyPrice':
      case 'getHolders':
      case 'getDexGraduationPct':
        return jettonBalance.data.memeId;

      case 'getTx':
        return getTx.data.hash;

      case 'getSeqno':
        return Number(seqnoForm.data.seqnoIndex);

      default:
        return undefined;
    }
  };

  useManyAppUpdates({
    id: 'TonTest',
    events: formNames.map((key) => {
      return {
        listener: serviceToForm[key].attachEventListener(FormEvents.OnUpdate),
      };
    }),
  });

  const [balance, setBalance] = useState('-');

  const [loading, setLoading] = useState(false);

  const request = (txId: string, promise: Promise<any>) => {
    setLoading(true);
    return promise
      .then(onSuccess(txId))
      .catch(onFailure(txId))
      .finally(() => {
        setLoading(false);
      });
  };

  const onSuccess = (txId: string) => (res: any) => {
    console.log(`Transaction '${txId}' success`);
    console.log(res);
  };

  const onFailure = (txId: string) => (res: any) => {
    console.error(`Transaction '${txId}' failed`);
    console.error(res);
  };

  const onClick = (id: TonService) => () => {
    const service = app.ton.ui[id];
    const params = getServiceParams(id);

    // Scramble the type to accept any param
    const serviceFn: (...p: any) => Promise<any> = service;
    request(id, serviceFn(params));
  };

  useEffect(() => {
    const fetchBalance = async () => {
      setBalance(await app.ton.getBalance());
    };

    fetchBalance();
  }, []);

  return (
    <div style={style}>
      <h1>TON TEST</h1>
      <>
        <label>ADMIN CONTRACT ADDRESS</label>
        <select
          onChange={(e) => {
            TonProvider.adminContractAddress = e.target.value;
          }}
        >
          {adminContractAddresses.map((address) => {
            return <option value={address}>{address}</option>;
          })}
        </select>
        <br />
      </>

      <>
        <label>Balance</label>
        <p>{balance}</p>
      </>

      {loading ? (
        <p>Loading...</p>
      ) : (
        tonServices.map((key) => {
          return (
            <>
              <button
                onClick={onClick(key)}
                disabled={
                  !Boolean(serviceToForm[key as FormName]?.isValid) &&
                  serviceToForm[key as FormName] !== undefined
                }
              >
                {key}
              </button>
              <br />
            </>
          );
        })
      )}
      {formNames.map((formName, index) => {
        return (
          <FormC
            key={formName + index}
            title={formName}
            form={serviceToForm[formName]}
          />
        );
      })}
    </div>
  );
};

// Lose type safety to make this take generic so its easier for people to update without changing component code
const FormC = ({ form, title }: { form: Forms; title: string }) => {
  const onInputChange =
    (form: Forms, key: keyof typeof form.data) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      form.setField(key, value);
    };

  return (
    <>
      <h3>{title}</h3>
      {form.fields.map((cfg, index) => {
        return (
          <div key={cfg.title + index}>
            <label>{cfg.title}</label>
            <input
              value={(form.data as any)[cfg.key]}
              onChange={onInputChange(form, cfg.key as never)}
            />
          </div>
        );
      })}
    </>
  );
};
