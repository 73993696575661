import {
  createSharedStateMessages,
  createSharedStateMessage,
  SB,
} from '@play-co/replicant';
import {
  contractTypeSchema,
  onchainTxsSharedStateSchema,
  OnchainTxsState,
  TxOutcome,
  txOutcomeSchema,
} from './onchainTxs.schema';
import { MAX_TXS_PER_PAGE } from './onchainTxs.ruleset';

const recordTx = (
  state: OnchainTxsState,
  txOutcome: TxOutcome,
  txHashes: string[],
) => {
  const recordedTxHashCound =
    state.successfulTxHashes.length + state.failedTxHashes.length;

  if (recordedTxHashCound + txHashes.length > MAX_TXS_PER_PAGE) {
    // throw new Error(`Max number of transactions reached for meme ${state.memeId} on ${state.contractType}`);
    // maximum number of transactions reached
    return;
  }

  if (txOutcome === 'success') {
    state.successfulTxHashes = state.successfulTxHashes.concat(txHashes);
    return;
  }

  if (txOutcome === 'failure') {
    state.failedTxHashes = state.failedTxHashes.concat(txHashes);
    return;
  }

  throw new Error(`Tx outcome invalid: ${txOutcome}`);
};

// @warning: never remove/rename shared state messages
export const onchainTxsMessages = createSharedStateMessages(
  onchainTxsSharedStateSchema,
)({
  createTxPage: createSharedStateMessage(
    SB.object({
      memeId: SB.string(),
      contractType: contractTypeSchema,
      page: SB.number(),
    }),
    (state, { memeId, contractType, page }, meta) => {
      state.global.memeId = memeId;
      state.global.contractType = contractType;
      state.global.page = page;
    },
  ),
  addTransactions: createSharedStateMessage(
    SB.object({
      memeId: SB.string(),
      contractType: contractTypeSchema,
      page: SB.number(),
      txOutcome: txOutcomeSchema,
      txHashes: SB.array(SB.string()),
    }),
    (state, { memeId, contractType, page, txOutcome, txHashes }, meta) => {
      if (!state.global.memeId) {
        // in case the createTxPage did not work
        state.global.memeId = memeId;
        state.global.contractType = contractType;
        state.global.page = page;
      }

      recordTx(state.global, txOutcome, txHashes);
    },
  ),
});
