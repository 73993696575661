import { EventListener } from '../EventListener';
import {
  getBuyTxEstimate,
  getRandomMemeImage,
  getRandomTickr,
} from '../../replicant/features/tradingMeme/tradingMeme.getters';
import { CreateCardPayload } from '../../replicant/features/tradingMeme/tradingMeme.actions';
import { AppController } from './AppController';
import { HP } from '../../replicant/lib/HighPrecision';

export class AutomationController extends EventListener {
  constructor(private app: AppController) {
    super();
  }

  async generateTestData(n: number) {
    for (let i = 0; i < n; i++) {
      await this._generateTestData();
    }
  }

  private async _generateTestData() {
    try {
      // get random number between 1 and 100
      const testUserId = String(Math.floor(Math.random() * 100) + 1);
      console.log('testUserId', testUserId);
      await this.app.replicant.setTestUsers({
        [testUserId]: {
          username: `bot_${this.app.replicant.state.username}_${testUserId}`,
          profile: {
            name: `Bot ${this.app.replicant.state.profile.name}-${testUserId}`,
            photo: getRandomMemeImage(),
          },
          score: 20000000,
          balance: 20000000,
          taps: 20000000,
          tokenCreationCredits: 100,
        },
      });

      // await this.app.invoke.incrementScore({testUserId: testUserId, score: 200000});

      const testUserStates = await this.app.replicant.fetchStates([testUserId]);
      const testUserState = testUserStates[testUserId].state;
      // console.log('testUserState', testUserState);
      const iapResponse = 'success';
      if (iapResponse === 'success') {
        let randomTickr = getRandomTickr();

        const memeIdResponse = await this.app.invoke.asyncGetNewMemeId();
        const memeId = memeIdResponse.data?.memeId;

        if (!memeId) {
          console.log('failed to create meme id');
          return;
        }

        const name = `Meme Card ${randomTickr}`;
        const description = `Mockup meme Card Description ${randomTickr}`;
        const createCardPayload: CreateCardPayload = {
          memeId,
          memeInput: {
            // id: randomTickr,
            image: getRandomMemeImage(),
            name,
            ticker: randomTickr,
            description,
            creatorWalletAddress: `wallet-address-${Date.now()}`,
          },
          memeAddressSeed: {
            id: memeId,
            name,
            description,
            ticker: randomTickr,
            image: getRandomMemeImage(),
          },
          // currencyAmount: '100',
          // productId: `Meme-${randomTickr}`,
          isLocal: true,
          // useCredit: true,
        };
        const createCardResponse =
          await this.app.invoke.asyncCreateOffchainTokenForTestUser({
            testUserId: testUserId,
            createCardPayload,
          });
        console.log('createCardResponse', createCardResponse);
      } else {
        console.log('iapResponse', iapResponse);
      }

      // get test cards, randomly buy and sell cards
      const allMemeTokens = await this.app.asyncGetters.searchMemes({
        searchString: undefined,
        field: 'pointSupply',
      });
      console.log('allMemeTokens', allMemeTokens);
      if (!allMemeTokens || allMemeTokens.length === 0) {
        console.log('Nothing to buy and sell');
        return;
      }
      const buy = Math.random() > 0.5;
      if (buy) {
        // get random item from array
        const randomToken =
          allMemeTokens[Math.floor(Math.random() * allMemeTokens.length)];
        console.log('Buying card', randomToken);
        const amount = 100;
        const price = getBuyTxEstimate({
          currencyAmount: HP(amount),
          currentSupply: HP(randomToken.pointSupply),
        });

        const response = await this.app.invoke.buyOffchainToken({
          offchainTokenId: randomToken.id,
          currencyAmount: amount.toString(),
          pointAmountEstimate: price.toString(),
          testUserId: testUserId,
        });
        console.log('buy response', response);
        // } else {
        //   const ownedMemeTokens = await this.getMyOffchainTokensListing(testUserState);
        //   console.log('ownedMemeTokens', ownedMemeTokens);
        //   const randomCard =
        //     ownedMemeTokens[Math.floor(Math.random() * ownedMemeTokens.length)];
        //   if (!randomCard) {
        //     console.log('No cards to sell');
        //     return;
        //   }
        //   console.log('Selling card', randomCard);
        //   const amount = 50;
        //   const price = getSellTxEstimate({
        //     pointAmount: highPrecision(amount),
        //     currentSupply: randomCard.supply,
        //   });
        //
        //   const response = await this.app.invoke.sellOffchainToken({
        //     offchainTokenId: randomCard.id,
        //     pointAmount: amount,
        //     currencyAmountEstimate: price,
        //   });
        //   console.log('sell response', response);
      }
    } catch (e) {
      console.error('Error generating test data', e);
    }
  }
}
