import {
  AsyncGettersAPI,
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
  ReplicantSyncActionAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import { ContractType, OnchainTxsState } from './onchainTxs.schema';
import { MAX_TXS_PER_PAGE } from './onchainTxs.ruleset';

export function getOnchainTxsStateId(
  memeId: string,
  contractType: ContractType,
  page: number,
) {
  return `onchain-txs-${memeId}-${contractType}-${page}`;
}

export function getTxCount(state: OnchainTxsState) {
  return state.successfulTxHashes.length + state.failedTxHashes.length;
}

export async function getOnchainTxsPageCount(
  api:
    | AsyncGettersAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  memeId: string,
  contractType: ContractType,
) {
  const pageCount = await api.sharedStates.onchainTxs.count({
    where: {
      memeId: {
        isAnyOf: [memeId],
      },
      contractType: {
        isAnyOf: [contractType],
      },
    },
  });

  return pageCount;
}

export async function getOnchainTxsPageForNewTx(
  api:
    | AsyncGettersAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  memeId: string,
  contractType: ContractType,
) {
  const pageCount = await api.sharedStates.onchainTxs.count({
    where: {
      memeId: {
        isAnyOf: [memeId],
      },
      contractType: {
        isAnyOf: [contractType],
      },
    },
  });

  if (pageCount === 0) {
    const firstPage = 1;
    return firstPage;
  }

  const lastPage = (
    await api.sharedStates.onchainTxs.search({
      where: {
        memeId: {
          isAnyOf: [memeId],
        },
        contractType: {
          isAnyOf: [contractType],
        },
        page: {
          between: [pageCount, pageCount],
        },
      },
      limit: 1,
    })
  ).results;

  if (lastPage.length === 0) {
    throw new Error(
      `Page ${pageCount} of meme ${memeId} on ${contractType} is missing`,
    );
  }

  const txCount = lastPage[0].txCount;

  if (txCount < MAX_TXS_PER_PAGE) {
    return pageCount;
  }

  // const lastPage = await api.sharedStates.onchainTxs.search({
  //   where: {
  //     memeId: {
  //       isAnyOf: [memeId],
  //     },
  //     contractType: {
  //       isAnyOf: [contractType],
  //     },
  //   },
  // });

  return pageCount;
}
