import { State } from '../../schema';
import { curveConfig, txConfig } from './offchainTrading.ruleset';
import { PriceSlice, StatsSlice, TradingState } from './offchainTrading.schema';
import { HP, HighPrecision } from '../../lib/HighPrecision';

export function getCurvePrice(supply: HighPrecision) {
  return curveConfig.startPrice.mul(
    supply.exp(supply.mul(curveConfig.exponent).toNumber()),
  );
}

export function getSupplyFromCurvePrice(price: HighPrecision) {
  return HP(Math.log(price.div(curveConfig.startPrice).toNumber())).div(
    curveConfig.exponent,
  );
}

function getPointAmountForCoinBuy(
  currentSupply: HighPrecision,
  coinsToSpend: HighPrecision,
  priceModifier = 1 + txConfig.fee,
) {
  let coinsToSpendValue = coinsToSpend;
  if (!(coinsToSpend instanceof HighPrecision)) {
    coinsToSpendValue = HP(coinsToSpend);
  }

  const adjustedCoinsToSpend = coinsToSpendValue
    .mul(curveConfig.exponent)
    // .div(curveConfig.startPrice.mul(txConfig.buyModifier).mul(priceModifier));
    .div(curveConfig.startPrice.mul(priceModifier));

  const term = adjustedCoinsToSpend.add(
    Math.exp(currentSupply.mul(curveConfig.exponent).toNumber()),
  );

  const pointAmount = HP(1)
    .div(curveConfig.exponent)
    .mul(
      HP(Math.log(term.toNumber())).minus(
        currentSupply.mul(curveConfig.exponent),
      ),
    );
  return pointAmount;
}

export function getGrossCoinAmountForPointSell(
  currentSupply: HighPrecision,
  pointAmount: HighPrecision,
) {
  let pointAmountDiff = currentSupply.minus(pointAmount);
  if (pointAmountDiff.lt(0)) {
    pointAmountDiff = currentSupply;
  }

  const pricing = curveConfig.startPrice.div(curveConfig.exponent);

  const curve = HP(
    Math.exp(curveConfig.exponent.mul(currentSupply).toNumber()),
  );

  const cadence = HP(
    Math.exp(
      curveConfig.exponent.mul(currentSupply.minus(pointAmount)).toNumber(),
    ),
  );

  return pricing.mul(curve.minus(cadence));
}

// (1 - spread) * (1 - fee) * (constant / exponent) * (EXP(exponent * (total supply + amount to sell)) - EXP(exponent * total supply))
export function getCoinAmountForPointSell(
  currentSupply: HighPrecision,
  tokensToSell: HighPrecision,
) {
  const grossCurrencyAmount = getGrossCoinAmountForPointSell(
    currentSupply,
    tokensToSell,
  );
  // const netCurrencyAmount = grossCurrencyAmount.mul(
  //   txConfig.sellModifier.minus(txConfig.fee),
  // );
  const netCurrencyAmount = grossCurrencyAmount.mul(HP(1 - txConfig.fee));

  return netCurrencyAmount;
}

export function getMyOffchainMemeIds(state: State) {
  return Object.keys(state.trading.offchainTokens);
}

export function getMyOffchainTokenPointAmount(
  state: State,
  offchainTokenId: string,
) {
  return state.trading.offchainTokens[offchainTokenId]?.pointAmount || '0';
}

export function getMyOffchainTokenPointsAccumulated(
  state: State,
  offchainTokenId: string,
) {
  return (
    state.trading.offchainTokens[offchainTokenId]?.pointsAccumulated || '0'
  );
}

export interface CanBuyOpts {
  currencyAmount: HighPrecision;
  pointAmountEstimate: HighPrecision;
  driftPct: number;
}

export function getCanBuy(state: TradingState, opts: CanBuyOpts) {
  const { currencyAmount, pointAmountEstimate, driftPct } = opts;
  const buyPointAmount = getBuyEstimate(state, currencyAmount);
  const minBuyPointAmount = pointAmountEstimate.minus(
    pointAmountEstimate.mul(driftPct - 1),
  );

  return buyPointAmount.gte(minBuyPointAmount);
}

export interface CanSellOpts {
  pointAmount: HighPrecision;
  currencyAmountEstimate: HighPrecision;
  driftPct: number;
}

export function getCanSell(state: TradingState, opts: CanSellOpts) {
  const { pointAmount, currencyAmountEstimate, driftPct } = opts;
  const sellPrice = getSellEstimate(state, pointAmount);

  const minSellPrice = currencyAmountEstimate.minus(
    currencyAmountEstimate.mul(driftPct - 1),
  );

  return sellPrice.gte(minSellPrice);
}

/**
 * Use this function to get the estimate token amount the user can purchase given the
 * currencyAmount. The estimate deducts the transactions fee. Use optional opts to modify the behaviour
 * @param state
 * @param currencyAmount
 * @returns the token amount that can be bought with the currencyAmount
 */
export const getBuyEstimate = (
  state: TradingState,
  currencyAmount: HighPrecision,
) => {
  return getBuyTxEstimate({
    currencyAmount,
    currentSupply: HP(state.supply),
  });
};

/**
 * Use this function to get the estimate amount of currencyAmount the user can get given the
 * pointAmount it wants to sell
 * @param state
 * @param pointAmount
 * @returns
 */
export const getSellEstimate = (
  state: TradingState,
  pointAmount: HighPrecision,
) => {
  return getCoinAmountForPointSell(HP(state.supply), pointAmount);
};

export const getCreateEstimate = (currencyAmount: HighPrecision) => {
  return getPointAmountForCoinBuy(HP(0), currencyAmount);
};

interface BuyTx {
  currencyAmount: HighPrecision;
  currentSupply: HighPrecision;
}

export const getBuyTxEstimate = (opts: BuyTx) => {
  return getPointAmountForCoinBuy(opts.currentSupply, opts.currencyAmount);
};

export function getPriceBackThen(pricePoints: PriceSlice[], then: number) {
  if (pricePoints.length === 0) {
    return curveConfig.startPrice;
  }

  let priceIdx = pricePoints.findIndex((pricePoint) => {
    return pricePoint.time > then;
  });

  if (priceIdx !== 0) {
    if (priceIdx > 0) {
      // we want the price just before the slice that comes after the "then"
      priceIdx -= 1;
    } else {
      priceIdx = pricePoints.length - 1;
    }
  }

  return HP(pricePoints[priceIdx].price);
}

export function getStatsBackThen(
  stats: StatsSlice[],
  then: number,
): StatsSlice {
  if (stats.length === 0) {
    return {
      time: then,
      volume: '0',
      holderCount: 0,
    };
  }

  let statsIdx = stats.findIndex((statsSlice) => {
    return statsSlice.time > then;
  });

  if (statsIdx !== 0) {
    if (statsIdx > 0) {
      // we want the price just before the slice that comes after the "then"
      statsIdx -= 1;
    } else {
      statsIdx = stats.length - 1;
    }
  }

  return stats[statsIdx];
}

export function getValueChange(
  priceNow: HighPrecision,
  priceThen: HighPrecision,
) {
  if (priceThen.eq(0)) {
    return 0;
  }
  return priceNow.div(priceThen).minus(1).toNumber();
}

export function getMeanHoldings(state: TradingState) {
  return HP(state.supply).div(state.holderCount);
}

export function getVariance(state: TradingState) {
  if (state.holderCount <= 0) {
    return HP(0);
  }

  const meanHoldings = getMeanHoldings(state);
  const sumOfHoldingsSquare = HP(state.sumOfHoldingsSquare);
  const variance = sumOfHoldingsSquare
    .div(state.holderCount)
    .minus(meanHoldings.pow(2));

  // Handle potential floating-point errors
  if (variance.lt(0)) {
    return HP(0);
  }

  return variance;
}

// "ceofficient of variation" is a measure of inequality
export function getCoefficientOfVariation(state: TradingState) {
  if (state.holderCount === 1) {
    // everything held by a single person
    return 1;
  }

  if (state.holderCount <= 0) {
    // no one holds the token = fairest distribution
    return 0;
  }

  const meanHoldings = getMeanHoldings(state);
  if (meanHoldings.lte(0)) {
    // note that it should not happen
    // in this case we default to 1 to avoid promoting "broken" tokens
    return 1;
  }

  const holdingsSdToMean = getVariance(state).sqrt();
  const c = holdingsSdToMean.div(meanHoldings).toNumber();
  // normalize, constant chosen arbitrarily to make variation coefficient feel good
  return c / (c + 10);
}

/**
  Points received = getPointAmountForCoinBuy(supply, Session Score / Price Modifier)
  Price Modifier = 1+(quantity*exp(-decay * share of points earned to date))
 */
export function scoreToPoints(
  currentSupply: HighPrecision,
  pointsAccumulated: string,
  pointsDistributed: string,
  score: number,
) {
  const shareOfDistribution = HP(pointsDistributed).eq(0)
    ? 1
    : HP(pointsAccumulated).div(pointsDistributed).toNumber();

  // @WARNING: DO NOT CHANGE THOSE CONSTANTS WITHOUT VERSIONING MESSAGE!
  const quantity = 0.25;
  const decay = 0.5;

  const priceModifier = 1 + quantity * Math.exp(-decay * shareOfDistribution);
  const equivalentCurrencyAmount = HP(score).div(priceModifier);
  const points = getPointAmountForCoinBuy(
    currentSupply,
    equivalentCurrencyAmount,
    1,
  );

  return {
    points,
    equivalentCurrencyAmount,
  };
}
