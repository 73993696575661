import { useState } from 'react';
import { app, useAppUpdates } from '../../data/Controllers/AppController';

export const getWalletConnected = () => {
  const [isConnected, setIsConnected] = useState(app.ton?.connected ?? false);

  useAppUpdates({
    id: 'WalletConnection',
    listener: () =>
      app.ton.tonConnectUI.onStatusChange((wallet) => {
        setIsConnected(!!wallet);
      }),
  });

  return isConnected;
};
