import {
  SB,
  payloadComputedProperty,
  searchableComputedProperty,
} from '@play-co/replicant';
import { createComputedProperties } from '../../createComputedProperties';
import { floorToSignificantDigits } from '../../utils/numbers';
import { HP } from '../../lib/HighPrecision';

export const gameComputedProperties = createComputedProperties({
  // @todo: remove score as unused and replace by a better proxy to rank friends in friend list
  score: searchableComputedProperty(SB.int(), (state) => {
    return floorToSignificantDigits(state.score, 3);
  }),
  referrerContribution: payloadComputedProperty(SB.int(), (state) =>
    Math.round(state.referrerContribution),
  ),
  balance: searchableComputedProperty(SB.int(), (state) => {
    return floorToSignificantDigits(state.balance, 3);
  }),
  name: payloadComputedProperty(SB.string(), (state) => state.profile.name),
  photo: payloadComputedProperty(SB.string(), (state) => state.profile.photo),
  lastBotNotifTime: searchableComputedProperty(SB.int(), (state) => {
    return state.lastNotifTime;
  }),
  lastSession: searchableComputedProperty(
    SB.int(),
    (state) => state.last_session_time,
  ),
  season: searchableComputedProperty(SB.int(), (state) => {
    return state.season;
  }),
  banned: searchableComputedProperty(
    SB.boolean(),
    (state) => state.banned !== undefined,
  ),
  updatedAt: searchableComputedProperty(SB.int(), (state) => state.updatedAt),
  followersCount: searchableComputedProperty(
    SB.int(),
    (state) => state.followersCount,
  ),
  referrer: searchableComputedProperty(
    SB.object({
      userId: SB.string().optional(),
      tokenId: SB.string().optional(),
    }),
    (state) => ({
      userId: state.referrer_id,
      tokenId: state.trading.referrerTokenId,
    }),
  ),
  wallets: searchableComputedProperty(
    SB.array(
      SB.object({
        app_name: SB.string(),
        address: SB.string(),
        lastConnectionTime: SB.int(),
      }),
    ),
    (state) => {
      return state.wallet.map((wallet) => wallet);
    },
  ),

  // @deprecated - remove (POST SEASON 2 MIGRATION)
  // league: searchableComputedProperty(SB.string(), (state) => getLeague(state)),
  notSeenSinceLegacy: searchableComputedProperty(SB.boolean(), (state) => {
    const legacyTerminationDate = 1717113600500;
    const lastSessionTime = state.last_session_time ?? 0;
    return lastSessionTime < legacyTerminationDate;
  }),
  // @deprecated - remove (POST SEASON 2 MIGRATION)
  scoreSeason1: searchableComputedProperty(SB.int(), (state) => {
    return state.seasonScores[0] ?? 0;
  }),
  // @deprecated - remove (POST SEASON 2 MIGRATION)
  teamId: searchableComputedProperty(
    SB.string().optional(),
    (state) => state.team_id,
  ),
  // @deprecated - to remove in subsequent release
  offchainTokens: searchableComputedProperty(
    SB.array(
      SB.object({
        id: SB.string(),
        isCreator: SB.boolean(),
        tokenAmount: SB.number(),
      }),
    ),
    (state) => {
      const offchainTokens = state.trading.offchainTokens;
      const offchainTokenList = Object.keys(offchainTokens);
      return offchainTokenList.map((tokenId) => {
        const ownedToken = offchainTokens[tokenId];
        return {
          id: tokenId,
          isCreator: Boolean(ownedToken.productId),
          tokenAmount: HP(ownedToken.pointAmount).toNumber(),
        };
      });
    },
  ),
});
