import { largeNumberToLetter } from '../../../replicant/utils/numbers';

export const LabelSubscript = ({
  sourceValue,
  stripZeroesOnRight,
}: {
  sourceValue: number;
  stripZeroesOnRight: boolean;
}) => {
  // return early if base value is zero
  if (sourceValue === 0) {
    return <div>0</div>;
  }

  const base = largeNumberToLetter(sourceValue, 4);

  const arr = base.split('.');
  const integers = arr[0];
  const decimals = arr[1];

  // return early if value is only integers
  if (!decimals) {
    return <div>{integers}</div>;
  }

  const decimalsArr = decimals.split('');

  // get subscript number of zeroes
  const posLeft = decimalsArr.findIndex((decimal) => decimal !== '0');

  // return early if number of subscript zeroes are zero
  if (posLeft === 0) {
    return <div>{base}</div>;
  }

  // get right side of the string
  let right = decimals.substring(posLeft, decimals.length - 1);

  // ----------------
  // enable this if we want to strip any zeroes on the most right side of the label
  if (stripZeroesOnRight) {
    let posRight = 0;
    decimalsArr.reverse().forEach((decimal, index) => {
      if (decimal !== '0') {
        posRight = index;
        return;
      }
    });

    right = decimals.substring(posLeft, decimals.length - posRight);
  }
  // ----------------

  return (
    <div>
      <span>{integers}.0</span>
      <span style={{ verticalAlign: 'sub', fontSize: '0.8em' }}>{posLeft}</span>
      <span>{right}</span>
    </div>
  );
};
