import './TiktokSwipeTutorialOverlay.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../assets/assets';
import { useEffect, useState } from 'react';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { isTelegramWebview } from '../../../data/device';

type AnimState = 'in' | 'out' | 'hidden';

export const TiktokSwipeTutorialOverlay = () => {
  const { t } = useTranslation();

  useAppUpdates({
    id: 'SwipeTutorial',
    listener: app.ui.swipeTutorial.attachEventListener(),
  });

  const { visible } = app.ui.swipeTutorial;

  const [state, setState] = useState<AnimState>('hidden');

  useEffect(() => {
    if (visible) {
      setState('in');
    } else if (state === 'in') {
      setState('out');
      setTimeout(() => {
        setState('hidden');
      }, 500);
    }
  }, [visible]);

  if (state === 'hidden') {
    return null;
  }

  return (
    <div className={`tiktok-swipe-tutorial-overlay ${state}`}>
      <div
        className={`swipe-tut-content ${isTelegramWebview() ? 'telegram' : ''}`}
      >
        <div className="swipe-tut-hand">
          <div className="hand-and-dot">
            <div className="dot-graph">
              <img src={assets.swipe_tut_dot} />
            </div>
            <div className="hand-graph">
              <img src={assets.swipe_tut_hand} />
            </div>
          </div>

          <div className="line-graph">
            <img src={assets.swipe_tut_line} />
          </div>
        </div>

        <div className="swipe-tut-message">Swipe for next meme</div>
      </div>
    </div>
  );
};
