import {
  getBalance,
  getEnergyLimit,
  getEnergyPerSecond,
  getIsAfter1Day,
  getIsAfter2Days,
  getIsbetween1And2Days,
  // getLeague,
  getReferralBonus,
  getRocketmanBonus,
  // getTaps,
  isNotExpired,
  getBaseEnergy,
  isEligibeForFreeMemeToken,
} from './game.getters';
import { MutableState } from '../../schema';
import { Booster, rocketmanConfig } from './ruleset/boosters';
import {
  REFERRAL_LEVEL_UPS_PREMIUM,
  REFERRAL_LEVEL_UPS_REGULAR,
  RESERVED_REFERRAL_ID,
} from './ruleset/referrals';
import { LEVEL_UP_BONUSES } from './ruleset/league';
import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
  ReplicantSyncActionAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../../config';
import { TelegramUser } from '../chatbot/chatbot.schema';
import { clampScore } from '../../utils/numbers';
import {
  DAY_IN_MS,
  HOUR_IN_MS,
  MIN_IN_MS,
  SEC_IN_MS,
  isNextDay,
} from '../../utils/time';
import {
  EarningKey,
  earningCondition,
  earningRewards,
} from './ruleset/earnings';
import { BoosterPurchaseUserProps } from './ruleset/analytics';
import {
  getGiftsNeededForNextLevel,
  getPowerUpIdsWithConditionKey,
  getPowerUpAvailableTime,
  getPowerUps,
  getTotalDailyBonusReward,
  isDailyPowerUp,
  getMiningRevenues,
} from '../powerups/getters';
import { PowerUpCardType } from '../powerups/types';
import { HIDDEN_CARD_REWARD } from '../powerups/ruleset';
import { getWhitelistedBadgesIds } from './game.getters';
import { ExpectedError } from '../../types';
import { MAX_DAILY_REWARD_STREAK } from './ruleset/dailyRewards';
import { checkQuest, getQuest } from '../quests/getters';
import { ErrorCode, errorResponse, successResponse } from '../../response';
import { addFriend } from '../friends/friends.modifiers';
import { tests, ModalLabels } from '../../ruleset';
import { maxNotifDelays, NotificationKey } from '../chatbot/chatbot.ruleset';
import templates, {
  extractCreativeAssetID,
} from '../chatbot/chatbot.templates';
import {
  confirmTransaction,
  getActualTime,
  getMyOffchainMemeIds,
  getTokenPrice,
  isMemeGraduated,
} from '../tradingMeme/tradingMeme.getters';
import { updatePointHolding } from '../tradingMeme/tradingMeme.modifiers';
import { sendTelegramMessage } from '../chatbot/chatbot.modifiers';
import { retry } from '../../lib/async';
import { tmgInitState } from '../tradingMeme/tradingMeme.modifiers';
import { TradingSearchResult } from '../tradingMeme/tradingMeme.properties';
import {
  TradingMemeStatus,
  TradingState,
} from '../tradingMeme/tradingMeme.schema';
import { initialScore, TIKTOK_INITIAL_BALANCE } from './ruleset/player';
import { HP } from '../../lib/HighPrecision';

export function updateTelegramProps(
  state: MutableState,
  telegramUser: TelegramUser,
) {
  let username = telegramUser.username;
  if (username === undefined) {
    username = telegramUser.first_name;
    if (telegramUser.last_name) {
      username = `${username} ${telegramUser.last_name}`;
    }
  }

  state.username = username;
  state.is_premium = Boolean(telegramUser.is_premium);
}

export function incrementBalance(state: MutableState, increment: number) {
  state.balance += increment;
  // @todo: remove (POST SEASON 2 MIGRATION)
  // state.league = getLeague(state);
}

export function incrementScore(state: MutableState, increment: number) {
  // weekend promotion, remove this code after the promotion ends
  // todo: remove this code after the weekend of 10/25/2024
  let incrementMultiplier = 1;
  const promotionStartTime = 1729875600000; // 10/25/2024 10:00:00
  const promotionEndTime = 1730134800000; // 10/28/2024 10:00:00
  const now = Date.now();
  if (now >= promotionStartTime && now <= promotionEndTime) {
    incrementMultiplier = 2;
  }

  const incrementedScore = increment * incrementMultiplier;

  state.score = clampScore(state.score + incrementedScore);
  incrementBalance(state, incrementedScore);

  if (state.team_id) {
    state.unsynced_team_score += incrementedScore;
  }
}

export function incrementScoreAndTaps(state: MutableState, increment: number) {
  incrementScore(state, increment);
  state.taps += increment;
}

export function incrementTokenCreationCredits(
  state: MutableState,
  increment: number,
) {
  state.tokenCreationCredits += increment;
}

export function resetBuffs(state: MutableState, now: number) {
  state.free_rocketman_used = state.free_rocketman_used.filter((timestamp) =>
    isNotExpired(timestamp, rocketmanConfig.freeCooldown, now),
  );
  state.rocketman_used = state.rocketman_used.filter((timestamp) =>
    isNotExpired(timestamp, DAY_IN_MS, now),
  );
}

// export function consumeTaps(
//   state: MutableState,
//   taps: number,
//   api: ReplicantSyncActionAPI<ReplicantServer>,
// ) {
//   if (taps === 0) {
//     return;
//   }

//   const now = api.date.now();
//   resetBuffs(state, now);
//   refreshEnergy(state, now);

//   let energyUsed = getTaps(state) * taps;
//   if (state.energy < energyUsed) {
//     energyUsed = state.energy;
//     if (energyUsed <= 0) {
//       return;
//     }
//   }

//   // update score/balance/taps
//   incrementScoreAndTaps(state, energyUsed);

//   // get rocketman status
//   const { multiplier } = getRocketmanBonus(state, now);

//   // only use energy when there's no rocketman bonus
//   if (multiplier === 1) {
//     state.energy -= energyUsed;
//   }

//   state.session_taps += taps;

//   const currentLeague = getLeague(state);
//   const nextLeague = getLeague(state);
//   if (currentLeague !== nextLeague) {
//     const ownBonus = LEVEL_UP_BONUSES[nextLeague];

//     incrementScore(state, ownBonus);

//     if (state.referrer_id) {
//       // give bonus to referrer and notify them
//       const friendBonuses = state.is_premium
//         ? REFERRAL_LEVEL_UPS_PREMIUM
//         : REFERRAL_LEVEL_UPS_REGULAR;
//       const friendBonus = friendBonuses[nextLeague];

//       const referrerId = state.referrer_id;
//       api.postMessage.addReferrerScore(referrerId, {
//         bonus: friendBonus,
//       });

//       state.referrerContribution = state.referrerContribution + friendBonus;

//       api.sendAnalyticsEvents([
//         {
//           eventType: 'Bonus',
//           eventProperties: {
//             nature: 'ownProgression',
//             amount: ownBonus,
//           },
//         },
//         {
//           userId: referrerId,
//           eventType: 'Bonus',
//           eventProperties: {
//             nature: 'friendProgression',
//             amount: friendBonus,
//           },
//         },
//       ]);
//     }
//   }
// }

export const acquireBooster = (
  state: MutableState,
  // todo: what type is api parameter? thugmaster seems to have
  // todo: a whole ecosystem of defined types, computedProps, etc
  // todo: ReplicantSyncActionAPI<PartialReplicant<wholegamestateastype>>
  api: any,
  boosterProps: {
    booster: Booster;
    level: number;
    price: number;
  },
): void | ExpectedError => {
  const { booster, level, price } = boosterProps;

  try {
    const now = api.date.now();
    if (getBalance(state, now) < price) {
      const errorMessage = 'User does not have enough points to buy this item';
      api.sendAnalyticsEvents([
        {
          eventType: 'AcquireBoosterError',
          eventProperties: {
            booster,
            level,
          },
        },
      ]);
      return {
        expectedError: true,
        errorMessage,
      };
    }

    spendCoins(state, price, now);

    const purchaseUserProps: BoosterPurchaseUserProps = {};

    switch (booster) {
      case 'AutoTap':
        state.has_auto_tap = true;
        state.boosterPurchases.autoTaps += 1;
        purchaseUserProps.purchasedAutoTaps = state.boosterPurchases.autoTaps;
        break;
      case 'MultiTap':
        state.tap_level = level as number;
        state.boosterPurchases.multiTaps += 1;
        purchaseUserProps.purchasedMultiTaps = state.boosterPurchases.multiTaps;
        break;
      case 'RechargeLimit':
        state.energy_limit_level = level as number;
        state.boosterPurchases.rechargeLimits += 1;
        purchaseUserProps.purchasedMultiTaps =
          state.boosterPurchases.rechargeLimits;
        break;
      case 'RechargeSpeed':
        state.energy_recharge_level = level as number;
        state.boosterPurchases.rechargeSpeeds += 1;
        purchaseUserProps.purchasedMultiTaps =
          state.boosterPurchases.rechargeSpeeds;
        break;
    }

    // send analytics events
    api.sendAnalyticsEvents([
      {
        eventType: 'AcquireBooster',
        eventProperties: {
          booster,
          level,
        },
        userProperties: purchaseUserProps,
      },
    ]);
  } catch (e) {
    console.log(`Failed to acquire booster`, e);
    throw e;
  }
};

export function refreshEnergy(state: MutableState, now: number) {
  const elapsedTimeSinceLastRefresh = now - (state.last_energy_refresh || 0);
  const elapsedTimeInSeconds = Math.floor(elapsedTimeSinceLastRefresh / 1000);

  const energyPerSecond = getEnergyPerSecond(state);

  const energyToRefresh = elapsedTimeInSeconds * energyPerSecond;

  const energyLimit = getEnergyLimit(state);

  const energy = state.energy + energyToRefresh;

  // the energy update has to be precise or it can lead to subtle bugs
  if (energy >= energyLimit) {
    state.energy = energyLimit;
    state.last_energy_refresh = now;
  } else {
    if (energy !== state.energy) {
      // @important: only update if values changes
      // otherwise the value of last_energy_refresh could keep shifting forward in time with the energy never increasing
      state.energy = energy;
      state.last_energy_refresh = now;
    }
  }
}

export const handleFirstEntry = async (
  state: MutableState,
  payload: { referrer?: string; telegramUser: TelegramUser; tokenId?: string },
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
) => {
  const { referrer, telegramUser, tokenId } = payload;
  const isPremium = Boolean(telegramUser.is_premium);

  updateTelegramProps(state, telegramUser);

  state.first_interaction = false;

  const hasNeverHadZeroEnergy = state.last_energy_zero === 0;
  const hasTaps = state.taps > 0;
  const isNotFirstTimeUser = hasNeverHadZeroEnergy && hasTaps;
  if (isNotFirstTimeUser) {
    state.last_energy_zero = api.date.now();
  } else {
    incrementBalance(state, TIKTOK_INITIAL_BALANCE - initialScore);
  }

  state.badgeControl.lastUpdated = api.date.now();

  // only store this list during the first entry
  // it will be cleaned up after the onSessionEnds
  state.badgeControl.whitelisted = getWhitelistedBadgesIds();

  if (referrer) {
    await handleReferralEntry(
      state,
      {
        referrerId: referrer,
        isPremium,
        tokenId,
      },
      api,
    );
  }
};

export const handleReferralEntry = async (
  state: MutableState,
  args: {
    referrerId: string;
    isPremium?: boolean;
    teamId?: string;
    tokenId?: string;
  },
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>,
) => {
  if (state.referrer_id) {
    // player already has a referrer
    return;
  }

  const referrerId = args.referrerId;
  if (referrerId === api.getUserID()) {
    // making sure a user cannot refer themself
    return;
  }

  if (referrerId === RESERVED_REFERRAL_ID) {
    // ignore reserved referral id
    return;
  }

  // called by the bot when a player joins the game through a referral
  const now = api.date.now();
  const isPremium = args.isPremium || false;
  const referralBonus = getReferralBonus(state, now, isPremium);

  // create a player for the new user with initial score
  incrementScore(state, referralBonus);
  state.referrer_id = referrerId;
  state.referrerContribution += referralBonus;

  // create friendship between the 2 players
  addFriend(state, referrerId, api);

  // If the user is referred from a token
  if (args.tokenId) {
    tmgInitState(state, args.tokenId);
    state.trading.referrerTokenId = args.tokenId;
  }

  // send analytics events
  api.sendAnalyticsEvents([
    {
      eventType: 'Bonus',
      eventProperties: { nature: 'joinInvite', amount: referralBonus },
      userProperties: { referrerId, friendCount: 1 },
    },
    {
      userId: referrerId,
      eventType: 'Bonus',
      eventProperties: { nature: 'inviteSuccess', amount: referralBonus },
    },
  ]);

  api.scheduledActions.schedule.addFriend({
    targetUserId: referrerId,
    args: {
      friendId: state.id,
      friendName: state.username,
      bonus: referralBonus,
      referredTokenId: args.tokenId,
    },
    notificationId: `addFriend.${referrerId}.${state.id}`,
    delayInMS: 5 * SEC_IN_MS,
  });
};

export const addVideoQuestReward = (
  state: MutableState,
  { id }: { id?: string },
  api:
    | ReplicantEventHandlerAPI<ReplicantServer>
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantSyncActionAPI<ReplicantServer>,
) => {
  const video = state.watchedVideos.find((video) => video.id === id);
  if (
    !id ||
    !video ||
    video.claimed ||
    api.date.now() - video.timestamp < HOUR_IN_MS
  ) {
    return;
  }
  video.claimed = true;
  incrementScore(state, 100_000);
};

export const addEarningReward = (
  state: MutableState,
  { earningKey }: { earningKey: EarningKey },
): boolean | ExpectedError => {
  if (state.earnings[earningKey]) {
    return false;
  }

  const claimError = earningCondition[earningKey](state);

  if (claimError) {
    return {
      expectedError: true,
      errorMessage: claimError,
    };
  }

  incrementScore(state, earningRewards[earningKey]);
  state.earnings[earningKey] = true;
  return true;
};

export const claimPowerUpReward = (state: MutableState, now: number) => {
  const reward = getMiningRevenues(state, now, state.powerUps.last_claimed);

  if (reward <= 0) {
    return 0;
  }

  incrementScoreAndTaps(state, reward);
  state.powerUps.last_claimed = now;

  return reward;
};

export const spendCoins = (
  state: MutableState,
  amount: number,
  now: number,
) => {
  if (amount <= 0) {
    return;
  }
  // update balance and avoids the mining reward appearing too large on session start
  claimPowerUpReward(state, now);

  state.balance -= amount;
};

export const initForRequireMoreFriendsFromLast = (
  state: MutableState,
): void => {
  updateAllBasisForRequireMoreFriendsFromLast(state);
};

export const updateAllBasisForRequireMoreFriendsFromLast = (
  state: MutableState,
  opts?: { skipBaseLevelCheck?: boolean },
): void => {
  const powerUpIds = getPowerUpIdsWithConditionKey(
    'requireMoreFriendsFromLast',
  );
  updateBasisForRequireMoreFriendsFromLast(state, {
    powerUpIds,
    skipBaseLevelCheck: opts?.skipBaseLevelCheck,
  });
};

export const updateBasisForRequireMoreFriendsFromLast = (
  state: MutableState,
  {
    powerUpIds,
    skipBaseLevelCheck = false,
  }: { powerUpIds: string[]; skipBaseLevelCheck?: boolean },
): void => {
  for (const powerUpId of powerUpIds) {
    const myPowerUp = state.powerUps.owned[powerUpId];

    const myPowerUpLevel = myPowerUp?.level || 0;
    const baseLevel = state.powerUps.specialsFriendBasis[powerUpId]?.level;
    if (
      !skipBaseLevelCheck &&
      baseLevel !== undefined &&
      myPowerUpLevel === baseLevel
    ) {
      // already sync'd for this power up level, so no need to update
      continue;
    }

    state.powerUps.specialsFriendBasis[powerUpId] = {
      friendCount: state.friendCount,
      level: myPowerUp?.level || 0,
    };
  }
};

export const buyPowerUp: (
  state: MutableState,
  now: number,
  payload: { id: string; isFree?: boolean; findAvailGiftIfEnded?: boolean },
  api: ReplicantAsyncActionAPI<ReplicantServer>,
) => Promise<
  | { powerUpId: string; originalPowerUpId: string; isDaily: boolean }
  | ExpectedError
> = async (state, now, payload, api) => {
  const { id, isFree = false } = payload;
  // update balance and avoids the mining reward appearing too large on session start
  claimPowerUpReward(state, now);

  const powerUps = getPowerUps(state, now);

  let powerUp = powerUps.find((pu) => pu.id === id);
  let isDaily = false;
  const isGift = powerUp?.isGift;

  if (!powerUp) {
    throw new Error(`Could not find power up '${id}'`);
  }

  if (!isFree && powerUp.cost > state.balance) {
    return {
      expectedError: true,
      errorMessage: `Not enough funds.`,
    };
  }

  if (powerUp.maxLevel !== undefined && powerUp.level >= powerUp.maxLevel) {
    return {
      expectedError: true,
      errorMessage: `Max level reached.`,
    };
  }

  const isExpired = powerUp.expireTime && now >= powerUp.expireTime;
  if (isExpired && !isGift) {
    return {
      expectedError: true,
      errorMessage: `Expired.`,
    };
  }

  const isEnded = powerUp.endTime && now >= powerUp.endTime;
  const isNotOwned = !state.powerUps.owned[id];
  if (isEnded && isNotOwned && !isGift) {
    return {
      expectedError: true,
      errorMessage: `Ended.`,
    };
  }

  const isEndedOrExpired = isEnded || isExpired;
  if (payload.findAvailGiftIfEnded && isEndedOrExpired && isGift) {
    // find an available gift that is not ended yet
    // sort by endTime furthest out
    powerUp = powerUps
      .filter(
        (item) =>
          item.isGift === true &&
          item.endTime !== undefined &&
          now < item.endTime,
      )
      .sort((a, b) => (b.endTime ?? 0) - (a.endTime ?? 0))[0];
    if (!powerUp) {
      return {
        expectedError: true,
        errorMessage: `Could not find any non expired or ended power up.`,
      };
    }
  }

  if (getPowerUpAvailableTime(state, powerUp) > now) {
    return {
      expectedError: true,
      errorMessage: `Too early to upgrade power-up: ${powerUp.id}`,
    };
  }

  // @TODO: add safety checks for buying specials make sure they are available (or owned?)
  if (
    isDailyPowerUp(powerUp.id, now) &&
    !state.powerUps.daily.power_ups.includes(powerUp.id)
  ) {
    state.powerUps.daily.power_ups.push(id);
    const reward = getTotalDailyBonusReward(state);
    isDaily = true;
    incrementScore(state, reward);
  }

  if (!isFree) {
    spendCoins(state, powerUp.cost, now);
  }

  const isFirstPowerUp = Object.keys(state.powerUps.owned).length === 0;

  // Make sure we set last_claimed to now when purchasing the first card so we reset the elapsed time (that starts in 0)
  if (isFirstPowerUp) {
    state.powerUps.last_claimed = now;
  }

  const isGiftOnlyCard = powerUp.type === PowerUpCardType.GIFT_ONLY;
  if (!state.powerUps.owned[powerUp.id]) {
    state.powerUps.owned[powerUp.id] = {
      level: 0,
    };
  }

  const isSpecial = powerUp.specialState !== undefined;
  if (isSpecial) {
    state.powerUps.owned[powerUp.id].lastClaimed = now;

    const isHidden = powerUp.type === PowerUpCardType.HIDDEN;
    if (isHidden) {
      incrementScore(state, HIDDEN_CARD_REWARD);
    }
  }

  if (isGiftOnlyCard) {
    let currentTotalReceivedGifts =
      state.powerUps.owned[powerUp.id]?.giftsReceived || 0;
    currentTotalReceivedGifts += 1;
    state.powerUps.owned[powerUp.id].giftsReceived = currentTotalReceivedGifts;

    // increment level if user has accumulated enough gifts
    const giftsNeededForNextLevel = getGiftsNeededForNextLevel(powerUp);
    if (currentTotalReceivedGifts === giftsNeededForNextLevel) {
      // increment level
      powerUp.level += 1;
      state.powerUps.owned[powerUp.id].level += 1;
    }
  } else {
    // increment level
    powerUp.level += 1;
    state.powerUps.owned[powerUp.id].level = powerUp.level;
  }

  // only update basis if it's one of the friend-gated powerups
  const powerUpIdsWithRmffl = getPowerUpIdsWithConditionKey(
    'requireMoreFriendsFromLast',
  );
  if (powerUpIdsWithRmffl.includes(powerUp.id)) {
    // update basis for all powerUps so all the other powerUps will require more friends again
    updateBasisForRequireMoreFriendsFromLast(state, {
      powerUpIds: powerUpIdsWithRmffl,
      skipBaseLevelCheck: true,
    });
  }

  clearConditionDiscount(state, powerUp.id);

  return {
    originalPowerUpId: id,
    powerUpId: powerUp.id,
    isDaily,
  };
};

export const clearDailyConditions = (state: MutableState) => {
  const giftDailyWithDiscountMap =
    state.powerUps.conditions.gift_daily_with_discount;
  Object.keys(giftDailyWithDiscountMap).forEach((key) => {
    giftDailyWithDiscountMap[key].dailyGifts = [];
  });

  const giftOnlyMap = state.powerUps.conditions.gift_only;
  Object.keys(giftOnlyMap).forEach((key) => {
    giftOnlyMap[key].dailyGifts = [];
  });
};

export const clearOutdatedUserMemeGifts = (
  state: MutableState,
  now: number,
) => {
  // note that the feature should work without cleanup, but we need to keep the state size small

  const userMemeGiftsClaimed = state.trading.userMemeGiftsClaimed;
  Object.keys(userMemeGiftsClaimed).forEach((giftId) => {
    const giftShareTime = userMemeGiftsClaimed[giftId];
    if (giftShareTime + DAY_IN_MS < now) {
      delete state.trading.userMemeGiftsClaimed[giftId];
    }
  });

  const userMemeGiftsSent = state.trading.userMemeGiftsSent;
  Object.keys(userMemeGiftsSent).forEach((giftId) => {
    const giftShareTime = userMemeGiftsSent[giftId].shareTime;
    if (giftShareTime + DAY_IN_MS < now) {
      delete state.trading.userMemeGiftsSent[giftId];
    }
  });
};

export const clearConditionDiscount = (state: MutableState, puId: string) => {
  try {
    state.powerUps.conditions.gift_daily_with_discount[puId].discountList = [];
  } catch {
    // fail silently
  }
};

const tutorialStateMutators: Record<
  string,
  (state: MutableState, opts?: { now: number; test?: boolean }) => void
> = {
  rechargeEnergy: (state) => {
    state.energy = getBaseEnergy(state);
  },
  rechargeRocketman: (state) => {
    state.free_rocketman_used = [];
  },
  startEnergy: (state, opts) => {
    state.energy = 75;
    if (opts?.now) {
      state.last_energy_refresh = opts?.now;
    }
    if (opts?.test) {
      incrementBalance(state, 500);
      state.powerUps.owned = {};
    }
  },
  '1000FreeGift': (state) => {
    if (state.balance <= 3_000) {
      incrementBalance(state, 1_000);
    }
  },
  '1500FreeGift': (state) => {
    if (state.balance <= 4_000) {
      incrementBalance(state, 1_500);
    }
  },
  '100FreeGift': (state) => {
    incrementBalance(state, 100);
  },
  '50Energy': (state) => {
    if (state.energy > 50) {
      state.energy = 50;
    }
  },
};

export const handleTutorialUpdate = (
  state: MutableState,
  {
    tutorialId,
    stepId,
    stepIndex,
    test,
  }: { tutorialId: string; stepId?: string; stepIndex: number; test: boolean },
  now: number,
) => {
  if (stepId) {
    tutorialStateMutators[stepId] &&
      tutorialStateMutators[stepId](state, { now, test });
  }
  state.tutorials[tutorialId] = stepIndex;
};

export const resetDailyCode = (state: MutableState, now: number) => {
  if (isNextDay(state.dailyCode.timestamp, now, 'pst')) {
    state.dailyCode = {
      timestamp: now,
      complete: false,
    };
  }
};

export const handleDailyRewards = (
  state: MutableState,
  now: number,
  api: ReplicantSyncActionAPI<ReplicantServer>,
) => {
  // @todo: remove (POST SEASON 2 MIGRATION)
  // more than 1d, less than 2
  const hadGrantedRewardBetweenDayOrTwo = getIsbetween1And2Days(
    now,
    state.last_reward_granted,
  );
  // first day after broken streak
  const isFirstConsecutiveDay =
    !state.streak_days && getIsAfter1Day(now, state.last_reward_granted);
  // grant reward if any of the conditions are met
  // any first day user get 500, for the streak rewards are growing
  const grantStreakReward =
    hadGrantedRewardBetweenDayOrTwo || isFirstConsecutiveDay;
  if (grantStreakReward) {
    state.consecutive_days = +1;
    state.last_reward_granted = now;
    // user is on second loop of his streak
    if (state.streak_days === MAX_DAILY_REWARD_STREAK) {
      if (state.unclaimed_rewards) {
        // if he has unclaimed rewards keep him on the same streak
        state.streak_days = Math.min(
          state.streak_days + 1,
          MAX_DAILY_REWARD_STREAK,
        );
      } else {
        state.streak_days = 1;
        state.unclaimed_rewards = 1;
      }
    } else {
      state.streak_days = Math.min(
        state.streak_days + 1,
        MAX_DAILY_REWARD_STREAK,
      );
      state.unclaimed_rewards += 1;
    }
  } else if (getIsAfter2Days(now, state.last_reward_granted)) {
    // user was not granted reward for 48h, broken streak
    // logic is a bit complicated here: grant reward once per 24h
    // means that in fact user can login every 47h 59min and still get the reward
    state.streak_days = 1;
    state.last_reward_granted = now;
    state.unclaimed_rewards = 1;
    api.sendAnalyticsEvents([
      {
        eventType: 'reward_forfeited',
        eventProperties: {
          // use +1 here becase the consecutive_days is 0-indexed
          max_day_returned: state.consecutive_days + 1,
        },
      },
    ]);
    state.consecutive_days = 1;
  }
};

export const updateQuest = (
  state: MutableState,
  now: number,
  { questId }: { questId: string },
) => {
  if (!state.quests[questId]) {
    state.quests[questId] = { state: 'default' };
  }

  if (state.quests[questId].state === 'complete') {
    return successResponse(state.quests[questId].state);
  }

  const quest = getQuest(questId, now);
  if (!quest) {
    return;
  }

  const response = checkQuest(state, now, {
    id: quest.id,
    category: quest.category,
    actionCTA: quest.actionCTA,
  });

  if (response.error) {
    const isWaitingForQuest =
      response.code === ErrorCode.START_WAITING_FOR_QUEST;
    const isStateNotWaiting = state.quests[questId].state != 'waiting';

    if (isWaitingForQuest && isStateNotWaiting) {
      state.quests[questId] = {
        state: 'waiting',
        firstCheckAt: now,
      };
    }

    return response;
  } else if (response.data) {
    incrementScore(state, quest.reward);
    state.quests[questId].state = 'complete';

    // @TODO: This fixes the mine quest which requires earning. This should be a temp solution;
    // Mine card system has to be updated to work with latest quest system
    if (questId === 'follow_x') {
      state.earnings.followOnX = true;
    }
  }
  return successResponse(state.quests[questId].state);
};

export const syncTgStarsTxAndCredits = async (
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) => {
  const txs = await api.purchases.getPurchaseHistory();
  const nonConsumedTxs = txs.filter((tx) => !tx.is_consumed);
  let anySync = false;
  for (const tx of nonConsumedTxs) {
    try {
      await api.purchases.consumePurchase({ productId: tx.product_id });
      state.tokenCreationCredits++;
      anySync = true;
    } catch {
      // What can we even do?
      return false;
    }
  }
  return anySync;
};

// @todo: remove (POST SEASON 2 MIGRATION)
// export const grantFreeTokenMemeGift = async (
//   state: MutableState,
//   api:
//     | ReplicantAsyncActionAPI<ReplicantServer>
//     | ReplicantEventHandlerAPI<ReplicantServer>,
//   tokenId: string,
// ) => {
//   const currencyAmountBig = highPrecision(giftTokenCoinAmount);

//   const offchainTokenId = tokenId;

//   const offchainTokenState = await api.sharedStates.tradingMeme.fetch(
//     offchainTokenId,
//   );
//   const offchainToken = offchainTokenState?.global;
//   if (!offchainToken) {
//     throw new Error(
//       `Attempting to buy offchainToken that doesn't exist '${offchainTokenId}'.`,
//     );
//   }

//   const timestamp = getActualTime(api);

//   api.sharedStates.tradingMeme.postMessage.awardMemeTokenGift2(
//     offchainTokenId,
//     {
//       timestamp,
//       buyerId: state.id,
//       buyerName: state.profile.name,
//       buyerImage: state.profile.photo,
//       msgVersion: offchainTokenMsgVersion,
//       expectedTxIdx: offchainToken.txs.length,
//     },
//   );

//   const txConfirmation = await confirmTransaction(
//     api as ReplicantAsyncActionAPI<ReplicantServer>,
//     state.id,
//     offchainTokenId,
//     timestamp,
//   );

//   if (!txConfirmation) {
//     throw new Error(`Failed to award meme token gift`);
//   }

//   const transaction = txConfirmation.transaction;

//   const currencyInvested = currencyAmountBig.round();

//   const supply = offchainToken.supply;
//   const txPointAmount = transaction.pointAmount;
//   const lastNotifPrice = getCurvePrice(highPrecision(supply).add(txPointAmount));

//   const pointUpdate = {
//     pointAmount: txPointAmount,
//     pointsAccumulated: txPointAmount,
//     currencyInvested: currencyInvested.toString(),
//     lastNotifPrice: lastNotifPrice.toString(),
//   };

//   updatePointHolding(state, offchainTokenId, pointUpdate);

//   console.warn('handleFreeTokenMemeGift', { pointUpdate });
// };

// @todo: remove (POST SEASON 2 MIGRATION)
// export const handleFreeTokenMemeGift = async (
//   state: MutableState,
//   api:
//     | ReplicantAsyncActionAPI<ReplicantServer>
//     | ReplicantEventHandlerAPI<ReplicantServer>,
//   useCheat?: 'useCheat',
// ) => {
//   const isEligible = isEligibeForFreeMemeToken(state, api.date.now());

//   if (!isEligible && !useCheat) {
//     console.warn('User is not eligible');
//     return;
//   }

//   const messageTemplateKey = `giftMemeToken` as NotificationKey;

//   const tokens = await api.asyncGetters.searchOffchainTokens({
//     searchString: undefined,
//     field: 'availableAt',
//     limit: 50,
//     randomSort: true,
//   });

//   const randomToken = tokens[Math.floor(Math.random() * tokens.length)];

//   if (!randomToken) {
//     return;
//   }

//   const mediaUrl = randomToken.profile.image;

//   sendTelegramMessage(state, api, {
//     chatId: state.id,
//     message: templates[messageTemplateKey]({
//       args: {
//         userId: state.id,
//         mediaUrl,
//         tokens: {
//           tokenName: randomToken.profile.name,
//         },
//       },
//       payload: {
//         $channel: 'CHATBOT',
//         feature: 'memes',
//         $subFeature: 'gift_token',
//         creativeAssetID: extractCreativeAssetID(mediaUrl),
//       },
//     }),
//     receiverId: state.id,
//     priority: 'high' as const,
//     maxDelayMs: maxNotifDelays.giftMemeToken,
//   });

//   try {
//     await retry(() => grantFreeTokenMemeGift(state, api, randomToken.id), {
//       attempts: 5,
//     });
//   } catch (error) {
//     return errorResponse('Failed to award meme token gift. Please try again', {
//       code: ErrorCode.OFFCHAIN_TOKEN_GIFT_FAILED,
//     });
//   }

//   state.trading.giftTokenId = randomToken.id;

//   state.labels.push(ModalLabels.SHOW_MEME_TOKEN_GIFT_MODAL);
// };

/**
 * This should take care of discrepancies between the status of the offchain tokens in the db and opensearch which happens due to race condition between the app and the moderation lambda
 * @param state
 * @param api
 */
export const syncModerationStatus = async (
  state: MutableState,
  api: ReplicantAsyncActionAPI<ReplicantServer>,
) => {
  if (
    state.trading.lastTokenCreatedTimestamp ==
    state.trading.lastTokenCreatedOSSync
  ) {
    return;
  }

  const memeIds = getMyOffchainMemeIds(state);

  const tokenStatesInOpensearch: TradingSearchResult[] =
    await api.asyncGetters.getMemesFromOpenSearch({
      memeIds,
      status: [TradingMemeStatus.Created],
    });

  const tokenStatesInOSWithCreatedStatus = tokenStatesInOpensearch.reduce(
    (acc, tokenState) => {
      acc[tokenState.id] = tokenState;
      return acc;
    },
    {} as Record<string, TradingSearchResult>,
  );

  const tokenFetchPromises = Object.keys(tokenStatesInOSWithCreatedStatus).map(
    async (memeId) => {
      const memeStatus = await api.sharedStates.tradingMeme.fetch(memeId);
      return { memeId, memeStatus };
    },
  );
  const tokenFetchResults = await Promise.all(tokenFetchPromises);
  const tokenStatesInDb = tokenFetchResults.reduce(
    (acc, { memeId, memeStatus }) => {
      if (memeStatus) {
        acc[memeId] = memeStatus.global;
      }
      return acc;
    },
    {} as Record<string, TradingState>,
  );

  // compare status from db and opensearch
  const tokensToSync = memeIds.filter((memeId) => {
    const dbStatus = tokenStatesInDb[memeId]?.status;
    return dbStatus === TradingMemeStatus.Moderated;
  });

  // sync status
  tokensToSync.forEach((memeId) => {
    const imageUrlInDB = tokenStatesInDb[memeId]?.details.image;
    api.sharedStates.tradingMeme.postMessage.updateStatus(memeId, {
      status: TradingMemeStatus.ModeratedOS, // set status to ModeratedOS do db update can trigger opensearch update
      image: imageUrlInDB,
    });
  });

  state.trading.lastTokenCreatedOSSync =
    state.trading.lastTokenCreatedTimestamp;
};

export const convertGift = async (
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  tokenId: string,
  coinAmount: number,
) => {
  const currencyAmountBig = HP(coinAmount);

  const offchainTokenId = tokenId;

  const offchainTokenState = await api.sharedStates.tradingMeme.fetch(
    offchainTokenId,
  );
  const offchainToken = offchainTokenState?.global;
  if (!offchainToken) {
    throw new Error(
      `Attempting to buy offchainToken that doesn't exist '${offchainTokenId}'.`,
    );
  }

  const timestamp = getActualTime(api);

  api.sharedStates.tradingMeme.postMessage.awardPointAmount(offchainTokenId, {
    timestamp,
    buyerId: state.id,
    buyerName: state.profile.name,
    buyerImage: state.profile.photo,
    expectedTxIdx: offchainToken.offchainTxs.length,
    coinAmount: coinAmount.toString(),
  });

  const txConfirmation = await confirmTransaction(
    api as ReplicantAsyncActionAPI<ReplicantServer>,
    state.id,
    offchainTokenId,
    timestamp,
  );

  if (!txConfirmation) {
    throw new Error(`Failed to award meme token gift`);
  }

  const transaction = txConfirmation.transaction;

  const currencyInvested = currencyAmountBig.round();

  const txPointAmount = transaction.pointAmount;

  const isGraduated = isMemeGraduated(offchainTokenState.global);

  const pointUpdate = {
    timestamp,
    dailyPoints: isGraduated ? txPointAmount : undefined,
    pointAmount: txPointAmount,
    pointsAccumulated: txPointAmount,
    currencyInvested: currencyInvested.toString(),
    lastNotifPrice: getTokenPrice(offchainToken),
  };

  updatePointHolding(state, tokenId, pointUpdate);

  return pointUpdate;
};

export const giveFreeToken = async (
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
  tokenId: string,
  coinAmount: number,
) => {
  try {
    return await retry(() => convertGift(state, api, tokenId, coinAmount), {
      attempts: 5,
    });
  } catch (error) {
    console.error('Failed to award meme token gift. Please try again', {
      code: ErrorCode.OFFCHAIN_TOKEN_GIFT_FAILED,
    });
  }
};
