import { assets } from '../../../../assets/assets';
import {
  CurrencyType,
  CurrencyFlow,
  TxType,
} from '../../../../replicant/features/tradingMeme/types';
import {
  formatPrice,
  displayPointAmount,
} from '../../../../replicant/utils/numbers';

export function getCurrencyName(
  currencyType: CurrencyType,
  memeTicker: string,
  txType: TxType,
  currencyFlow: CurrencyFlow = 'send',
  isUsdPreferred: boolean = false,
) {
  const isBuying = txType === 'buy';
  const isPointsTransaction = currencyType === 'points';
  const isCurrencyFlowSending = currencyFlow === 'send';

  const coinName = 'Coins';
  const tonName = isUsdPreferred ? 'USD' : 'TON';

  // const memePointsName = '$' + meme?.ticker + ' POINTS' || '';
  const memePointsName = 'POINTS'; // note that both ticker+points label broke layout
  const memeTokenName = '$' + memeTicker;

  let name = '';

  if (isBuying) {
    if (isPointsTransaction) {
      name = isCurrencyFlowSending ? coinName : memePointsName;
    } else {
      name = isCurrencyFlowSending ? tonName : memeTokenName;
    }
  } else {
    if (isPointsTransaction) {
      name = isCurrencyFlowSending ? memePointsName : coinName;
    } else {
      name = isCurrencyFlowSending ? memeTokenName : tonName;
    }
  }

  return name;
}

export function getCurrencyIcon(
  currencyType: CurrencyType,
  txMemeImage: string,
  txType?: TxType,
  currencyFlow: CurrencyFlow = 'send',
  isUsdPreferred: boolean = false,
) {
  const isBuying = txType === 'buy';
  const isPointsTransaction = currencyType === 'points';
  const isCurrencyFlowSending = currencyFlow === 'send';

  const coinIcon = assets.coin;
  const usdIcon = '';
  const tonIcon = isUsdPreferred ? usdIcon : assets.icon_ton;
  const memeIcon = txMemeImage;

  let image = '';

  if (isBuying) {
    if (isPointsTransaction) {
      image = isCurrencyFlowSending ? coinIcon : memeIcon;
    } else {
      image = isCurrencyFlowSending ? tonIcon : memeIcon;
    }
  } else {
    if (isPointsTransaction) {
      image = isCurrencyFlowSending ? memeIcon : coinIcon;
    } else {
      image = isCurrencyFlowSending ? memeIcon : tonIcon;
    }
  }

  return image;
}

export function getFormattedTxAmount(
  amount: number,
  txType: TxType,
  currencyType: CurrencyType,
  currencyFlow: CurrencyFlow,
) {
  const isBuying = txType === 'buy';
  const isPointsTransaction = currencyType === 'points';
  const isCurrencyFlowSending = currencyFlow === 'send';

  if (isCurrencyFlowSending) {
    const sendAmount = isBuying
      ? formatPrice(Number(amount))
      : isPointsTransaction
      ? displayPointAmount(Number(amount), 8, true, true)
      : formatPrice(Number(amount));
    return sendAmount;
  }

  const receiveAmount = isBuying
    ? isPointsTransaction
      ? displayPointAmount(Number(amount), 8, true, true)
      : formatPrice(Number(amount))
    : isPointsTransaction
    ? formatPrice(Math.round(Number(amount)))
    : formatPrice(Number(amount));

  return receiveAmount;
}
