import { createAsyncGetters } from '../../createAsyncGetters';
import {
  getOnchainTxsPageForNewTx,
  getOnchainTxsStateId,
} from './onchainTxs.getters';
import { ContractType } from './onchainTxs.schema';

export const onchainTxsAsyncGetters = createAsyncGetters({
  // getOnchainTxsPageForNewTx: async (
  //   { memeId, contractType }: { memeId: string; contractType: ContractType },
  //   api,
  // ) => {
  //   const pageCount = (await getOnchainTxsPageForNewTx(
  //     api,
  //     memeId,
  //     contractType,
  //   )) as number;
  //   return pageCount;
  // },
  // getLastRecordedTxHash: async (
  //   { memeId, contractType }: { memeId: string; contractType: ContractType },
  //   api,
  // ) => {
  //   const pageCount = (await getOnchainTxsPageCount(
  //     api,
  //     memeId,
  //     contractType,
  //   )) as number;
  //   if (pageCount === 0) {
  //     return;
  //   }
  //   const lastPageId = getOnchainTxsStateId(memeId, contractType, pageCount - 1);
  //   const lastPage = await api.sharedStates.onchainTxs.fetch(lastPageId);
  //   if (!lastPage) {
  //     throw new Error(
  //       `Could not find last page of records for ${memeId} on ${contractType}`,
  //     );
  //   }
  //   const txHashes = lastPage.global.txHashes;
  //   return txHashes[txHashes.length - 1];
  // },
});
