import './TradingCreatePage.scss';
import { useTranslation } from 'react-i18next';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { FormEvent, useEffect } from 'react';
import { TradingCreateInput } from './TradingCreateInput/TradingCreateInput';
import { Page } from '../Page';
import { FormEvents } from '../../../data/Form';

export const TradingEditLinksPage = () => {
  const { t } = useTranslation();
  const { visible } = app.views.TradingEditLinksPage;

  useAppUpdates({
    id: 'TradingEditLinksPage',
    listener: app.views.TradingEditLinksPage.attachEventListener(),
  });
  useAppUpdates({
    id: 'TradingEditLinksPage/FormEvents.OnUpdate',
    listener: app.memes.factory.updateLinksForm.attachEventListener(
      FormEvents.OnUpdate,
    ),
  });

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    // react will reload the page for some reason when submitting a form
    // this will prevent it to happen
    event.preventDefault();

    // close the keyboard
    // inputRef.current?.blur();
  };

  useEffect(() => {
    if (!visible) return;

    // pre-fill link inputs with current token values
    const myToken = app.memes.currentMeme.meme;

    app.memes.factory.updateLinksForm.setField(
      'twitterLink',
      myToken?.description.twitterLink || '', // https://x.com
    );

    app.memes.factory.updateLinksForm.setField(
      'telegramChannelLink',
      myToken?.description.telegramChannelLink || '', // 'https://t.me/'
    );

    app.memes.factory.updateLinksForm.setField(
      'telegramChatLink',
      myToken?.description.telegramChatLink || '', // https://t.me/joinchat
    );

    app.memes.factory.updateLinksForm.setField(
      'websiteLink',
      myToken?.description.websiteLink || '', // https://website.fun
    );
  }, [visible]);

  const onInputChange =
    (key: (typeof app.memes.factory.updateLinksForm.fields)[0]['key']) =>
    (value: string) => {
      app.memes.factory.updateLinksForm.setField(key, value);
    };

  const onUpdateLinks = async () => {
    if (!app.memes.factory.updateLinksForm.isValid) {
      return;
    }
    app.memes.factory.submitFormUpdateLinks();

    app.ui.drawer.show({
      id: 'generic',
      hideClose: true,
      opts: {
        title: app.memes.currentMeme.meme?.name,
        subtitle: t('trading_update_links_success'),
        buttons: [
          {
            cta: 'Great!',
            onClick: async () => {
              app.ui.drawer.close();
            },
          },
        ],
      },
    });
  };

  if (!visible) {
    return null;
  }

  return (
    <Page id="trading-create" visible={visible}>
      <div className="trading-create-layout">
        <div className="trading-create-header">
          {/* header title / question */}
          <div className="trading-create-title">
            <div className="label">{t('create_token_links_title')}</div>
          </div>

          {/* form with several inputs */}
          <form onSubmit={onFormSubmit}>
            {app.memes.factory.updateLinksForm.fields.map((cfg, index) => {
              return (
                <TradingCreateInput
                  key={`${cfg.key}_${index}`}
                  id={cfg.key}
                  title={`${t(cfg.title)} - ${cfg.example}`}
                  placeholder={cfg.placeholder ? t(cfg.placeholder) : ''}
                  value={app.memes.factory.updateLinksForm.data[cfg.key]}
                  onChange={onInputChange(cfg.key)}
                  isValid={app.memes.factory.updateLinksForm.getIsFieldValid(
                    cfg.key,
                  )}
                  required={cfg.required}
                />
              );
            })}
          </form>
        </div>

        <div
          className={`btn btn-normal button-continue editlinks ${
            !app.memes.factory.updateLinksForm.isValid && 'disabled'
          }`}
          onClick={onUpdateLinks}
        >
          {t('trading_update_links_cta')}
        </div>
      </div>
    </Page>
  );
};
