import { assets } from '../../../assets/assets';
import { Tutorials } from '../../../data/tutorial/types';
import { SlideshowItem } from './Slideshow';

export const slideshows = {
  [Tutorials.SlideshowTutorial]: {
    items: [
      {
        id: '1',
        title: 'tut_slide_1_title', // 'Tap to earn coins',
        subtitle: 'tut_slide_1_subtitle', // 'You can use boosters and tricky strategies',
        icon: assets.sloth_hacker,
        // image: assets.sl_clicker,
        telemetry: 'FTUE_slide_tapcoin',
      },
      {
        id: '2',
        title: 'tut_slide_2_title', // 'Get to the top league',
        subtitle: 'tut_slide_2_subtitle', // 'Go all the way to become the best player',
        icon: assets.sloth_createteam,
        // image: assets.sl_league,
        telemetry: 'FTUE_slide_league',
      },
      {
        id: '3',
        title: 'tut_slide_3_title', // 'Upgrade your income',
        subtitle: 'tut_slide_3_subtitle', // 'Level up your cards, increase your coins per hour',
        icon: assets.sloth_rocket,
        // image: assets.sl_mine,
        telemetry: 'FTUE_slide_mine',
      },
      {
        id: '4',
        title: 'tut_slide_4_title', // 'Invite friends and get bonuses together',
        subtitle: 'tut_slide_4_subtitle', // 'You and your friend will both receive coins',
        icon: assets.sloth_teams,
        // image: assets.sl_friends,
        telemetry: 'FTUE_slide_invite',
      },
      {
        id: '5',
        title: 'tut_slide_5_title', // 'Subscribe to our community and socialize',
        subtitle: '', // 'Subscribe to our community and socialize',
        icon: assets.sloth_outofenergy,
        // image: assets.sl_community,
        telemetry: 'FTUE_slide_subscribe',
      },
      {
        id: '6',
        title: 'tut_slide_6_title', // 'Use coins to get special rewards',
        subtitle: 'tut_slide_6_subtitle', // `Don't forget to invite your friends. Good luck!`,
        icon: assets.sloth_earning_summary,
        // image: assets.sl_rewards,
        telemetry: 'FTUE_slide_special',
      },
    ] as SlideshowItem[],
  },

  // todo: Deprecated on season 3. Remove?
  // [Tutorials.SlideshowTradingCreateToken]: {
  //   items: [
  //     {
  //       id: '1',
  //       title: 'tut_trading_slide_1_title',
  //       subtitle: 'tut_trading_slide_1_subtitle',
  //       icon: assets.sloth_hacker,
  //       image: assets.slideshowTrading_step1,
  //       telemetry: 'FTUE_slide_tapcoin',
  //     },
  //     {
  //       id: '2',
  //       title: 'tut_trading_slide_2_title',
  //       subtitle: 'tut_trading_slide_2_subtitle',
  //       icon: assets.sloth_createteam,
  //       image: assets.slideshowTrading_step2,
  //       telemetry: 'FTUE_slide_league',
  //     },
  //     {
  //       id: '3',
  //       title: 'tut_trading_slide_3_title',
  //       subtitle: 'tut_trading_slide_3_subtitle',
  //       icon: assets.sloth_rocket,
  //       image: assets.slideshowTrading_step3,
  //       telemetry: 'FTUE_slide_mine',
  //     },
  //   ] as SlideshowItem[],
  // },

  // @todo: remove (POST SEASON 2 MIGRATION
  // [Tutorials.SlideshowSeasonKickOff]: {
  //   items: [
  //     {
  //       id: '1',
  //       title: 'tut_season_kickoff_slide_1', // Welcome back!
  //       subtitle: 'tut_season_kickoff_slide_1_subtitle',
  //       icon: assets.sloth_earn,
  //       image: assets.slideshowSeason2_step1,
  //       telemetry: 'welcome_back_step1',
  //     },
  //     {
  //       id: '2',
  //       title: 'tut_season_kickoff_slide_2_title', // New and Improved!
  //       subtitle: 'tut_season_kickoff_slide_2_subtitle',
  //       icon: assets.sloth_earn,
  //       image: assets.slideshowSeason2_step2,
  //       telemetry: 'welcome_back_step2',
  //     },
  //     {
  //       id: '3',
  //       title: 'tut_season_kickoff_slide_3_title', // Thanks for playing Season 1
  //       subtitle: 'tut_season_kickoff_slide_3_subtitle',
  //       icon: assets.sloth_earn,
  //       image: assets.slideshowSeason2_step3,
  //       telemetry: 'welcome_back_step3',
  //       cta: 'tut_season_claim_reward',
  //     },
  //   ] as SlideshowItem[],
  // },
};

export type Slideshow = keyof typeof slideshows;
