import './DrawerTradingTransaction.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { displayPointAmount } from '../../../../replicant/utils/numbers';
import { HP } from '../../../../replicant/lib/HighPrecision';

export const DrawerTradingTransactionProcessing = () => {
  const { t } = useTranslation();

  // get offchainToken
  const token = app.ui.drawer.view.data?.props?.meme;

  const onTapShareButton = async () => {
    if (!token) return;
    // share - sendGift
    await app.memes.sendGift(token, 'ftue_share_gate');
    app.invoke.grantFtueShareReward({ memeId: token.id || '' });
    setTimeout(() => app.ui.drawer.close(), 1000);
  };

  // const onShare = () => {
  // app.memes.shareOffchainToken('transaction_processing_drawer', props);
  // };

  return (
    <div className="drawer-trading-transaction">
      <div className="drawer-trading-transaction-header">
        <img
          className="drawer-trading-transaction-icon pulse"
          src={assets.energy_yellow}
        />

        <div className="drawer-trading-transaction-title">
          {t('trading_transaction_processing_title')}
        </div>
        <div className="drawer-trading-transaction-subtitle">
          {t('trading_transaction_processing_subtitle')}
        </div>
      </div>

      <div className="footer-buttons">
        {/* share */}
        <div
          className={`btn btn-normal`}
          onClick={() => {
            app.ui.confetti.hide();
            app.ui.drawer.close();
            onTapShareButton();
          }}
        >
          {t('generic_share')}
        </div>
      </div>
    </div>
  );
};
