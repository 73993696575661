import './CreatorImage.scss';
import { assets } from '../../../assets/assets';
import { useEffect, useRef, useState } from 'react';
import { app } from '../../../data/Controllers/AppController';
import { getUserStartingSeason } from '../../../replicant/features/game/game.getters';

interface CreatorImageProps {
  src: string;
  size: number;
}

export const CreatorImage = ({ src, size }: CreatorImageProps) => {
  const startingSeason = getUserStartingSeason(app.state);
  const startingSeasonClass = `s${startingSeason}`;
  // console.warn('>>> startingSeasonClass', startingSeasonClass);

  const imgRef = useRef<any>();
  const [verticalAspectRatio, setVerticalAspectRatio] = useState(false);

  useEffect(() => {
    if (src) {
      if (imgRef.current) {
        const isVertical =
          imgRef.current.naturalWidth < imgRef.current.naturalHeight;
        setVerticalAspectRatio(isVertical);
      }
    }
  }, [src]);

  return (
    <div
      className={`creator-image ${startingSeasonClass}`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <img
        ref={imgRef}
        src={src || assets.telegram_star}
        style={{
          width: verticalAspectRatio ? '100%' : 'unset',
          height: verticalAspectRatio ? 'unset' : '100%',
        }}
      />
    </div>
  );
};
