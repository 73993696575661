class TimeLog {
  private timeLogs: { time: number; action: string }[] = [];

  private logs: Record<string, number> = {};

  constructor() {}

  start = (id: string, msg?: any) => {
    if (this.logs[id]) {
      console.error(
        `id '${id}' already in use. Did you forget to call end(id)?`,
      );
      return;
    }
    this.logs[id] = Date.now();
    if (msg) {
      console.log(`%c Start ${id}`, `color: #03fc7f`, msg);
    } else {
      console.log(`%c Start ${id}`, `color: #03fc7f`);
    }
  };

  end = (id: string, msg?: any) => {
    if (!this.logs[id]) {
      console.error(
        `id '${id}' does not exist. Did you forget to call start(id)?`,
      );
      return;
    }
    const diff = Date.now() - this.logs[id];
    const secs = diff / 1000;
    if (msg) {
      console.warn(
        `%c Action '${id}' completed in: ${secs} seconds.`,
        `color: #03fc1c`,
        msg,
      );
    } else {
      console.warn(
        `%c Action '${id}' completed in: ${secs} seconds.`,
        `color: #03fc1c`,
      );
    }
  };
}

export const timeLog = new TimeLog();
