import './FeedMemeSidebar.scss';
import { t } from 'i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { TransactionSuccess } from '../../../../data/Controllers/UIController/UITypes';
import { resolveProfilePicture } from '../../../../replicant/features/game/game.getters';
import { TradingTokenListing } from '../../../../replicant/features/tradingMeme/types';
import { CreatorImage } from '../../../shared/CreatorImage/CreatorImage';
// import { tests } from '../../../../replicant/ruleset';

interface SidebarUIProps {
  token: TradingTokenListing;
}

export const SidebarUI = ({ token }: SidebarUIProps) => {
  const { offchainTokenId, shares } = token;

  const onTapAuthor = () => {
    app.nav.goToProfile(token.creatorId);
  };

  const { currentMeme } = app.memes;

  const onTapShare = () => {
    if (!currentMeme.meme || currentMeme.meme.id !== offchainTokenId) {
      return;
    }

    const { name } = currentMeme.meme;

    app.memes.shareOffchainToken(
      'feed_sidebar',
      {
        memeId: offchainTokenId,
        memeName: t('tiktokpage_share_title', { memeTitle: name }),
        memeDescription: t('tiktokpage_share_body'),
      } as TransactionSuccess,
      'isTiktok',
    );
  };

  // const onTapGift = async () => {
  //   if (!currentMeme.token || currentMeme.token.id !== offchainTokenId) {
  //     return;
  //   }

  //   await app.memes.sendGift(currentMeme.token, 'sidebar_gift');
  // };

  // const ruleset = app.replicant?.state.ruleset;
  // const bucketId = ruleset.abTests[tests.TEST_SIDEBAR_GIFT]?.bucketId;
  // const hasSidebarGifting = bucketId === 'enabled';

  return (
    <div className="sidebar">
      {/* share */}
      <div className="sidebar-item share" onClick={onTapShare}>
        <div className="sidebar-image">
          <img src={assets.icon_sidebar_share} />
          <div className="label">{shares}</div>
        </div>
      </div>

      {/* chat */}
      {/* <div className="sidebar-item chat" onClick={onTapChat}>
        <div className="sidebar-image">
          <img src={assets.icon_sidebar_chat} />
        </div>
        <div className="label">{0}</div>
      </div> */}

      {/* gift */}
      {/* {hasSidebarGifting && (
        <div className="sidebar-item gift" onClick={onTapGift}>
          <div className="sidebar-image">
            <img src={assets.gift_white_icon} />
            <div className="label">{t('sidebar_gift')}</div>
          </div>
        </div>
      )} */}

      {/* author */}
      <div className="sidebar-item author" onClick={onTapAuthor}>
        <CreatorImage
          src={resolveProfilePicture(token.creatorId, token.creatorImage)}
          size={36}
        />
        {/* <div className="label">{token.creatorName}</div> */}
      </div>
    </div>
  );
};
