import { useTranslation } from 'react-i18next';
import { app } from '../../../../../data/Controllers/AppController';
import { getPct } from '../../../../../data/utils';
import { CurrencyType } from '../../../../../replicant/features/tradingMeme/types';

// note: this is used also from DrawerTradingTransactionConfirm
export const TradingTokenFooterFeeMessage = ({
  currencyType,
}: {
  currencyType?: CurrencyType;
}) => {
  const { t } = useTranslation();
  const fee = app.memes.trading.getTxFee();

  // get the fee pct relative to 100% with no decimal places
  const feePct = getPct(100 * fee.feePercentage, 100, 0);

  if (!currencyType) currencyType = 'points';

  return (
    <div className="trading-token-footer-fee-message-container">
      <div className="percent">~{feePct}</div>
      <div className="transaction-fee">
        {t('trading_token_footer_transaction_fee')}
      </div>
    </div>
  );
};
