import './TradingCreatePage.scss';
import { useTranslation, Trans } from 'react-i18next';
import { app, useAppUpdates } from '../../../data/Controllers/AppController';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { TradingCreateInput } from './TradingCreateInput/TradingCreateInput';
import { Page } from '../Page';
import { TradingTokenImageUploader } from './TradingTokenImageUploader/TradingTokenImageUploader';
import { fileToB64, getTonIconReactImg } from '../../../data/utils';
import { FormEvents } from '../../../data/Form';
import { assets } from '../../../assets/assets';
import { isTelegramWebview } from '../../../data/device';
import { creatorGraduationTonReward } from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';

export const TradingCreatePage = () => {
  const { t } = useTranslation();
  const { visible } = app.views.TradingCreatePage;

  useAppUpdates({
    id: 'TradingCreatePage',
    listener: app.views.TradingCreatePage.attachEventListener(),
  });
  useAppUpdates({
    id: 'TradingCreatePage/FormEvents.OnUpdate',
    listener: app.memes.factory.newTokenForm.attachEventListener(
      FormEvents.OnUpdate,
    ),
  });

  const [linksVisible, setLinksVisible] = useState(false);
  const toggleLinkOptions = () => {
    setLinksVisible(!linksVisible);
  };

  const formConfig = useMemo(() => {
    const targetFields = ['name', 'ticker', 'description', 'image'];
    return app.memes.factory.newTokenForm.fields.filter((f) =>
      targetFields.includes(f.key),
    );
  }, []);

  const formConfigLinks = useMemo(() => {
    const targetFields = [
      'telegramChannelLink',
      'telegramChatLink',
      'twitterLink',
      'websiteLink',
    ];
    return app.memes.factory.newTokenForm.fields.filter((f) =>
      targetFields.includes(f.key),
    );
  }, []);

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    // react will reload the page for some reason when submitting a form
    // this will prevent it to happen
    event.preventDefault();

    // close the keyboard
    // inputRef.current?.blur();
  };

  const [previewCounter, setPreviewCounter] = useState(0);
  const [offchainTokenImage, setOffchainTokenImage] = useState<File>();

  // -----------------------------------
  // reset page each time we enter on it
  useEffect(() => {
    if (visible) {
      // console.warn('>>>> INIT PAGE');
      setLinksVisible(false);
      setOffchainTokenImage(undefined);
      setPreviewCounter(0);
    }
  }, [visible]);
  // -----------------------------------

  const onInputChange =
    (key: (typeof app.memes.factory.newTokenForm.fields)[0]['key']) =>
    (value: string) => {
      app.memes.factory.newTokenForm.setField(key, value);
    };

  useEffect(() => {
    if (offchainTokenImage) {
      fileToB64(offchainTokenImage).then((img) => {
        app.memes.factory.newTokenForm.setField('image', img);
      });
      app.memes.factory.newTokenForm.validateForm();
    } else {
      app.memes.factory.newTokenForm.setField('image', '');
    }
  }, [offchainTokenImage]);

  const onContinue = async () => {
    if (!app.memes.factory.newTokenForm.isValid) {
      return;
    }
    app.memes.factory.submitFormCreateToken();
  };

  if (!visible) {
    return null;
  }

  return (
    <Page id="trading-create" visible={visible}>
      <div className="trading-create-layout">
        <div className="trading-create-header">
          <div className="trading-create-title">
            {t('create_token_page_title')}
          </div>

          {/* form with several inputs */}
          <form onSubmit={onFormSubmit}>
            {formConfig.map((cfg, index) => {
              if (cfg.key === 'image') {
                return (
                  <div
                    className="trading-create-row"
                    key={`${cfg.key}_${index}`}
                  >
                    <div className="top">
                      <div className="title">{t(cfg.title)}</div>
                      <div className="required">{cfg.required ? '*' : ''}</div>
                    </div>

                    <div className="trading-create-image-container">
                      <div
                        className={`trading-create-image ${
                          offchainTokenImage ? 'uploaded' : ''
                        }`}
                      >
                        <TradingTokenImageUploader
                          useTrashCan={true}
                          onFileChange={setOffchainTokenImage}
                          uploadState={previewCounter}
                          preview={app.memes.factory.newTokenForm.data.image}
                        />
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <TradingCreateInput
                  key={`${cfg.key}_${index}`}
                  id={cfg.key}
                  title={t(cfg.title)}
                  placeholder={cfg.placeholder ? t(cfg.placeholder) : ''}
                  value={app.memes.factory.newTokenForm.data[cfg.key]}
                  onChange={onInputChange(cfg.key)}
                  isValid={app.memes.factory.newTokenForm.getIsFieldValid(
                    cfg.key,
                  )}
                  required={cfg.required}
                  maxLength={cfg.maxLength}
                />
              );
            })}
          </form>

          {/* button links options */}
          <div className="button-link-options" onClick={toggleLinkOptions}>
            <img
              src={assets.create_icon_arrow_blue}
              className={linksVisible ? 'up' : 'down'}
            />
            <div className="label">
              {linksVisible
                ? t('create_token_hide_options')
                : t('create_token_show_options')}
            </div>
          </div>

          {/* links form */}
          {linksVisible && (
            <form onSubmit={onFormSubmit}>
              {formConfigLinks.map((cfg, index) => {
                return (
                  <TradingCreateInput
                    key={`${cfg.key}_${index}`}
                    id={cfg.key}
                    title={`${t(cfg.title)} - ${cfg.example}`}
                    placeholder={cfg.placeholder ? t(cfg.placeholder) : ''}
                    value={app.memes.factory.newTokenForm.data[cfg.key]}
                    onChange={onInputChange(cfg.key)}
                    isValid={app.memes.factory.newTokenForm.getIsFieldValid(
                      cfg.key,
                    )}
                    required={cfg.required}
                  />
                );
              })}
            </form>
          )}
        </div>

        {/* footer: label + button continue */}
        <div
          className={`trading-create-footer ${linksVisible ? 'expanded' : ''} ${
            isTelegramWebview() ? 'telegram' : ''
          }`}
        >
          <div className="trading-create-footer-message">
            <Trans
              i18nKey="create_token_graduate_reward"
              components={{
                tonIcon: getTonIconReactImg(),
              }}
              values={{
                graduateRewardAmount: creatorGraduationTonReward,
              }}
            />
          </div>
          <div
            className={`btn btn-normal button-continue ${
              !app.memes.factory.newTokenForm.isValid && 'disabled'
            }`}
            onClick={onContinue}
          >
            {t('trading_create_continue')}
          </div>
        </div>
      </div>
    </Page>
  );
};
