import './DrawerInfoDex.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { app } from '../../../data/Controllers/AppController';

export const DrawerInfoDex = () => {
  const { t } = useTranslation();

  return (
    <div className="drawer-info-dex">
      {/* close button - needs to be custom, with minimize graphic instead */}
      <img
        className="modal-drawer-button-close"
        src={assets.button_arrow_down}
        onClick={app.ui.drawer.close}
      />

      {/* how it works */}
      <div className="drawer-info-dex-header">
        <div className="drawer-info-dex-title">
          {t('drawer_info_dex_title')}
        </div>
      </div>

      {/* steps */}
      <div className="drawer-info-dex-steps">
        <InfoDexStep
          title={t('drawer_info_dex_step1_title')}
          subtitle={t('drawer_info_dex_step1_subtitle')}
        />
        <InfoDexStep
          title={t('drawer_info_dex_step2_title')}
          subtitle={t('drawer_info_dex_step2_subtitle')}
        />
        <InfoDexStep
          title={t('drawer_info_dex_step3_title')}
          subtitle={t('drawer_info_dex_step3_subtitle')}
        />
        <InfoDexStep
          title={t('drawer_info_dex_step4_title')}
          subtitle={t('drawer_info_dex_step4_subtitle')}
        />
        <InfoDexStep
          title={t('drawer_info_dex_step5_title')}
          subtitle={t('drawer_info_dex_step5_subtitle')}
        />
      </div>
    </div>
  );
};

const InfoDexStep = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <div className="drawer-info-dex-step">
      <div className="drawer-info-dex-step-title">{title}</div>
      <div className="drawer-info-dex-step-subtitle">{subtitle}</div>
    </div>
  );
};
