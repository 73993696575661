import {
  SB,
  createSharedStateComputedProperties,
  searchableComputedProperty,
  GetIndexSchema,
} from '@play-co/replicant';
import {
  contractTypeSchema,
  onchainTxsSharedStateSchema,
} from './onchainTxs.schema';
import { getTxCount } from './onchainTxs.getters';

export const onchainTxsComputedProperties = createSharedStateComputedProperties(
  onchainTxsSharedStateSchema,
)({
  memeId: searchableComputedProperty(SB.string(), (state) => {
    return state.global.memeId;
  }),
  contractType: searchableComputedProperty(contractTypeSchema, (state) => {
    return state.global.contractType;
  }),
  page: searchableComputedProperty(SB.number(), (state) => {
    return state.global.page;
  }),
  txCount: searchableComputedProperty(SB.int(), (state) => {
    return getTxCount(state.global);
  }),
});

export type OnchainTxIndexSchema = GetIndexSchema<
  typeof onchainTxsComputedProperties
>;
