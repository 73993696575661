import './DrawerProfileSeasonInfo.scss';
import { assets } from '../../../assets/assets';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../replicant/utils/numbers';
import {
  getUserStartingSeason,
  getSeasonReward,
} from '../../../replicant/features/game/game.getters';
import { app } from '../../../data/Controllers/AppController';

export const DrawerProfileSeasonInfo = () => {
  const { t } = useTranslation();

  const startingSeason = getUserStartingSeason(app.state);

  return (
    <div className="drawer-profile-season-info">
      <div className="drawer-layout profile-season-info">
        <div className="drawer-header">
          {/* image */}
          <div className="drawer-header-icon">
            <img src={assets.coins_500k} />
          </div>

          {/* title */}
          <div className="profile-season-info-title">
            {t('og_player_info_title')}
          </div>

          {/* season rows */}
          <div className="profile-season-info-rows">
            {startingSeason <= 1 && <SeasonInfoRow season={1} />}
            {startingSeason <= 2 && <SeasonInfoRow season={2} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const SeasonInfoRow = ({ season }: { season: number }) => {
  const { t } = useTranslation();

  const reward = getSeasonReward(app.state, season);

  return (
    <div className="profile-season-info-row">
      <div className="left">
        {t('og_player_info_season')} {season}
      </div>

      <div className="right">
        <div className="text">{formatPrice(reward)}</div>
        <img src={assets.coin} />
      </div>
    </div>
  );
};
