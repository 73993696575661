import { createSharedStateSchema, SB, WithMeta } from '@play-co/replicant';
import { migrator } from './tradingMeme.migrator';

const reportsSchema = SB.object({
  createdAt: SB.int(),
  reason: SB.string().optional(),
});

export const tradingMemeDetailsSchema = SB.object({
  availableAt: SB.int().optional(),
  creatorId: SB.string(),
  creatorName: SB.string(),
  creatorImage: SB.string().optional(),
  creatorWalletAddress: SB.string(),
  image: SB.string(),
  moderatedImage: SB.string().optional(),
  name: SB.string(),
  description: SB.string(),
  telegramChannelLink: SB.string().optional(),
  telegramChatLink: SB.string().optional(),
  twitterLink: SB.string().optional(),
  websiteLink: SB.string().optional(),
  ticker: SB.string(),
});

export const txTypeSchema = SB.tuple([
  'deploy',
  'buy',
  'sell',
  'dexBuy',
  'dexSell',
  'dailyClaim',
  'graduationClaim',
]);

export const jettonTxInnerSchema = {
  txType: txTypeSchema,
  walletAddress: SB.string(),
  userId: SB.string(),
  userName: SB.string().optional(),
  userImage: SB.string().optional(),
  // currency: SB.tuple(['ton']).optional(),
  createdAt: SB.int(),
  tokenAmount: SB.string(),
};

export const jettonTxSchema = SB.object(jettonTxInnerSchema);

export const onchainTxSchema = SB.object({
  ...jettonTxInnerSchema,
  currencyAmount: SB.string(),
});

export const offchainTxSchema = SB.object({
  txType: SB.tuple(['buy', 'sell']),
  userId: SB.string(),
  userName: SB.string().optional(),
  userImage: SB.string().optional(),
  currency: SB.tuple(['coins', 'points', 'gift']).optional(),
  createdAt: SB.int(),
  pointAmount: SB.string(),
  currencyAmount: SB.string(),
});

const failedTxReasonSchema = SB.tuple(['concurrencyIssue']).optional();

export const failedOffchainTxSchema = SB.object({
  userId: SB.string(),
  createdAt: SB.int(),
  reason: failedTxReasonSchema,
});

export type FailedTxReason = SB.ExtractType<typeof failedTxReasonSchema>;

const trendSliceSchema = SB.object({
  price: SB.string(),
  supply: SB.string(),
  time: SB.int(),
});

export type PriceSlice = SB.ExtractType<typeof trendSliceSchema>;

const trendSchema = SB.object({
  hour24: SB.array(trendSliceSchema),
  day7: SB.array(trendSliceSchema),
  day30: SB.array(trendSliceSchema),
  allTime: SB.array(trendSliceSchema),
});

export type PriceTrends = SB.ExtractType<typeof trendSchema>;

const statsSliceSchema = SB.object({
  time: SB.int(),
  volume: SB.string(),
  holderCount: SB.int(),
});

export type StatsSlice = SB.ExtractType<typeof statsSliceSchema>;

const statsSchema = SB.object({
  // only 24h window for now
  hour24: SB.array(statsSliceSchema),
});

export type TradingMemeStats = SB.ExtractType<typeof statsSchema>;

export const onchainUserProfileSchema = SB.object({
  walletAddress: SB.string(),
  userId: SB.string(),
  userName: SB.string().optional(),
  userImage: SB.string().optional(),
});

export enum TradingMemeStatus {
  Drafted = 'drafted', // When the token has been created in replicant but not given data yet (we first draft to get id then create with data)
  Created = 'created',
  Moderated = 'moderated',
  ModeratedOS = 'moderatedOS',
  Reported = 'reported',
  Deleted = 'deleted',
}

const allowedStatuses: [string, ...string[]] = Object.values(
  TradingMemeStatus,
) as [string, ...string[]];

export const tradingMemeSharedStateSchema = createSharedStateSchema({
  global: {
    schema: SB.object({
      details: tradingMemeDetailsSchema,
      shares: SB.number(),
      reports: SB.array(reportsSchema),
      status: SB.tuple(allowedStatuses as [string, ...string[]]).default(
        TradingMemeStatus.Drafted,
      ),

      // onchain holding/tx data
      jettonContractAddress: SB.string(),
      jettonMinted: SB.boolean().default(false),
      dexContractAddress: SB.string().optional(),
      isGraduated: SB.boolean().default(false),
      dexListingTime: SB.int().optional(),
      onchainTxs: SB.array(onchainTxSchema),
      lastDexTxLogicalTime: SB.string().default('0'),
      lastJettonTxLogicalTime: SB.string().default('0'),
      lastOnchainTxUpdateTime: SB.int(),
      trends: trendSchema,
      stats: statsSchema,
      holderCount: SB.int(),
      sumOfHoldingsSquare: SB.string().default('0'),
      tokenSupply: SB.string().default('0'),
      firstBuyer: onchainUserProfileSchema,
      buyCount: SB.int(),
      sellCount: SB.int(),
      liquidity: SB.string().default('0'),

      // offchain holding/tx data
      pointSupply: SB.string().default('0'),
      offchainTxs: SB.array(offchainTxSchema), // Why do we need offchainTx?
      failedOffchainTxs: SB.array(failedOffchainTxSchema), // Why do we need offchainTx?
      dailyPoints: SB.map(SB.string()),
      pointsDistributed: SB.string().default('0'),
    }),
    migrator,
  },
});

export type TradingState = SB.ExtractType<
  typeof tradingMemeSharedStateSchema.global.schema
>;

export type TxType = SB.ExtractType<typeof txTypeSchema>;

export type OnchainUserProfile = SB.ExtractType<
  typeof onchainUserProfileSchema
>;

export type OffchainTx = SB.ExtractType<typeof offchainTxSchema>;

export type OnchainTx = SB.ExtractType<typeof onchainTxSchema>;

export type JettonTx = SB.ExtractType<typeof jettonTxSchema>;

export type MutableTradingState = WithMeta<TradingState, {}>;
