import './DrawerTradingTransaction.scss';
import { Trans, useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';

export const DrawerTradingClaimSuccess = () => {
  const { t } = useTranslation();

  const onTapShareButton = async () => {
    if (!meme) return;
    // share - sendGift
    await app.memes.sendGift(meme, 'ftue_share_gate');
    app.invoke.grantFtueShareReward({ memeId: meme.id || '' });
    setTimeout(() => app.ui.drawer.close(), 1000);
  };

  // get meme from drawer params
  const meme = app.ui.drawer.view.data?.props?.meme;
  const claimedTokens = app.ui.drawer.view.data?.props?.claimedTokens;

  return (
    <div className="drawer-trading-transaction">
      <div className="drawer-trading-transaction-header">
        <img
          className="drawer-trading-transaction-icon"
          src={assets.trading_transaction_success}
        />
        <div className="drawer-trading-transaction-title">
          {t(`drawer_claim_success_title`)}
        </div>

        <div className="drawer-trading-transaction-subtitle">
          <Trans
            i18nKey="drawer_claim_success_subtitle"
            components={{
              bold: <strong />,
              small: <small />,
            }}
            values={{
              amount: claimedTokens,
              tokenName: meme?.ticker,
            }}
          />
        </div>
      </div>

      <div className="footer-buttons">
        {/* share */}
        <div
          className={`btn btn-normal`}
          onClick={() => {
            app.ui.confetti.hide();
            app.ui.drawer.close();
            onTapShareButton();
          }}
        >
          {t('generic_share')}
        </div>
      </div>
    </div>
  );
};
