import './DrawerTradingCreationSuccess.scss';
import { useTranslation } from 'react-i18next';
import { assets } from '../../../../assets/assets';
import { app } from '../../../../data/Controllers/AppController';
import { useEffect, useRef, useState } from 'react';
import { displayPointAmount } from '../../../../replicant/utils/numbers';
import { HP } from '../../../../replicant/lib/HighPrecision';
import { memeGiftRuleset } from '../../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { getBuyTxEstimate } from '../../../../replicant/features/tradingMeme/tradingMeme.getters';

export const DrawerTradingCreationSuccess = () => {
  const { t } = useTranslation();

  // this is old share without sending gift
  // const onTapShareButton = () => {
  //   app.memes.shareOffchainToken('creation_success_drawer', props);
  // };

  // get created token through drawer params
  const token = app.ui.drawer.view.data?.props?.meme;
  console.warn(
    '>>> DrawerTradingCreationSuccess - token',
    app.ui.drawer.view.data,
  );

  // calculate get/give sendgift values
  const supply = HP(token?.tokenSupply ?? 0);
  const giftCoinEquivalent = memeGiftRuleset.giftReward;
  const giftPoints = getBuyTxEstimate({
    currentSupply: supply,
    currencyAmount: HP(giftCoinEquivalent),
  });

  const onClose = () => {
    app.ui.confetti.hide();
    app.ui.drawer.close();

    // note: would be nice to be able to auto-navigate to the created token
    // but at the time of closing this drawer the meme might not have been validated and added to the feed
    // app.nav.goToTiktokFeed(token.id || '');

    // clear form inputdata after a while so image doesnt get screwed while closign the popup
    setTimeout(() => {
      app.memes.factory.newTokenForm.clearForm();
    }, 350);
  };

  const onTapShareButton = async () => {
    if (!token) return;
    // share - sendGift
    await app.memes.sendGift(token, 'ftue_share_gate');
    app.invoke.grantFtueShareReward({ memeId: token.id || '' });
    setTimeout(() => onClose(), 1000);
  };

  // ---
  // note:
  // image needs to be the local image from the form inputdata
  // because at this point, created token has not been validated yet
  const inputData = app.memes.factory.newTokenForm.data;

  const imgPreviewRef = useRef<any>();
  const [previewVerticalAspectRatio, setPreviewVerticalAspectRatio] =
    useState(false);

  useEffect(() => {
    if (inputData.image) {
      if (imgPreviewRef.current) {
        const isVertical =
          imgPreviewRef.current.naturalWidth <
          imgPreviewRef.current.naturalHeight;
        setPreviewVerticalAspectRatio(isVertical);
      }
    }
  }, [inputData.image]);
  // ---

  return (
    <div className="drawer-create-transaction">
      {/* empty header */}
      <div className="header-empty" />

      {/* token image */}
      <div className="create-token-container">
        <div className="create-token-frame">
          <img src={assets.create_card_front} />

          <div className="create-token-image">
            <img
              ref={imgPreviewRef}
              src={inputData.image || assets.create_icon_camera}
              style={{
                width: previewVerticalAspectRatio ? '100%' : 'unset',
                height: previewVerticalAspectRatio ? 'unset' : '100%',
              }}
            />
          </div>

          {/* ticker name */}
          <div className="create-ticker-name">${token?.ticker || '$Meme'}</div>
        </div>
      </div>

      {/* token footer */}
      <div className="drawer-create-transaction-header">
        <img
          className="drawer-create-transaction-icon"
          src={assets.trading_transaction_success}
        />

        <div className="drawer-create-transaction-title-row">
          <div className="drawer-create-transaction-title yellow">
            {token?.name || 'Meme'}
          </div>
          <div className="drawer-create-transaction-title">
            {t('trading_create_success_title')}
          </div>
        </div>

        {/* sendGift - give and get */}
        <div className="drawer-create-share-giveAndGet">
          <div className="drawer-create-share-giveAndGet-text">
            {t('user_meme_gift_give_get', {
              amount: displayPointAmount(
                giftPoints.toNumber(),
                3,
                false,
                false,
              ),
            })}
          </div>
        </div>
      </div>

      {/* footer buttons - share and close */}
      <div className="footer-buttons">
        {/* share */}
        <div
          className={`btn btn-normal button-share`}
          onClick={onTapShareButton}
        >
          {t('generic_share')}
        </div>

        <div className="btn button-share-close" onClick={onClose}>
          {t('generic_close')}
        </div>
      </div>
    </div>
  );
};
