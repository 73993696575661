import { createSharedStateSchema, SB, WithMeta } from '@play-co/replicant';

export const holderSchema = SB.object({
  walletAddress: SB.string(),
  tokenAmount: SB.string(),
  jettonTokenAmount: SB.string().optional(),
  dexTokenAmount: SB.string().optional(),
  userId: SB.string(),
  userName: SB.string().optional(),
  userImage: SB.string().optional(),
});

export const holdersSchema = SB.array(holderSchema);

export type Holding = SB.ExtractType<typeof holderSchema>;

export const onchainHoldersSharedStateSchema = createSharedStateSchema({
  global: {
    schema: SB.object({
      memeId: SB.string(),
      holders: holdersSchema,
    }),
  },
});

export type onchainHoldersState = SB.ExtractType<
  typeof onchainHoldersSharedStateSchema.global.schema
>;

export type MutableonchainHoldersState = WithMeta<onchainHoldersState, {}>;
