import { PureController } from './Controllers/PureController';

interface FormField {
  title: string;
  placeholder?: string;
  example?: string;
  validate?: (str: string) => boolean;
  transformInput?: (str: string) => string;
  required: boolean;
  maxLength?: number | undefined;
  // data only fields are filtered out when requesting fields to render
  dataOnly?: true;
  initialValue?: string;
}

export enum FormEvents {
  OnUpdate = 'OnUpdate',
}

export class Form<T extends string> extends PureController<FormEvents> {
  private state: Record<T, string>;
  get isValid() {
    return this.validateForm();
  }

  get data() {
    return this.state;
  }
  // fields to be rendered - remove data only
  get fields() {
    return this.fieldKeys
      .map((key) => ({
        key,
        ...this.cfg[key],
      }))
      .filter((cfg) => !cfg.dataOnly);
  }

  private get fieldKeys() {
    return Object.keys(this.cfg) as T[];
  }

  constructor(private cfg: Record<T, FormField>) {
    super();
    this.state = this.getInitialFormState();
  }

  private getInitialFormState = () =>
    this.fieldKeys.reduce(
      (res, cur) => ({
        ...res,
        [cur]: this.cfg[cur].initialValue ?? '',
      }),
      {} as Record<T, string>,
    );

  validatePartial = (fields: T[]) => {
    return fields.every((fieldKey) => {
      return this.getIsFieldValid(fieldKey);
    });
  };

  validateForm = () => {
    return this.validatePartial(this.fieldKeys);
  };

  clearForm = () => {
    this.state = this.getInitialFormState();
    this.sendEvents(FormEvents.OnUpdate);
  };

  // Field operations

  getIsFieldValid = (field: T) => {
    const fieldCfg = this.cfg[field];
    if (!fieldCfg) {
      return false;
    }
    const value = this.state[field];
    if (fieldCfg.required && !value) {
      return false;
    }
    // only validate the value if value is set
    if (value) {
      return fieldCfg.validate?.(value) ?? true;
    }
    return true;
  };

  setField = (field: T, value: string) => {
    const fieldCfg = this.cfg[field];
    if (!fieldCfg) {
      return;
    }
    this.state[field] = fieldCfg.transformInput
      ? fieldCfg.transformInput(value)
      : value;
    this.sendEvents(FormEvents.OnUpdate);
  };

  clearField = (field: T) => {
    this.state[field] = '';
  };
}
