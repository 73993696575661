import { getTapGameFx } from '../../../components/pages/ClickerPage/ClickerDiamond/addPointUp';
import {
  getTMGTappingSessionTaps,
  getTMGTappingTickets,
} from '../../../replicant/features/tradingMeme/tradingMeme.getters';
import {
  memePointsDisplayMultiplier,
  tmgRuleset,
} from '../../../replicant/features/tradingMeme/tradingMeme.ruleset';
import { Fn, Optional, WithProxy } from '../../types';
import { MemesEvents } from '../Memes/MemesController';
import { TMGEvents } from './TMGController';
import { TokenMiniGame } from './TokenMiniGame';
import { Timer, TimerEvents } from '../../Timer';
import { NavEvents } from '../NavController';
import { ModalEvents } from '../UIController/ModalManager';
import { TutorialEvents } from '../../tutorial/TutorialController';
import { TransactionSuccess } from '../UIController/UITypes';
import { isMobile, isMobileEmulatedByBrowser } from '../../device';
import { t } from 'i18next';
import { ABManager } from '../../../replicant/config';
import { HP } from '../../../replicant/lib/HighPrecision';

export const tapTimerConfig = {
  GAME_SESSION_DURATION_SEC: 15,
  GAME_AUTO_START_COUNTDOWN_SEC: 3,
  GAME_START_COUNTDOWN_SEC: 3,
  GAME_TAP_IDLE_COOLDOWN_SEC: 3.5,
};

interface TappingState {
  id: string;
  tapEnabled: boolean;
  onTap: (mousePos: { x: number; y: number }) => void;
  startGame: Fn<void>;
  resetGame: Fn<void>;
  showPoints: boolean;
  tickets: number;
  isPlaying: boolean;
  gameStartCountdown?: number;
  timeLeft: number;
}

export class TMGTapping extends TokenMiniGame<TappingState> {
  private showOverlay = false;

  private showPoints = false;

  private tapEnabled = false;

  private isPlaying = false;

  private tapCount = 0;

  private gameFx = getTapGameFx('tiktok');

  private timerGameSession = new Timer({
    id: 'tmgTapping/gameSessionTimer',
    duration: tapTimerConfig.GAME_SESSION_DURATION_SEC,
  });

  // This shows before the same starts
  private timerAutoStartCountdown = new Timer({
    id: 'tmgTapping/timerAutoStartCountdown',
    duration: tapTimerConfig.GAME_AUTO_START_COUNTDOWN_SEC,
  });

  // This shows once the game starts to give time for the user to get ready
  private timerSessionStartCountdown = new Timer({
    id: 'tmgTapping/timerSessionStartCountdown',
    duration: tapTimerConfig.GAME_START_COUNTDOWN_SEC,
  });

  private timerIdleHand = new Timer({
    id: 'tmgTapping/gameIdleTimer',
    duration: tapTimerConfig.GAME_START_COUNTDOWN_SEC,
    step: 500, // because we have .5 we need to step every 500ms
  });

  private hasShownNoTickets = false;

  private evts: ReturnType<typeof this.addEventListener>[] = [];

  private currentTokenId?: string;

  private isRetry = false;

  private get active() {
    return this.app.nav.currentRoute === 'TiktokPage';
  }

  private get timeLeft() {
    return this.timerGameSession.time;
  }

  public get state() {
    if (!this.tokenId) {
      return undefined;
    }
    const tickets = getTMGTappingTickets(this.tokenId, this.app.replicant);
    const taps = this.tapEnabled ? getTMGTappingSessionTaps(this.app.state) : 0;

    const gameAutoStartCountdown = this.timerAutoStartCountdown.isRunning
      ? this.timerAutoStartCountdown.time
      : undefined;

    const gameStartCountdown = this.timerSessionStartCountdown.isRunning
      ? this.timerSessionStartCountdown.time
      : undefined;

    return {
      id: this.tokenId,
      tapEnabled: this.tapEnabled,
      showPoints: this.showPoints,
      timeLeft: this.timeLeft,
      isPlaying: this.isPlaying,
      showOverlay: this.showOverlay,
      tickets,
      taps,
      gameStartCountdown,
      gameAutoStartCountdown,
      sessionDuration: this.timerGameSession.duration,
      // Functions
      onTap: this.onTap,
      startGame: this.startGame,
      share: this.share,
      resetGame: this.reset,
      // killGame: this.killGame,
    };
  }

  private addEventListeners = () => {
    return [
      this.app.memes.addEventListener(MemesEvents.OnTokenSelected, () => {
        this.hasShownNoTickets = false;
        this.startAutoPlay();
        this.currentTokenId = this.tokenId;
      }),
      this.timerGameSession.listen<TimerEvents>((evt) => {
        if (evt === TimerEvents.OnTick) {
          this.sendEvent(TMGEvents.OnTappingGameTick);
        } else if (evt === TimerEvents.OnEnd) {
          this.onGameEnd();
        }
      }),
      this.timerAutoStartCountdown.listen<TimerEvents>((evt) => {
        if (evt === TimerEvents.OnTick) {
          this.sendEvent(TMGEvents.OnTappingGameTick);
        } else if (evt === TimerEvents.OnEnd) {
          this._startGame();
        }
      }),
      this.timerIdleHand.listen<TimerEvents>((evt) => {
        if (evt === TimerEvents.OnStart) {
          this.showOverlay = false;
        } else if (evt === TimerEvents.OnEnd) {
          if (this.isPlaying) {
            this.showOverlay = true;
            this.sendEvent(TMGEvents.OnTappingUpdate);
          }
        }
      }),
      this.timerSessionStartCountdown.listen<TimerEvents>((evt) => {
        if (evt === TimerEvents.OnTick) {
          this.sendEvent(TMGEvents.OnTappingGameTick);
        }
      }),

      // Start auto play when we close a drawer on tiktok page otherwise reset
      this.app.ui.drawer.addEventListener(ModalEvents.OnUpdate, () => {
        if (!this.app.ui.drawer.view.visible) {
          if (this.app.nav.currentRoute === 'TiktokPage') {
            this.startAutoPlay();
          }
        } else {
          this.timerAutoStartCountdown.stop();
        }
      }),

      this.app.tutorial.addEventListener(TutorialEvents.onUpdate, () => {
        if (this.app.tutorial.active) {
          this.reset();
        }
      }),
    ];
  };

  public share = (copyUrlOnly: Optional<'copyUrlOnly'> = undefined) => {
    const token = this.app.memes.currentMeme.meme;
    if (!token) {
      return;
    }

    this.track('load_end_game_invite_screen', { tickets: this.state?.tickets });
    this.track('endgame_invite_click');
    return this.app.memes.shareOffchainToken(
      'end_game_invite_screen',
      {
        memeId: token?.id, //  this.tokenId,
        memeName: token?.name,
        memeDescription: t('session_end_send_text'),
      } as TransactionSuccess,
      'isTiktok',
      Boolean(copyUrlOnly),
    );
  };

  override init = async (opts: WithProxy<TMGEvents>) => {
    // Do the set duration here when replicant is ready and we have access to AB tests
    this.timerGameSession.setDuration(tapTimerConfig.GAME_SESSION_DURATION_SEC);
    this.timerAutoStartCountdown.setDuration(
      tapTimerConfig.GAME_AUTO_START_COUNTDOWN_SEC,
    );

    // Start auto play when we navigate to tiktok page otherwise reset
    this.app.nav.addEventListener(NavEvents.OnNavigate, () => {
      if (this.active) {
        this.evts = this.addEventListeners();
        this.startAutoPlay();
      } else {
        this.evts.forEach((unsubscribe) => {
          unsubscribe();
        });
        this.hasShownNoTickets = false;
        this.reset();
      }
    });

    super.init(opts);
  };

  private startAutoPlay = () => {
    if (
      !this.tokenId ||
      this.hasShownNoTickets ||
      !this.active ||
      this.isRetry
      // note: this prevents autostart when we cancel the game while swiping from 3,2,1 countdown
      // || this.currentTokenId === this.tokenId
    ) {
      return;
    }
    return;
    this.reset();

    this.timerAutoStartCountdown.start();
  };

  private handleOutOfTicket = () => {
    // @TODO: send friend invite
    this.hasShownNoTickets = true;
    // open drawerTiktokInvite
    this.app.ui.drawer.show({
      id: 'drawerTiktokInvite',
      skipBackdropClose: true,
      hideClose: isMobile() || isMobileEmulatedByBrowser(),
      opts: {},
    });
  };

  private _startGame = async () => {
    if (!this.state) {
      return;
    }

    const canStart = this.isRetry || !this.isPlaying || this.active;

    if (!canStart) {
      return;
    }

    this.isRetry = false;

    const { tickets, id } = this.state;

    this.showPoints = true;

    this.timerAutoStartCountdown.stop();

    if (tickets <= 0) {
      this.isPlaying = false;
      return this.handleOutOfTicket();
    }

    // This makes so the app does not navigate from the current page and instead would close a modal
    this.app.nav.showingBlockingModal();

    this.isPlaying = true;
    this.sendEvent(TMGEvents.OnTappingPlayingUpdate);

    await this.timerSessionStartCountdown.start().waitForComplete;

    this.track('use_ticket');

    this.app.invoke.tmgStartSession({ tokenId: id });

    this.track('play_session_start');

    console.warn('>>> startGame - tickets:', tickets);
    this.showPoints = true;
    this.tapEnabled = true;
    this.showOverlay = false;
    this.tapCount = 0;

    this.timerGameSession.start();
    this.timerIdleHand.start();
    this.sendEvent(TMGEvents.OnTappingUpdate);
  };

  public startGame = async (isRetry: Optional<'retry'> = undefined) => {
    this.isRetry = Boolean(isRetry);
    if (this.isRetry) {
      this.track('endgame_play_again_click');
    }
    this._startGame();
  };

  public onTap = (mousePos: { x: number; y: number }) => {
    if (!this.tokenId || !this.tapEnabled) {
      return;
    }

    if (this.tapCount === 0) {
      this.track('play_session_tap');
    }
    this.tapCount += 1;

    this.timerIdleHand.start();

    this.app.invoke.tmgTokenTap();
    this.gameFx.addPointUp(mousePos, tmgRuleset.tappingScorePerTap);
    this.gameFx.addRipple(mousePos);
    this.sendEvent(TMGEvents.OnTappingTap);
  };

  private onGameEnd = async () => {
    this.timerGameSession.stop();

    // @TODO: I think we should store the request (promise) into a variable and make sure the user can't leave the modal
    // until the promise is resolved, to make sure the scores have been saved correctly;
    if (!this.tokenId) {
      return;
    }

    this.app.ui.setTappingState({ points: 'loading' });
    this.app.invoke
      .tmgHandleTapSessionEnd({
        tokenId: this.tokenId,
      })
      .then((points) => {
        this.app.invoke.tmgTriggerKickbackReward();

        const pointsBig = HP(points);

        this.track('play_session_end', {
          taps: this.tapCount,
          player_points: pointsBig.toNumber(),
        });

        const displayPoints = pointsBig
          .mul(memePointsDisplayMultiplier)
          .toString();
        this.app.ui.setTappingState({ points: displayPoints });
      });

    // when game ends, open gameover drawer, which is special and looks like a fullscreen overlay
    this.app.ui.drawer.show({
      id: 'drawerTiktokGameOver',
      skipBackdropClose: true,
      hideClose: isMobile() || isMobileEmulatedByBrowser(), //  true, // todo carles
      onClose: this.reset,
    });

    // reset in-game ui once we open the gameover drawer
    this.timerIdleHand.stop();
    this.showOverlay = false;
    this.showPoints = false;
    this.sendEvent(TMGEvents.OnTappingGameOver);

    // Reload current token
    this.app.memes.getMeme(undefined, 'fetchAndUpdate');
  };

  private reset = () => {
    // console.warn('>>> RESET TAPGAME');
    this.timerGameSession.stop();
    this.timerIdleHand.stop();
    this.timerAutoStartCountdown.stop();
    this.timerSessionStartCountdown.stop();
    this.tapEnabled = false;
    this.isPlaying = false;
    this.showPoints = false;
    this.showOverlay = false;
    this.sendEvent(TMGEvents.OnTappingPlayingUpdate);
    this.sendEvent(TMGEvents.OnTappingUpdate);
    this.sendEvent(TMGEvents.OnPointsUpdate);
  };
}
