import './TradingCreateInput.scss';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setUserScrolling } from '../../../../data/utils';
import { assets } from '../../../../assets/assets';

interface Props {
  id: string;
  title: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  isValid: boolean;
  required?: boolean;
  maxLength?: number;
  children?: any; // todo carles review
}

export const TradingCreateInput = ({
  id,
  title,
  placeholder,
  value,
  onChange,
  isValid,
  required,
  maxLength,
}: Props) => {
  const { t } = useTranslation();

  const inputRef = useRef<any>();
  const [isInputFocused, setInputFocused] = useState(false);

  // =======================================================

  const onInputFocus = (focused: boolean) => {
    setInputFocused(focused);

    // prevent scrolling while window open
    setUserScrolling(!focused);

    function setPageHeightStyle(heightStyle: string) {
      const page = document.querySelector(
        '.page.modal-team-list',
      ) as HTMLDivElement;

      if (page) page.style.minHeight = heightStyle;
    }

    setPageHeightStyle('unset');

    // reset scroll
    if (!focused) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);
    }
  };

  // =======================================================

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange],
  );

  // =======================================================

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputRef.current) {
      inputRef.current.blur();
    }
  };

  // =======================================================

  return (
    <div className="trading-create-row">
      <div className="top">
        <div className="title">{title}</div>
        <div className="required">{required ? '*' : ''}</div>
      </div>

      {id === 'image' ? (
        <div className={`trading-create-input ${id}`}></div>
      ) : (
        <input
          className={`trading-create-input ${id}`}
          ref={inputRef}
          type="input"
          maxLength={maxLength}
          //
          inputMode={id.includes('Link') ? 'url' : 'text'}
          autoCapitalize="on"
          autoComplete="on"
          autoCorrect="on"
          enterKeyHint="done"
          //
          placeholder={placeholder}
          value={value}
          onChange={onInputChange}
          autoFocus={false}
          onFocus={() => onInputFocus(true)}
          onBlur={() => onInputFocus(false)}
          onKeyDown={onKeyDown}
          readOnly={id === 'image'}
        />
      )}

      {isValid && inputRef?.current?.value.length > 0 && (
        <div className="icon-tick">
          <img src={assets.create_icon_green_tick} />
        </div>
      )}
    </div>
  );
};
