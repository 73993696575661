import {
  GetIndexSchema,
  SB,
  createSharedStateComputedProperties,
  matchableString,
  payloadComputedProperty,
  searchableComputedProperty,
} from '@play-co/replicant';
import {
  OffchainTx,
  tradingMemeSharedStateSchema,
  onchainTxSchema,
  OnchainTx,
} from './tradingMeme.schema';
import { DAY_IN_MS, HOUR_IN_MS, MIN_IN_MS } from '../../utils/time';
import {
  getCoefficientOfVariation,
  getOnchainCurvePrice,
  getPriceBackThen,
  getPriceSliceBackThen,
  getTokenPrice,
  getValueChange,
} from './tradingMeme.getters';
import { HP } from '../../lib/HighPrecision';
import { onchainCurveConfig } from './tradingMeme.ruleset';

export const tradingMemeComputedProperties =
  createSharedStateComputedProperties(tradingMemeSharedStateSchema)({
    profile: searchableComputedProperty(
      SB.object({
        name: matchableString(),
        creatorName: matchableString(),
        creatorImage: matchableString(),
        creatorId: SB.string(),
        image: SB.string(),
        ticker: SB.string(),
      }),
      (state) => ({
        name: state.global.details.name,
        creatorName: state.global.details.creatorName,
        creatorImage: state.global.details.creatorImage || '',
        creatorId: state.global.details.creatorId,
        image: state.global.details.image,
        ticker: state.global.details.ticker,
      }),
    ),
    availableAt: searchableComputedProperty(
      SB.int(),
      (state) => state.global.details.availableAt ?? 0,
    ),
    jettonContractAddress: searchableComputedProperty(
      SB.string(),
      (state) => state.global.jettonContractAddress || '',
    ),
    dexContractAddress: searchableComputedProperty(
      SB.string(),
      (state) => state.global.dexContractAddress || '',
    ),
    dexListingTime: searchableComputedProperty(
      SB.int(),
      (state) => state.global.dexListingTime ?? 0,
    ),
    dailyPoints: payloadComputedProperty(
      SB.array(
        SB.object({
          date: SB.int(),
          totalDailyPoints: SB.string(),
        }),
      ),
      (state) => {
        const dailyPoints = state.global.dailyPoints;

        // reversing dates to make array searches more optimal (latest date will often be the one searched for)
        const dates = Object.keys(dailyPoints).reverse();
        return dates.map((date) => {
          return {
            date: parseInt(date),
            totalDailyPoints: dailyPoints[date],
          };
        });
      },
    ),
    lastTx: searchableComputedProperty(onchainTxSchema, (state) => {
      // Make sure there is always a transaction event if no txs has been specified yet
      // (there is a short time interval during which a offchainToken may not have any transaction although each offchainToken is created with a buy transaction)
      const zero = HP(0).toString();
      const dummyTx: OnchainTx = {
        walletAddress: '',
        createdAt: state.global.createdAt,
        txType: 'buy',
        userId: state.global.details.creatorId,
        currencyAmount: zero,
        tokenAmount: zero,
      };

      return (
        state.global.onchainTxs[state.global.onchainTxs.length - 1] || dummyTx
      );
    }),
    holderCount: searchableComputedProperty(
      SB.int(),
      (state) => state.global.holderCount,
    ),
    distribution: searchableComputedProperty(SB.number(), (state) =>
      getCoefficientOfVariation(state.global),
    ),
    tokenPrice: searchableComputedProperty(SB.string(), (state) =>
      getOnchainCurvePrice(HP(state.global.tokenSupply)).toString(),
    ),
    pastTokenPrices: payloadComputedProperty(
      SB.array(
        SB.object({
          supply: SB.string(),
          price: SB.string(),
          time: SB.int(),
        }),
      ),
      (state, api) => {
        const now = api.date.now();
        // get prices at 1h interval over the last 24h
        const priceHistory = [];
        for (let i = 24; i >= 0; i--) {
          const time = now - i * HOUR_IN_MS;
          priceHistory.push(
            getPriceSliceBackThen(state.global.trends.hour24, time),
          );
        }

        return priceHistory;
      },
    ),
    priceChange: searchableComputedProperty(
      SB.object({
        last1hour: SB.number(),
        last24hours: SB.number(),
        // last7days: SB.number(),
        // last30days: SB.number(),
      }),
      (state, api) => {
        try {
          const now = api.date.now();

          const priceNow = HP(getTokenPrice(state.global));
          const price1hAgo = getPriceBackThen(
            state.global.trends.hour24,
            now - 1 * HOUR_IN_MS,
          );

          const price24hAgo = getPriceBackThen(
            state.global.trends.hour24,
            now - 24 * HOUR_IN_MS,
          );

          return {
            last1hour: getValueChange(priceNow, price1hAgo),
            last24hours: getValueChange(priceNow, price24hAgo),
            // last7days: 0,
            // last30days: 0,
          };
        } catch (error) {
          console.error(error);
          return {
            last1hour: 0,
            last24hours: 0,
          };
        }
      },
    ),
    top: searchableComputedProperty(SB.number(), (state, api) => {
      if (
        state.global.dexListingTime !== undefined &&
        state.global.dexListingTime > 0
      ) {
        // Add max supply to dex-listed token so that they always show on top of jetton tokens
        const maxTokenSupply = Number(onchainCurveConfig.maxSupply);
        return maxTokenSupply + state.global.dexListingTime;
      }

      if (state.global.jettonMinted) {
        return HP(state.global.tokenSupply).toNumber();
      }

      return -1;
    }),
    volume: searchableComputedProperty(SB.number(), (state, api) => {
      const now = api.date.now();
      const oneDayAgo = now - DAY_IN_MS * 1;
      const volume = state.global.stats.hour24.reduce(
        (totalVolume, statsSlice) => {
          if (statsSlice.time < oneDayAgo) {
            return totalVolume;
          }

          return totalVolume.add(statsSlice.volume);
        },
        HP(0),
      );

      return volume.toNumber();
    }),
    pointSupply: searchableComputedProperty(SB.number(), (state) => {
      if (!state.global.pointSupply || state.global.pointSupply === '0') {
        return 0;
      }
      return HP(state.global.pointSupply).toNumber();
    }),
    tokenSupply: searchableComputedProperty(SB.number(), (state) => {
      if (!state.global.tokenSupply || state.global.tokenSupply === '0') {
        return 0;
      }
      return HP(state.global.tokenSupply).toNumber();
    }),
    status: searchableComputedProperty(
      SB.string(),
      (state) => state.global.status,
    ),
    shares: searchableComputedProperty(
      SB.number(),
      (state) => state.global.shares,
    ),
  });

export type TradingIndexSchema = GetIndexSchema<
  typeof tradingMemeComputedProperties
>;

export type TradingSearchResult = {
  [T in keyof typeof tradingMemeComputedProperties]: SB.ExtractType<
    (typeof tradingMemeComputedProperties)[T]['type']
  >;
} & { id: string };
