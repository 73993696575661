import { useTranslation } from 'react-i18next';
import { app } from '../../../../data/Controllers/AppController';
import { getUIStateClassName } from '../../../../data/utils';
import './ProfileItem.scss';
import { MemeImage } from '../../../shared/MemeImage/MemeImage';
import { useEffect, useState } from 'react';
import { Meme } from '../../../../replicant/features/tradingMeme/tradingMeme.getters';
import { UnconfirmedTx } from '../../../../replicant/features/game/player.schema';
import { getDexGraduationPct } from '../../../../data/memeUtils';
import { assets } from '../../../../assets/assets';

interface Props {
  pendingTx: UnconfirmedTx;
}

export const ProfilePendingTx = ({ pendingTx }: Props) => {
  const { t } = useTranslation();

  const memeId = pendingTx.memeId;

  const [meme, setMeme] = useState(undefined as Meme | undefined);
  const [isGraduated, setIsGraduated] = useState(false);

  const onShowOffchainTokenDetails = async () => {
    app.nav.goToTiktokFeed(memeId, 'Owned', 'profile-list');
  };

  useEffect(() => {
    app.memes.getMeme(memeId, 'cache').then((meme) => {
      setMeme(meme);
      if (meme) {
        getDexGraduationPct(meme).then((graduationPct) => {
          setIsGraduated(graduationPct >= 100);
        });
      }
    });
  }, [memeId]);

  if (!meme) {
    return <div></div>;
  }

  const getTxType = () => {
    const txType = pendingTx.txType;
    if (txType === 'buy' || txType === 'dexBuy') {
      return 'buy';
    }

    if (txType === 'sell' || txType === 'dexSell') {
      return 'sell';
    }

    if (txType === 'dailyClaim' || txType === 'graduationClaim') {
      return 'claim';
    }
  };

  const txType = getTxType();
  if (!txType) {
    // do not show
    return;
  }

  const color = {
    buy: '#09d0a8',
    sell: '#d00948',
    claim: '#cccccc',
  }[txType];

  return (
    <div
      className={`btn item-profile item-profile--compact ${getUIStateClassName(
        app.ui.getCardState(memeId),
      )}`}
      onClick={onShowOffchainTokenDetails}
    >
      <div className="item-profile-container">
        <MemeImage
          src={meme.image}
          size={36}
          badgeSrc={isGraduated ? assets.icon_graduation_rocket : undefined}
        />

        <div className="item-profile-content">
          {/* left info */}
          <div className="left">
            <div className="name-container">{meme.name}</div>

            <div className="owned-container">
              <strong style={{ color }}>{txType}</strong>
            </div>
          </div>

          <div className="right">
            <div className="pending-container">
              <div>{t('profile_tx_pending')}</div>
              <div className="pending-spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
