import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';
import {
  getPowerUpBonusPerHour,
  getPowerUpCount,
} from '../features/powerups/getters';
import { DAY_IN_MS } from '../utils/time';
import {
  getGrossCoinAmountForPointSell,
  getMyOffchainMemeIds,
} from '../features/tradingMeme/tradingMeme.getters';
import {
  COMPLETE_SEASON_2_LABEL,
  season2StartTime,
} from '../features/tradingMeme/tradingMeme.ruleset';
import { getLeague } from '../features/game/game.getters';
import { League, SEASON } from '../features/game/ruleset/league';
import {
  fillInSeasonRecord,
  migratePowerUps,
  migratePowerUps1,
  migrateToSeason2,
} from '../features/game/migration.modifiers';
import { HP } from '../lib/HighPrecision';
import { TradingMemeStatus } from '../features/tradingMeme/tradingMeme.schema';

export async function completeSeason2(
  state: MutableState,
  api:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  // Compensation for mining cards
  // Coin amount is determined by multiplying their current earnings per day multiplied by the amount of days their account has been active

  if (state.createdAt < season2StartTime) {
    // was active before season 2 launched
    state.startingSeason = 1;
  } else if (state.season === 2) {
    // was active during season 2
    state.startingSeason = 2;
  }

  // migrate players from season 1 to season 2 first
  migrateToSeason2(state);

  migratePowerUps(state);
  migratePowerUps1(state);

  const now = api.date.now();
  const daysActive = Math.ceil((now - state.createdAt) / DAY_IN_MS);
  const powerUpEarningsPerHour = getPowerUpBonusPerHour(state, now);
  const powerUpEarningsPerDay = 24 * powerUpEarningsPerHour;

  const powerUpCompensation = Math.round(powerUpEarningsPerDay * daysActive);

  // Compensation for existing meme portfolio
  // All players will have their existing offchain memes purchased back at latest portfolio value
  const offchainTokens = state.trading.offchainTokens;
  const myTokenIds = getMyOffchainMemeIds(state);

  let offchainTokenCompensation = 0;
  if (myTokenIds.length !== 0) {
    const myTokens =
      await api.asyncGetters.getLegacyOffchainTokensFromOpenSearch({
        offchainTokenIds: myTokenIds,
        status: [
          TradingMemeStatus.Created,
          TradingMemeStatus.Moderated,
          TradingMemeStatus.ModeratedOS,
          TradingMemeStatus.Reported,
        ],
        availableAt: season2StartTime,
      });

    myTokens.forEach((tokenStatus) => {
      // compute portfolio value of token
      const supply = HP(tokenStatus.supply);
      const pointAmountHeld = HP(offchainTokens[tokenStatus.id].pointAmount);
      const portfolioValue = getGrossCoinAmountForPointSell(
        supply,
        pointAmountHeld,
      ).toNumber();

      offchainTokenCompensation += portfolioValue;
    });
  }

  // provide reward as starting balance
  offchainTokenCompensation = Math.round(offchainTokenCompensation);

  state.trading.offchainTokensLegacy = state.trading.offchainTokens;
  state.trading.offchainTokens = {};

  const existingBalance = state.balance;

  const totalCompensation =
    state.balance + powerUpCompensation + offchainTokenCompensation;

  state.balance = totalCompensation;

  // saving score and league
  const previousSeason = SEASON - 1;
  const previousSeasonIdx = previousSeason - 1;
  const previousSeasonLeague = getLeague(state, previousSeason);

  fillInSeasonRecord(
    state.seasonLeagues,
    previousSeasonIdx,
    previousSeasonLeague,
    League.league1,
  );
  fillInSeasonRecord(
    state.seasonScores,
    previousSeasonIdx,
    totalCompensation,
    0,
  );

  state.score = 0;

  state.season = SEASON;

  state.season2Compensations = {
    claimAmount: totalCompensation,
    finalBalanceRedeemed: existingBalance,
    totalMiningCardsRedeemed: getPowerUpCount(state, now),
    miningCardRedemptionAmount: powerUpCompensation,
    redeemedPortfolioValue: offchainTokenCompensation,
  };

  // add a label that we can use in the FE to show the modal once
  state.labels.push(COMPLETE_SEASON_2_LABEL);
}
