import {
  ReplicantAsyncActionAPI,
  ReplicantEventHandlerAPI,
} from '@play-co/replicant';
import { ReplicantServer } from '../config';
import { MutableState } from '../schema';
import { getCoinAmountForPointSell } from '../features/tradingMeme/tradingMeme.getters';
import { HP } from '../lib/HighPrecision';
import { txConfig } from '../features/tradingMeme/tradingMeme.ruleset';

// We have to fill how much players spent on their tokens
// However it cannot be deduced from the offchainToken data held on the player state
// Therefore a new property was added, and a heuristic has to be used to roughly evaluate how much they spent
export async function fillTokenCurrencyInvestments(
  state: MutableState,
  _:
    | ReplicantAsyncActionAPI<ReplicantServer>
    | ReplicantEventHandlerAPI<ReplicantServer>,
) {
  let totalCurrencySpent = HP(0);
  Object.values(state.trading.offchainTokens).forEach((offchainToken) => {
    if (offchainToken.supplyAtPurchase === undefined) {
      // no need to add contributions from tokens without supplyAtPurchase
      // those would have been added after this migration took effect
      return;
    }

    const tokenPurchased = HP(offchainToken.pointAmount);
    const supplyAfterPurchase = HP(offchainToken.supplyAtPurchase).add(
      tokenPurchased,
    );
    const currencyInvested = getCoinAmountForPointSell(
      supplyAfterPurchase,
      tokenPurchased,
    )
      // .mul(txConfig.buyModifier)
      // .div(txConfig.sellModifier)
      .round();
    offchainToken.currencyInvested = currencyInvested.toString();

    totalCurrencySpent = totalCurrencySpent.add(currencyInvested);
  });

  state.trading.offchain.currencySpent = totalCurrencySpent.toString();
}
