export interface Response {
  data?: any;
  error?: string;
  code?: string;
}

interface ErrorOpts<T> {
  code?: ErrorCode;
  data?: T;
  log_off?: boolean;
}

export const errorResponse = <T>(
  message: string,
  opts?: ErrorOpts<T>,
): Response => {
  if (opts?.log_off) {
    console.error(message);
  }
  return {
    error: message,
    ...opts,
  };
};

export const successResponse = <T>(data: T): Response => {
  return {
    data,
  };
};

export enum ErrorCode {
  QUEST_FRIEND_INVITE = 'QUEST_FRIEND_INVITE',
  START_WAITING_FOR_QUEST = 'START_WAITING_FOR_QUEST',
  QUEST_WAIT_NOT_READY = 'QUEST_WAIT_NOT_READY',
  WALLET_NOT_CONNECTED = 'WALLET_NOT_CONNECTED',
  OFFCHAIN_TOKEN_PRICE_DRIFT = 'OFFCHAIN_TOKEN_PRICE_DRIFT',
  OFFCHAIN_TOKEN_PURCHASE_FAILED = 'OFFCHAIN_TOKEN_PURCHASE_FAILED',
  NOT_ENOUGH_FUNDS = 'NOT_ENOUGH_FUNDS',
  INVALID_TOKEN = 'INVALID_TOKEN',
  INVALID_CURRENCY = 'INVALID_CURRENCY',
  NON_TRANSACTABLE_TOKEN = 'NON_TRANSACTABLE_TOKEN',
  NO_OFFCHAIN_TOKEN_OWNED = 'NO_OFFCHAIN_TOKEN_OWNED',
  NO_OFFCHAIN_TOKEN_CREATED = 'NO_OFFCHAIN_TOKEN_CREATED',
  IAP_TG_STARS_TIMEOUT = 'IAP_TG_STARS_TIMEOUT',
  CREATION_LIMIT_REACHED = 'CREATION_LIMIT_REACHED',
  HOLDING_LIMIT_REACHED = 'HOLDING_LIMIT_REACHED',
  OFFCHAIN_TOKEN_GIFT_FAILED = 'OFFCHAIN_TOKEN_GIFT_FAILED',
  TAP_SCORE_TO_POINT_CONV_FAILED = 'TAP_SCORE_TO_POINT_CONV_FAILED',
  MEME_CREATION_TIMEOUT = 'MEME_CREATION_TIMEOUT',

  CUSTOM_SENTRY_TRACK = 'CUSTOM_SENTRY_TRACK',

  // ON-CHAIN / TON / JETTON (OC_ prefix)
  OC_TON_NOT_READY = 'OC_TON_NOT_READY',
  OC_TON_SIGNATURE_FAILED = 'OC_TON_SIGNATURE_FAILED',
  OC_JETTON_METADATA_FAILED = 'OC_JETTON_METADATA_FAILED',
  OC_WALLET_ADDRESS_MISSING = 'OC_WALLET_ADDRESS_MISSING',
  OC_TRIGGER_GRADUATION_FAILED = 'OC_TRIGGER_GRADUATION_FAILED',
}

export const isHandledError = (error: string) => {
  return (Object.values(ErrorCode) as string[]).includes(error);
};
