import './MemeDetailsTabsContent.scss';
import { t } from 'i18next';
import { app } from '../../../../data/Controllers/AppController';
import { useState } from 'react';
import { Tabs, TabStyle } from '../../../shared/Tabs/Tabs';
import {
  MemeDetailsAbout,
  MemeDetailsMarketStats,
  MemeDetailsTransactions,
} from './MemeDetailsTabsContent';

type MemeDetailsCategories = 'MarketStats' | 'About' | 'Transactions';
interface Props {
  onTabChange: () => void;
}
export const MemeDetailsTabs = ({ onTabChange }: Props) => {
  const initialTab = 'MarketStats';
  const [currentTab, setCurrentTab] = useState(initialTab);

  const meme = app.memes.currentMeme.meme;

  const onTabClick = (
    tab: MemeDetailsCategories,
    _prev: MemeDetailsCategories,
  ) => {
    setCurrentTab(tab);
    onTabChange();

    if (meme) {
      app.track('memeoffchainToken_detail_tab_view', {
        tab_name: tab,
        memeoffchainToken_name: meme.name,
        memeoffchainTokenID: meme.id,
        current_price: meme.pointPrice,
        total_holders: meme.holderCount,
      });
    }
  };

  // --------------------------------
  // render

  if (!meme) {
    return null;
  }

  return (
    <div className="meme-details-tabs-container">
      <Tabs<MemeDetailsCategories>
        tabStyle={TabStyle.Underline}
        initial={initialTab as MemeDetailsCategories}
        onTabClick={onTabClick}
        tabs={[
          {
            id: 'MarketStats',
            name: t('token_details_tab_name_market_stats'),
            badge: { visible: false, amount: 3, checked: false },
            component: <MemeDetailsMarketStats />,
          },

          {
            id: 'About',
            name: t('token_details_tab_name_about'),
            badge: { visible: false, amount: 2, checked: false },
            component: <MemeDetailsAbout />,
          },

          {
            id: 'Transactions',
            name: t('token_details_tab_name_transactions'),
            badge: { visible: false, amount: 1, checked: false },
            component: <MemeDetailsTransactions />,
          },
        ]}
      />
    </div>
  );
};
