import { getMyOffchainMemeIds } from '../features/tradingMeme/tradingMeme.getters';
import { updateStatus } from '../features/tradingMeme/tradingMeme.utils';
import {
  DEFAULT_FLAGGED_IMAGE,
  season2StartTime,
} from '../features/tradingMeme/tradingMeme.ruleset';
import { Migrator } from './types';

/*
  refund any tokens that were moderated as deleted, set their status to reported
  could be removed after all deleted tokens have been updated to reported.
*/
export const moderatedTokensFromDeleteToReported: Migrator = async (
  state,
  api,
) => {
  // get tokens with deleted status
  const offchainTokens = state.trading.offchainTokens;
  const myTokenIds = getMyOffchainMemeIds(state).filter((tokenId) => {
    const token = offchainTokens[tokenId];
    return !!token.productId;
  });

  if (myTokenIds.length === 0) {
    return;
  }

  const myTokens = await api.asyncGetters.getLegacyOffchainTokensFromOpenSearch(
    {
      offchainTokenIds: myTokenIds,
      status: ['deleted'],
      availableAt: season2StartTime,
    },
  );
  const deletedTokenIds = myTokens.map((token) => token.id);

  if (deletedTokenIds.length === 0) {
    return;
  }

  // update token status to reported
  await updateStatus(api, deletedTokenIds, 'reported', DEFAULT_FLAGGED_IMAGE);
};
