import {
  OwnedOffchainMeme,
  PriceSlice,
  WalletMemeHoldings,
} from '../replicant/features/game/player.schema';
import {
  maxTokenAllTimeSliceCount,
  onchainCurveConfig,
  SliceConfig,
} from '../replicant/features/tradingMeme/tradingMeme.ruleset';
import { app } from './Controllers/AppController';
import { GraphPoint } from '../components/pages/TradingPage/LightweightChartComponent/LightweightChartComponent';
import { timeInSec } from './utils';
import {
  computeOnchainPortfolioValue,
  getCurrencyInvested,
  getOnchainMarketCap,
  getGrossCoinAmountForPointSell,
  Meme,
  getWalletStatus,
  getOnchainCurvePrice,
} from '../replicant/features/tradingMeme/tradingMeme.getters';
import { TradingSearchResult } from '../replicant/features/tradingMeme/tradingMeme.properties';
import { getRoi } from '../replicant/utils/numbers';
import { State } from '../replicant/schema';
import { HighPrecision, HP } from '../replicant/lib/HighPrecision';
import { fromNano } from '@ton/core';
import { calculateTotalMintPrice } from './TonProvider/utils';

export const getPricePoints = (
  priceSlices: PriceSlice[],
  sliceConfig: SliceConfig | null,
  latestPrice: number,
) => {
  const now = app.now();

  // uncomment to test items with no transactions
  // let testTime = now;
  // new Array(100).fill(0).forEach((_, index) => {
  //   testTime -= Math.round(Math.random() * HOUR_IN_MS);
  //   // const time = now - Math.random() * 10 * HOUR_IN_MS;

  //   priceSlices.unshift({
  //     time: testTime,
  //     price: Math.round(Math.random() * offchainToken.pointPrice),
  //   });
  // });

  // remove data points that are not sorted in time
  let time = 0;
  priceSlices = priceSlices.filter((slice) => {
    const forwardInTime = slice.time > time;
    if (forwardInTime) {
      time = slice.time;
    }
    return forwardInTime;
  });

  const pricePoints: GraphPoint[] = [];

  if (sliceConfig === null) {
    if (priceSlices.length === 0) {
      return pricePoints;
    }
    // trying to make the price intervals match the all time definition,
    // however it cannot exceed a certain limit of the graph wont display it (bug with the chart?)
    const priceIntervals = Math.min(150, maxTokenAllTimeSliceCount);
    const timeWindow = now - priceSlices[0].time;

    pricePoints.push({
      time: timeInSec(now - timeWindow),
      value: HP(priceSlices[0].price).toNumber(),
    });

    let j = 0;
    let price = HP(-1);
    for (let i = 0; i <= priceIntervals; i += 1) {
      const time = now - timeWindow + (timeWindow * i) / priceIntervals;
      while (j < priceSlices.length && priceSlices[j].time < time) {
        price = HP(priceSlices[j].price);
        j += 1;
      }

      if (!price.eq(-1)) {
        pricePoints.push({
          time: timeInSec(time),
          value: price.toNumber(),
        });
      }
    }

    pricePoints.push({
      time: pricePoints[pricePoints.length - 1].time + 1,
      value: HP(priceSlices[priceSlices.length - 1].price).toNumber(),
    });
  } else {
    // remove price points outside of the interval
    // and add missing slices
    const timeInterval = sliceConfig.interval;
    const windowTimeStart = now - sliceConfig.window;

    let startIdx = 0;
    while (
      startIdx < priceSlices.length &&
      priceSlices[startIdx].time < windowTimeStart
    ) {
      startIdx += 1;
    }
    startIdx = Math.max(0, startIdx - 1);

    let sliceIdx = startIdx;
    // temp fix since it was breaking the app, @cai please confirm if this is correct
    if (priceSlices.length === 0) {
      return pricePoints;
    }
    let sliceTime = priceSlices[sliceIdx].time;
    while (sliceTime <= now) {
      while (
        sliceIdx < priceSlices.length &&
        priceSlices[sliceIdx].time < sliceTime
      ) {
        sliceIdx += 1;
      }

      if (sliceIdx === priceSlices.length) {
        break;
      }

      const slice = priceSlices[sliceIdx];
      pricePoints.push({
        time: timeInSec(sliceTime),
        value: HP(slice.price).toNumber(),
      });

      sliceTime = sliceTime + timeInterval;
    }

    // always add latest price as last slice
    pricePoints.push({
      time: timeInSec(sliceTime),
      value: latestPrice,
    });

    if (now > sliceTime) {
      pricePoints.push({
        time: timeInSec(now),
        value: latestPrice,
      });
    }
  }

  return pricePoints;
};

export const getOnchainTokenPricePoints = (
  meme: Meme,
  sliceConfig: SliceConfig | null,
) => {
  const priceSlices = meme.trends[sliceConfig?.windowId || 'allTime'].slice();
  const latestPrice = HP(meme.tokenPrice).toNumber();

  return getPricePoints(priceSlices, sliceConfig, latestPrice);
};

export const getDexGraduationPct = async (meme: Meme) => {
  if (meme.isMinted) {
    if (meme.isGraduated || meme.dexContractAddress) {
      return 100;
    }
    return app.ton.getDexGraduationPct(meme.tokenSupply);
  }

  return 0;
};

export const getPortfolioPricePoints = (
  state: State,
  sliceConfig: SliceConfig | null,
  ownedOffchainPoints: TradingSearchResult[],
) => {
  // const priceSlices = getWalletStatus(
  //   state,
  //   app.ton.walletAddress,
  // ).portfolioTrends[sliceConfig?.windowId || 'allTime'].slice();

  // const latestPrice = computeOnchainPortfolioValue(
  //   state,
  //   ownedOffchainPoints,
  //   app.ton.walletAddress,
  // ).toNumber();

  // return getPricePoints(priceSlices, sliceConfig, latestPrice);
  return [];
};

export const getOffchainMemeHoldCount = (state: State) => {
  return Object.values(state.trading.offchainTokens).reduce(
    (count, offchainMeme) => {
      const pointAmount = HP(offchainMeme.pointAmount).toNumber();
      return pointAmount > 0 ? count + 1 : count;
    },
    0,
  );
};

export const getPortfolioRoi = (state: State, portfolioValue: number) => {
  const currencyInvested = getCurrencyInvested(state).toNumber();
  const roi = getRoi(currencyInvested + state.balance, portfolioValue);
  return roi;
};

export const getOffchainMemeHoldingStats = (
  memeId: string,
  ownedOffchainMemes: TradingSearchResult[],
  offchainMemeHoldings?: OwnedOffchainMeme,
) => {
  const memeStatus = ownedOffchainMemes.find((memeStatus) => {
    return memeStatus.id === memeId;
  });
  if (!memeStatus) {
    return {
      valuation: 0,
      roi: 0,
      marketCap: 0,
    };
  }

  const marketCap = getOnchainMarketCap(HP(memeStatus.tokenSupply)).toNumber();
  if (!offchainMemeHoldings) {
    return {
      valuation: 0,
      roi: 0,
      marketCap,
    };
  }

  const currencyInvested = HP(offchainMemeHoldings.currencyInvested).toNumber();
  const holdingValuation = getGrossCoinAmountForPointSell(
    HP(memeStatus.pointSupply),
    HP(offchainMemeHoldings.pointAmount),
  ).toNumber();

  const roi = getRoi(currencyInvested, holdingValuation);

  // console.warn(
  //   '>>> getOffchainTokenHoldingStats',
  //   holdingValuation,
  //   roi,
  //   marketCap,
  // );

  return {
    valuation: holdingValuation,
    roi,
    marketCap,
  };
};

export const getOnchainMemeVolume = (meme: Meme, since: number) => {
  return meme.stats.hour24.reduce((totalVolume, statsSlice) => {
    if (statsSlice.time < since) {
      return totalVolume;
    }

    return totalVolume.add(statsSlice.volume);
  }, HP(0));
};
